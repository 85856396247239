import React, { useState } from "react";
import classes from "./CookiesPolicy.module.css";

/** Third Party **/
import { Helmet } from "react-helmet";
import { Config } from "../../config/config";

const CookiesPolicy = () => {
  return (
    <>
      <Helmet>
        <title>{Config.name} | Cookies Policy</title>
      </Helmet>
      <div className={`${classes.container}`}>
        <div className={`container`}>
          <div className={`${classes.block} container-sm`}>
            <div className={`title-container text-center`}>
              <div className={`title`}>Cookies Policy</div>
            </div>

            <div className={`page-content pt-0`}>
              {/* Details */}
              <div className={`${classes.details}`}>
                <div className={`${classes.text} text mb-2`}>
                  <div class="PageContent PageContent--narrow Rte">
                    <strong>
                      Personal information we collect
                      <br />
                    </strong>
                    <span></span>
                    <br />
                    <span>
                      When you visit the Site, we automatically collect certain
                      information about your device, including information about
                      your web browser, IP address, time zone, and some of the
                      cookies that are installed on your device. Additionally,
                      as you browse the Site, we collect information about the
                      individual web pages or products that you view, what
                      websites or search terms referred you to the Site, and
                      information about how you interact with the Site. We refer
                      to this automatically-collected information as “Device
                      Information”.
                    </span>
                    <br />
                    <br />
                    <span>
                      We collect Device Information using the following
                      technologies:
                    </span>
                    <br />
                    <span>
                      - “Cookies” are data files that are placed on your device
                      or computer and often include an anonymous unique
                      identifier. For more information about cookies, and how to
                      disable cookies, visit http://www.allaboutcookies.org.
                    </span>
                    <br />
                    <span>
                      - “Log files” track actions occurring on the Site, and
                      collect data including your IP address, browser type,
                      Internet service provider, referring/exit pages, and
                      date/time stamps.
                    </span>
                    <br />
                    <span>
                      - “Web beacons”, “tags”, and “pixels” are electronic files
                      used to record information about how you browse the Site.
                    </span>
                    <br />
                    <br />
                    <span>
                      Additionally, when you make a purchase or attempt to make
                      a purchase through the Site, we collect certain
                      information from you, including your name, billing
                      address, shipping address, payment information (including
                      credit card numbers,Tap, Knet, cash on delivery), email
                      address, and phone number. We refer to this information as
                      “Order Information”.
                    </span>
                    <br />
                    <br />
                    <span>
                      When we talk about “Personal Information” in this Privacy
                      Policy, we are talking both about Device Information and
                      Order Information.
                    </span>
                    <br />
                    <br />
                    <strong>How do we use your personal information?</strong>
                    <span></span>
                    <br />
                    <span>
                      We use the Order Information that we collect generally to
                      fulfill any orders placed through the Site (including
                      processing your payment information, arranging for
                      shipping, and providing you with invoices and/or order
                      confirmations). Additionally, we use this Order
                      Information to:
                    </span>
                    <br />
                    <span>- Communicate with you;</span>
                    <br />
                    <span>
                      - Screen our orders for potential risk or fraud; and
                    </span>
                    <br />
                    <span>
                      - When in line with the preferences you have shared with
                      us, provide you with information or advertising relating
                      to our products or services.
                    </span>
                    <br />
                    <br />
                    <br />
                    <span>
                      We use the Device Information that we collect to help us
                      screen for potential risk and fraud (in particular, your
                      IP address), and more generally to improve and optimize
                      our Site (for example, by generating analytics about how
                      our customers browse and interact with the Site, and to
                      assess the success of our marketing and advertising
                      campaigns).
                    </span>
                    <br />
                    <br />
                    <strong>Sharing you personal Information</strong>
                    <span></span>
                    <br />
                    <span>
                      We share your Personal Information with third parties to
                      help us use your Personal Information, as described above.
                      For example, we use Shopify to power our online store--you
                      can read more about how Shopify uses your Personal
                      Information here: https://www.shopify.com/legal/privacy.
                      We also use Google Analytics to help us understand how our
                      customers use the Site -- you can read more about how
                      Google uses your Personal Information here:
                      https://www.google.com/intl/en/policies/privacy/. You can
                      also opt-out of Google Analytics here:
                      https://tools.google.com/dlpage/gaoptout.
                    </span>
                    <br />
                    <br />
                    <span>
                      Finally, we may also share your Personal Information to
                      comply with applicable laws and regulations, to respond to
                      a subpoena, search warrant or other lawful request for
                      information we receive, or to otherwise protect our
                      rights.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { CookiesPolicy };
