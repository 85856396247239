import React, { useState } from "react";
import classes from "./SearchBar.module.css";
import { useTranslation } from "react-i18next";

export const SearchBar = ({ search, onSearchFromParent }) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className={`${classes.searchBar}`}>
        <div className={`${classes.searchBarGroup}`}>
          <span className={`icon-search ${classes.icon}`}></span>
          <input
            className={`${classes.inputSearch}`}
            type="text"
            placeholder={t("search")}
            value={search}
            onChange={(e) => onSearchFromParent(e.target.value)}
          />
        </div>
      </div>
    </>
  );
};
