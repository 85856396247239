import React, { useState, useEffect } from "react";
import classes from "../Auth.module.css";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Helmet } from "react-helmet";
import { jwt } from "@services";
import CircularProgress from "@mui/material/CircularProgress";
import { ResetPassword } from "./ResetPassword";
import { Seo } from "@components";

/** Redux **/
import { forgetPassword, clearState } from "@store/auth/auth";
import { useDispatch, useSelector } from "react-redux";

/** Third Party **/
import { Config } from "@config/config";
import { NavLink, Navigate } from "react-router-dom";

export const ForgotPassword = () => {
  const { t, i18n } = useTranslation();
  const jwToken = jwt();
  const dispatch = useDispatch();
  const [emailValid, setEmailValid] = useState(false);
  const [mainEmail, setMainEmail] = useState(null);

  const { loading, success } = useSelector(
    (state) => state.auth.forgetPassword
  );

  const schema = yup
    .object({
      email: yup
        .string()
        .required(t("Validations.email-address-required"))
        .email(t("Validations.email-must-be-a-valid-email"))
        .min(6, t("Validations.email-too-short")),
    })
    .required();

  const defaultValues = {
    email: "",
  };

  const {
    setError,
    clearErrors,
    reset,
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    if (success) {
      setEmailValid(true);
    } else {
      setEmailValid(false);
    }
  }, [dispatch, success]);

  const onSubmit = async (data) => {
    dispatch(forgetPassword({ email: data?.email }));
    setMainEmail(data?.email);
  };

  useEffect(() => {
    if (!mainEmail) {
      setEmailValid(false);
      dispatch(clearState());
    }
  }, [dispatch, mainEmail]);

  let redirect = null;
  if (jwToken) {
    redirect = <Navigate to="/" />;
  }

  return (
    redirect ?? (
      <>
        <Seo title={`${Config.name} | ${t("forgot-password")}`} />
        <div className={classes.container}>
          <div className={`container-fluid text-center`}>
            <div className={classes.block}>
              <div className={`title`}>
                {emailValid ? t("reset-password") : t("recover-password")}
              </div>
              <div className={`sub-title m-3 mb-4`}>
                {emailValid ? (
                  <>
                    {t("enter-the-code-weve-sent-to")}{" "}
                    {mainEmail?.substring(0, 4)}
                    *********
                  </>
                ) : (
                  t("recover-password-description")
                )}
              </div>
              {!emailValid ? (
                <form className="mt-2" onSubmit={handleSubmit(onSubmit)}>
                  <div className={`input-box`}>
                    <Controller
                      control={control}
                      {...register("email")}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          helperText={
                            errors?.email ? errors?.email.message : null
                          }
                          size="small"
                          error={!!errors?.email}
                          onChange={onChange}
                          value={value}
                          fullWidth
                          label={t("email")}
                          variant="outlined"
                        />
                      )}
                    />
                  </div>

                  <Button
                    disabled={loading}
                    type="submit"
                    size="large"
                    fullWidth
                    color="primary"
                    variant="contained">
                    {loading ? (
                      <>
                        <CircularProgress size={17} style={{ color: "#fff" }} />{" "}
                        {t("loading")}
                      </>
                    ) : (
                      t("recover-now")
                    )}
                  </Button>
                </form>
              ) : (
                <ResetPassword mainEmail={mainEmail} />
              )}

              <div className={classes.accountCheck}>
                {t("remember-your-password")}
                <NavLink className={classes.accountCheckLink} to="/auth/login">
                  {t("back-to-login")}
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};
