import React, { useState, useEffect } from "react";
import classes from "./ProductCardDefault.module.css";
import {
  Avatar,
  OnSale,
  Bookmark,
  ProductCardActions,
  Share,
} from "@components";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { jwt } from "@services";
import CircularProgress from "@mui/material/CircularProgress";
import { fetchCart, addCart, clearState } from "@store/cart";
import { useDispatch, useSelector } from "react-redux";

export const ProductCardDefault = ({ row }) => {
  const { t } = useTranslation();
  const jwToken = jwt();
  const dispatch = useDispatch();
  const [currentId, setCurrentId] = useState(null);
  const {
    data: cartData,
    loading: cartLoading,
    success: successAddToCart,
  } = useSelector((state) => state.cart.addCart);

  const onAddToCart = (id) => {
    setCurrentId(id);
    dispatch(
      addCart({
        productId: id,
        qty: 1,
      })
    )
      .then((res) => {
        setCurrentId(null);
        dispatch(clearState());
      })
      .catch((err) => {
        setCurrentId(null);
        dispatch(clearState());
      });
  };

  return (
    <article className={`${classes.card}`} data-aos="zoom-out">
      {/* <Bookmark row={row} /> */}
      <OnSale sale={row?.hasOffer} />
      <NavLink to={`/p/${row?.encryptId}`}>
        <Avatar src={row?.media?.url} className={classes.cardImg} />
      </NavLink>
      {/* <NavLink to={`/p/${row?.encryptId}`} className={`${classes.cardLink}`}>
        <div
          className={`${classes.cardImgHover}`}
          style={{ backgroundImage: `url(${row?.media?.url})` }}></div>
      </NavLink> */}
      <div className={`${classes.cardInfo}`}>
        <div className={`${classes.cardInfoTop}`}>
          {/* <span className={`${classes.cardCategory}`}>Category Name</span> */}
          <h3 className={`${classes.cardTitle} text-capitalize`}>
            {row?.title}
          </h3>
        </div>
        {row?.oldPrice > row?.price ? (
          <div className={`${classes.price} card-item-price-red`}>
            {row?.price} {t("currency")}
            <span className={`${classes.oldPrice} card-item-old-price`}>
              {row?.oldPrice} {t("currency")}
            </span>
          </div>
        ) : (
          <span className={`${classes.cardBy}`}>
            <div className={`${classes.cardAuthor}`}>
              {row?.price} {t("currency")}
            </div>
          </span>
        )}
        {/* <div className={`${classes.action} pt-3`}>

          <button
            disabled={cartLoading}
            className={`${classes.actionBtn} ${classes.actionBtnCart} ${
              currentId === row.id ? classes.active : ""
            } `}
            onClick={() => onAddToCart(row.id)}>
            {currentId === row.id && cartLoading ? (
              <>
                <CircularProgress size={17} style={{ color: "#fff" }} />
                {t("loading")}
              </>
            ) : (
              <>
                {t("add-to-cart")}
                <span className={`icon-handbag ${classes.icon}`}></span>
              </>
            )}
          </button>

          {jwToken ? (
            <button
              className={`${classes.actionBtn}  ${classes.actionBtnFavorite} ${classes.actionBtnIcon} `}>
              <span className={`icon-heart-empty  ${classes.icon}`}></span>
            </button>
          ) : (
            ""
          )}

          <NavLink
            to={`/p/${row?.encryptId}`}
            className={`${classes.actionBtn}  ${classes.actionBtnView} ${classes.actionBtnIcon} `}>
            <span className={`icon-eye  ${classes.icon}`}></span>
          </NavLink>
        </div> */}

        <ProductCardActions row={row} />
      </div>
    </article>
  );
};
