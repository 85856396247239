import React, { useState, useEffect, useMemo } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import classes from "./Payment.module.css";
import Button from "@mui/material/Button";
import { Paginate, CircleSpinner } from "@components";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import { jwt } from "@services";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import PhoneInput from "react-phone-input-2";

/** Redux **/
import { useDispatch, useSelector } from "react-redux";
import { fetchAddresses, handlePagePagination } from "@store/user";
import { createCheckout, clearState } from "@store/checkout";
import { fetchProvinces, fetchCities } from "@store/general";

export const GuestAddress = ({
  gaIsValid,
  onGuestAddressParent,
  onAreaDeliveryValue,
}) => {
  const jwToken = jwt();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: provincesData, loading: provincesLoading } = useSelector(
    (state) => state.general.provinces
  );
  const { data: citiesData, loading: citiesLoading } = useSelector(
    (state) => state.general.cities
  );

  const schema = yup
    .object({
      // firstName: yup
      //   .string()
      //   .min(3, "First Name is too short")
      //   .required("First Name is required"),
      // lastName: yup
      //   .string()
      //   .min(3, "Last Name is too short")
      //   .required("Last Name is required"),
      phone: yup
        .string()
        .min(3, t("Validations.phone-too-short"))
        .required(t("Validations.phone-required")),
      address: yup
        .string()
        .min(3, t("Validations.address-too-short"))
        .required(t("Validations.address-required")),
      email: yup
        .string()
        .required(t("Validations.email-address-required"))
        .email(t("Validations.email-must-be-a-valid-email"))
        .min(6, t("Validations.email-too-short")),
      province: yup.string().required(t("Validations.province-required")),
      city: yup.string().required(t("Validations.city-required")),
    })
    .required();

  const defaultValues = {
    // firstName: "",
    // lastName: "",
    phone: "",
    address: "",
    province: "",
    city: "",
    email: "",
  };

  const {
    setError,
    clearErrors,
    reset,
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const formData = watch();

  watch((data) => {
    onGuestAddressParent(data);
  });

  const onSubmit = async (data) => {
    dispatch(createCheckout(data));
  };

  useEffect(() => {
    dispatch(fetchProvinces());
  }, [dispatch]);

  useEffect(() => {
    if (formData?.province) {
      dispatch(fetchCities({ rowId: formData?.province }));
      onAreaDeliveryValue(null);
    }
  }, [dispatch, formData?.province]);

  const onSelectAreaDelivery = (data) => {
    onAreaDeliveryValue(data?.value);
  };

  return (
    <>
      {/* <div>
        {!guestAddress?.phone ? (
          <p color="data-error">{t("Validations.phone-required")}</p>
        ) : (
          ""
        )}

        {!guestAddress?.address ? (
          <p color="data-error">{t("Validations.address-required")}</p>
        ) : (
          ""
        )}

        {!guestAddress?.email ? (
          <p color="data-error">{t("Validations.email-address-required")}</p>
        ) : (
          ""
        )}

        {!guestAddress?.province ? (
          <p color="data-error">{t("Validations.province-required")}</p>
        ) : (
          ""
        )}

        {!guestAddress?.city ? (
          <p color="data-error">{t("Validations.city-required")}</p>
        ) : (
          ""
        )}
      </div> */}
      <div className={`${classes.container} mb-4`}>
        <div className="mb-2">
          <div className={`${classes.title} title-md`}>{t("address")}</div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Box>
                <Controller
                  control={control}
                  {...register("province")}
                  render={({ field: { ref, onChange, value } }) => (
                    <TextField
                      helperText={
                        errors?.province
                          ? errors?.province.message
                          : null || (gaIsValid && !formData?.province)
                          ? t("Validations.province-required")
                          : ""
                      }
                      select
                      inputRef={ref}
                      type="text"
                      size="small"
                      error={
                        !!errors?.province || (gaIsValid && !formData?.province)
                      }
                      onChange={onChange}
                      value={value}
                      fullWidth
                      label={t("governorate")}
                      variant="outlined">
                      {provincesData?.map((row) => {
                        return (
                          <MenuItem
                            key={row.id}
                            value={row.id}
                            className="text-capitalize">
                            {row?.name}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  )}
                />
              </Box>
            </Grid>

            {citiesLoading ? (
              <CircleSpinner className={`pt-3`} size={50} />
            ) : formData?.province ? (
              <Grid item xs={12} sm={12}>
                <Box>
                  <Controller
                    control={control}
                    {...register("city")}
                    render={({ field: { ref, onChange, value } }) => (
                      <TextField
                        helperText={
                          errors?.city
                            ? errors?.city.message
                            : null || (gaIsValid && !formData?.city)
                            ? t("Validations.city-required")
                            : ""
                        }
                        select
                        inputRef={ref}
                        type="text"
                        size="small"
                        error={!!errors?.city || (gaIsValid && !formData?.city)}
                        onChange={onChange}
                        value={value}
                        fullWidth
                        label={t("area")}
                        variant="outlined">
                        {citiesData?.map((row) => {
                          return (
                            <MenuItem
                              onClick={() => onSelectAreaDelivery(row)}
                              key={row.id}
                              value={row.id}
                              className="text-capitalize">
                              {row?.name}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    )}
                  />
                </Box>
              </Grid>
            ) : (
              ""
            )}

            <Grid item xs={12} sm={12}>
              <Controller
                control={control}
                {...register("address")}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    helperText={
                      errors?.address
                        ? errors?.address.message
                        : null || (gaIsValid && !formData?.address)
                        ? t("Validations.address-required")
                        : ""
                    }
                    size="small"
                    error={
                      !!errors?.address || (gaIsValid && !formData?.address)
                    }
                    onChange={onChange}
                    value={value}
                    fullWidth
                    label={t("address-details-optional")}
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Controller
                control={control}
                {...register("phone")}
                render={({ field: { onChange, value } }) => (
                  // <TextField
                  //   helperText={errors?.phone ? errors?.phone.message : null}
                  //   type="number"
                  //   size="small"
                  //   error={!!errors?.phone}
                  //   onChange={onChange}
                  //   value={value}
                  //   fullWidth
                  //   label={t("phone")}
                  //   variant="outlined"
                  // />
                  <PhoneInput
                    // helperText={
                    //   errors?.phone
                    //     ? errors?.phone.message
                    //     : null || (gaIsValid && !formData?.phone)
                    //     ? t("Validations.phone-required")
                    //     : ""
                    // }
                    error={!!errors?.phone || (gaIsValid && !formData?.phone)}
                    country={"kw"}
                    value={value}
                    onChange={onChange}
                    className={`${
                      gaIsValid && !formData?.phone ? "data-error-border" : ""
                    } phone-number-container`}
                  />
                )}
              />
              {gaIsValid && !formData?.phone ? (
                <p className="data-error">{t("Validations.phone-required")}</p>
              ) : (
                ""
              )}
            </Grid>

            <Grid item xs={12} sm={12}>
              <Controller
                control={control}
                {...register("email")}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    helperText={
                      errors?.email
                        ? errors?.email.message
                        : null || (gaIsValid && !formData?.email)
                        ? t("Validations.email-address-required")
                        : ""
                    }
                    size="small"
                    error={!!errors?.email || (gaIsValid && !formData?.email)}
                    onChange={onChange}
                    value={value}
                    fullWidth
                    label={t("email")}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </div>
    </>
  );
};
