import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import LockIcon from "@mui/icons-material/Lock";
import EditIcon from "@mui/icons-material/Edit";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import classes from "./AccountMenu.module.css";
import { useSelector, useDispatch } from "react-redux";
import { Avatar as AvatarImg } from "@components";
import { NavLink } from "react-router-dom";
import { logout } from "@store/auth";
import { useTranslation } from "react-i18next";

export const AccountMenu = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { data: userData, loading } = useSelector(
    (state) => state.auth.userData
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    dispatch(logout());
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title={t("Profile.account")}>
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}>
            {userData?.image ? (
              <AvatarImg
                className={classes.avatar}
                src={userData?.image}
                alt={userData?.name}
                width={1000}
                height={1000}
              />
            ) : (
              <Avatar sx={{ width: 40, height: 40 }}>
                {userData?.name?.substring(0, 1)}
              </Avatar>
            )}
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
        <MenuItem>
          <NavLink className="d-flex align-items-center" to="/account">
            {userData?.image ? (
              <AvatarImg
                className={classes.avatar}
                src={userData?.image}
                alt={userData?.name}
                width={1000}
                height={1000}
              />
            ) : (
              <Avatar sx={{ width: 40, height: 40 }}>
                {userData?.name?.substring(0, 1)}
              </Avatar>
            )}
            <span className={`text-capitalize ${classes.username}`}>
              {userData?.name}
            </span>
          </NavLink>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>
          <NavLink className="d-flex align-items-center" to="/account/wishlist">
            <ListItemIcon>
              <FavoriteIcon fontSize="small" />
            </ListItemIcon>
            {t("Profile.wishlist")}
          </NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <NavLink
            className="d-flex align-items-center"
            to="/account/addresses">
            <ListItemIcon>
              <LocationOnIcon fontSize="small" />
            </ListItemIcon>
            {t("my-addresses")}
          </NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <NavLink
            className="d-flex align-items-center"
            to="/account/update-profile">
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            {t("Profile.update-profile")}
          </NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <NavLink
            className="d-flex align-items-center"
            to="/account/update-password">
            <ListItemIcon>
              <LockIcon fontSize="small" />
            </ListItemIcon>
            {t("Profile.change-password")}
          </NavLink>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {t("Profile.logout")}
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};
