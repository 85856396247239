import React, { useState } from "react";
import classes from "./Avatar.module.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Config } from "@config";

export const Avatar = ({ src, alt, className, style }) => {
  return (
    <img
      style={style}
      className={`${className ?? ""} img-fluid`}
      src={src ?? ""}
      alt={alt ?? Config.name}
    />
  );
};
