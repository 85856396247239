import React, { useEffect, useState } from "react";
import classes from "./Store.module.css";
import { Helmet } from "react-helmet";
import { useLocation, useParams } from "react-router-dom";

/** Redux **/
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProducts,
  handlePagePagination,
  fetchCategoriesForParent,
  fetchVideoPromo,
  fetchFilteredProducts,
} from "@store/storeCollection";
import { useTranslation } from "react-i18next";

/** Third Party **/
import { Config } from "@config/config";
import { Filter } from "./filter/Filter";
import { Products } from "./products/Products";
import { Video } from "./video/Video";
import { Categories } from "./categories/Categories";
import { SliderProducts, Paginate, CircleSpinner, Seo } from "@components";

export const Store = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const stateData = location.state;
  const { category, title, slug: slugOrId } = useParams();
  const categoryKeyQuery = new URLSearchParams(location.search).get("c");
  const categoryIdQuery = new URLSearchParams(location.search).get("cid");
  const videoURLQuery = new URLSearchParams(location.search).get("v");
  const [sortGrid, setSortGrid] = useState(4);

  const { data, paginate, pagePaginate, loading } = useSelector(
    (state) => state.storeCollection.products
  );

  const { data: categoriesData, loading: categoriesLoading } = useSelector(
    (state) => state.storeCollection.categories
  );

  const { data: videoPromoData, loading: videoPromoLoading } = useSelector(
    (state) => state.storeCollection.videoPromo
  );

  const filterIds = useSelector((state) => state.storeCollection.filterIds);

  // useEffect(() => {
  //   if (keyword === "categories") {
  //     dispatch(fetchCategoriesForParent({ parentId: items[0]?.id }));
  //   }
  // }, [dispatch, items, keyword]);

  const onSortGrid = (data) => {
    setSortGrid(data);
  };

  const onPagePaginate = (data) => {
    dispatch(handlePagePagination({ page: data }));
    if (data > 1) {
      if (category && categoryIdQuery) {
        dispatch(
          fetchFilteredProducts({
            category: category,
            categoryId: categoryIdQuery,
            page: data,
          })
        );
      } else {
        dispatch(fetchFilteredProducts({ page: data }));
      }
    } else {
      dispatch(
        fetchFilteredProducts({
          category: category,
          categoryId: categoryIdQuery,
        })
      );
    }
  };

  const onPagePaginateFilter = (data) => {
    dispatch(handlePagePagination({ page: data }));
    if (data > 1) {
      dispatch(
        fetchFilteredProducts({
          page: data,
          mainCategoriesIds: filterIds?.mainCategoriesIds,
          priceRange: filterIds?.priceRange,
          brandsIds: filterIds?.brandsIds,
          discountsIds: filterIds?.discountsIds,
          colorsIds: filterIds?.colorsIds,
          sizesIds: filterIds?.sizesIds,
          printSpeed: filterIds?.printSpeed,
          sensor: filterIds?.sensor,
          printQuality: filterIds?.printQuality,
          connectivity: filterIds?.connectivity,
          duplex: filterIds?.duplex,
          type: filterIds?.type,
          functions: filterIds?.functions,
          monthlyDutyCycle: filterIds?.monthlyDutyCycle,
          bagSize: filterIds?.bagSize,
          category: category,
          categoryId: categoryIdQuery,
        })
      );
    } else {
      dispatch(
        fetchFilteredProducts({
          mainCategoriesIds: filterIds?.mainCategoriesIds,
          priceRange: filterIds?.priceRange,
          brandsIds: filterIds?.brandsIds,
          discountsIds: filterIds?.discountsIds,
          colorsIds: filterIds?.colorsIds,
          sizesIds: filterIds?.sizesIds,
          printSpeed: filterIds?.printSpeed,
          sensor: filterIds?.sensor,
          printQuality: filterIds?.printQuality,
          connectivity: filterIds?.connectivity,
          duplex: filterIds?.duplex,
          type: filterIds?.type,
          functions: filterIds?.functions,
          monthlyDutyCycle: filterIds?.monthlyDutyCycle,
          bagSize: filterIds?.bagSize,
          category: category,
          categoryId: categoryIdQuery,
        })
      );
    }
  };

  useEffect(() => {
    if (category && categoryIdQuery) {
      dispatch(
        fetchFilteredProducts({
          category: category,
          categoryId: categoryIdQuery,
        })
      );
    } else {
      dispatch(fetchFilteredProducts());
    }
  }, [categoryIdQuery, category, dispatch]);

  useEffect(() => {
    if ((category && category === "c") || category === "sc") {
      dispatch(
        fetchCategoriesForParent({
          parentId: categoryIdQuery,
        })
      );
    }
  }, [dispatch, category, categoryIdQuery]);

  useEffect(() => {
    if (videoURLQuery) {
      dispatch(
        fetchVideoPromo({
          rowId: videoURLQuery,
        })
      );
    }
  }, [dispatch, videoURLQuery]);

  return (
    <>
      <Seo title={`${Config.name} | ${title?.replace("-", " ")}`} />
      <div className={classes.container}>
        <div className={`container-fluid`}>
          <div className={`title-container text-center`}>
            <div className={`title text-capitalize`}>
              {title?.replace("-", " ")}
            </div>
          </div>

          {videoURLQuery && videoPromoData ? (
            <Video videoURL={videoPromoData} />
          ) : (
            ""
          )}

          {(category && category === "c") || category === "sc" ? (
            categoriesLoading ? (
              <CircleSpinner className={"pb-5"} size="lg" />
            ) : categoriesData?.length > 0 ? (
              <Categories items={categoriesData} keyword={category} />
            ) : (
              ""
            )
          ) : (
            ""
          )}

          {/* <CircleSpinner className={"pb-5"} size="lg" /> */}
        </div>
        <Filter onSortFromParent={onSortGrid} sortGrid={sortGrid} />
        <div className={`page-content`}>
          {loading ? (
            <CircleSpinner className={"pb-5"} size="lg" />
          ) : (
            <>
              <div className="pb-5">
                <Products sortGrid={sortGrid} items={data} />
                {paginate?.last_page > 1 ? (
                  <Paginate
                    page={pagePaginate}
                    count={paginate?.last_page}
                    onPage={
                      filterIds?.selected
                        ? onPagePaginateFilter
                        : onPagePaginate
                    }
                  />
                ) : (
                  ""
                )}
              </div>
            </>
          )}
          {/* <SliderProducts viewAll={false} title={t("recently-viewed")} /> */}
        </div>
      </div>
    </>
  );
};
