import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import classes from "./MainHeader.module.css";
import { useTranslation } from "react-i18next";
import { Config } from "@config/config";
import { jwt, slug } from "@services";
import {
  LangSwitcher,
  Search,
  Cart,
  Avatar,
  CircleSpinner,
  CircleCard,
} from "@components";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories, fetchCategoriesByParenId } from "@store/general";
import { fetchBrands } from "@store/home";
import { AccountMenu } from "./accountMenu/AccountMenu";
import { ResponsiveNavbar } from "./responsiveNavbar/ResponsiveNavbar";

export const Dropdown = ({
  link,
  title,
  btnTitle,
  items,
  keyword,
  onActiveDropdown,
}) => {
  const dispatch = useDispatch();
  const { data, paginate, loading, pagePaginate } = useSelector(
    (state) => state.general.categoriesByParenId
  );

  useEffect(() => {
    if (keyword === "categories") {
      dispatch(fetchCategoriesByParenId({ parentId: items[0]?.id }));
    }
  }, [dispatch, items, keyword]);

  return (
    <>
      <div className={`${classes.dropdownHeader}`}>
        <div className={`${classes.headerTitle}`}>{title}</div>
        <NavLink
          onClick={onActiveDropdown}
          to={link}
          className={`btn-corner ${classes.headerAction}`}>
          {btnTitle}
          <span className={`icon-redirection icon ${classes.icon}`}></span>
        </NavLink>
      </div>

      {keyword === "categories" ? (
        <>
          <div className={`${classes.dropdownTabs}`}>
            {items?.length > 0
              ? items?.map((row, index) => {
                  if (row?.popular || row?.hasOffer) {
                    return (
                      <div className={`${classes.dropTabBtn}`}>
                        <span
                          onClick={() =>
                            dispatch(
                              fetchCategoriesByParenId({ parentId: row?.id })
                            )
                          }>
                          {row?.title}
                        </span>
                        <NavLink
                          onClick={onActiveDropdown}
                          to={`/${keyword === "categories" ? "c" : "b"}/${slug(
                            row?.title
                          )}/${row?.encryptId}?cid=${row?.id}`}
                          className={`${classes.dropTabLink}`}>
                          <span
                            className={`icon-redirection ${classes.icon}`}></span>
                        </NavLink>
                      </div>
                    );
                  }
                })
              : ""}
          </div>
          {loading ? (
            <CircleSpinner size="lg" className={"pt-2 pb-2"} />
          ) : (
            <div className={`${classes.dropdownBody}`}>
              <div className={`${classes.dropdownGrid} text-center`}>
                {JSON.stringify(data?.rows)}
                {data?.length > 0
                  ? data?.map((row, index) => {
                      return (
                        <CircleCard
                          key={row?.id}
                          onActiveDropdown={onActiveDropdown}
                          row={row}
                          keyword={"sc"}
                          keywordEncryptID={row?.encryptId}
                          keywordID={row?.id}
                        />
                      );
                    })
                  : ""}
              </div>
            </div>
          )}
        </>
      ) : (
        <div className={`${classes.dropdownBody}`}>
          <div className={`${classes.dropdownGrid} text-center`}>
            {items?.length > 0
              ? items?.map((row, index) => {
                  if (row?.popular || row?.hasOffer) {
                    return (
                      <CircleCard
                        key={row?.id}
                        onActiveDropdown={onActiveDropdown}
                        row={row}
                        keyword={"b"}
                        keywordEncryptID={row?.encryptId}
                        keywordID={row?.id}
                      />
                    );
                  }
                })
              : ""}
          </div>
        </div>
      )}
    </>
  );
};

export const MainHeader = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const currentLang = i18n.language;
  const jwToken = jwt();
  const [searchIsActive, setSearchIsActive] = useState(false);
  const [cartIsActive, setCartIsActive] = useState(false);
  const [navbarIsActive, setNavbarIsActive] = useState(false);
  const [activeCategories, setActiveCategories] = useState(false);
  const [activeBrands, setActiveBrands] = useState(false);
  const categories = useSelector((state) => state.general.categories.data);
  const brands = useSelector((state) => state.home.brands.data);
  const { data: cartData, loading } = useSelector(
    (state) => state.cart.cartData
  );

  const location = useLocation();
  const hideNavbarOnPages = Config?.hideNavbarOnPages;
  const isHidden = hideNavbarOnPages.includes(location.pathname);

  const handleSearch = (data) => {
    setSearchIsActive(data);

    if (!searchIsActive) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  };

  const handleCart = (data) => {
    setCartIsActive(data);
  };

  const toggleNavbar = () => {
    setNavbarIsActive(!navbarIsActive);
  };

  if (localStorage.getItem("i18nextLng")) {
    const bodyEl = document.body;
    if (localStorage.getItem("i18nextLng") == "ar") {
      bodyEl.classList.add("rtl-dir");
    } else {
      bodyEl.classList.remove("rtl-dir");
    }
  }

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchBrands());
  }, [dispatch]);

  if (isHidden) {
    return null;
  }

  return (
    <>
      <nav className={`${classes.navWrapper}`}>
        <div className={`${classes.navbarBlock}`}>
          <div className={`container-fluid`}>
            <div className={`${classes.navbarContent}`}>
              <div className={`${classes.navLogo}`}>
                <div className={`${classes.logo}`}>
                  <NavLink className={classes.logoLink} to="/">
                    <Avatar
                      className={classes.logoImg}
                      src={Config.logo}
                      alt={Config.name}
                    />
                  </NavLink>
                </div>
              </div>

              <div className={`${classes.navLinks}`}>
                <div className={`${classes.mainLinks}`}>
                  <div className={`${classes.itemLink}`}>
                    <NavLink className={`${classes.itemLinkBtn}`} to="/">
                      {t("home")}
                    </NavLink>
                  </div>
                  <div
                    className={`${classes.itemLink}`}
                    onMouseEnter={() => setActiveCategories(true)}
                    onMouseLeave={() => setActiveCategories(false)}>
                    <NavLink
                      onClick={() => setActiveCategories(false)}
                      to={"/categories"}
                      className={`${classes.itemLinkBtn}`}>
                      {t("categories")}
                      <span
                        className={`icon-chevron-down ${classes.iconChevron}`}></span>
                    </NavLink>

                    <div
                      className={`${classes.dropdownContainer} ${
                        activeCategories ? classes.active : ""
                      }`}>
                      <Dropdown
                        onActiveDropdown={() => setActiveCategories(false)}
                        keyword={"categories"}
                        link={"/categories"}
                        title={t("popular-categories")}
                        btnTitle={t("show-all-categories")}
                        items={categories}
                      />
                    </div>
                  </div>
                  <div
                    className={`${classes.itemLink}`}
                    onMouseEnter={() => setActiveBrands(true)}
                    onMouseLeave={() => setActiveBrands(false)}>
                    <NavLink
                      onClick={() => setActiveBrands(false)}
                      to={"/brands"}
                      className={`${classes.itemLinkBtn}`}>
                      {t("brands")}
                      <span
                        className={`icon-chevron-down ${classes.iconChevron}`}></span>
                    </NavLink>

                    <div
                      className={`${classes.dropdownContainer} ${
                        activeBrands ? classes.active : ""
                      } `}>
                      <Dropdown
                        onActiveDropdown={() => setActiveBrands(false)}
                        keyword={"brands"}
                        link={"/brands"}
                        title={t("featured-brands")}
                        btnTitle={t("show-all-brands")}
                        items={brands}
                      />
                    </div>
                  </div>
                  <div className={`${classes.itemLink}`}>
                    <NavLink
                      className={`${classes.itemLinkBtn}`}
                      to="/discounts">
                      {t("discounts")}{" "}
                      <span className={`icon-sale ${classes.linkIcon}`}></span>
                    </NavLink>
                  </div>
                  <div className={`${classes.itemLink}`}>
                    <NavLink
                      className={`${classes.itemLinkBtn}`}
                      to="/contact-us">
                      {t("contact-us")}
                    </NavLink>
                  </div>
                </div>
              </div>

              <div className={`${classes.navActions}`}>
                <div className={`${classes.item} ${classes.menuBar}`}>
                  <button
                    className={`${classes.itemNav} btn`}
                    onClick={toggleNavbar}>
                    <span className={`icon-menu ${classes.icon}`}></span>
                  </button>
                </div>

                <div className={`${classes.item}`}>
                  <LangSwitcher className={classes.langSwitcher} />
                </div>

                <div className={classes.item}>
                  <button
                    className={`${classes.itemNav} btn`}
                    onClick={() => handleSearch(!searchIsActive)}>
                    <span className={`icon-search-2 ${classes.icon}`}></span>
                  </button>
                </div>

                <div className={classes.item}>
                  <button
                    className={`${classes.itemNav} btn`}
                    onClick={() => setCartIsActive(true)}>
                    <div className={`${classes.itemContent}`}>
                      <span className={`icon-cart ${classes.icon}`}></span>
                      <span className={`${classes.badge}`}>
                        {cartData?.rows?.length ?? 0}
                      </span>
                    </div>
                  </button>
                </div>

                <div className={classes.item}>
                  {jwToken && <AccountMenu />}

                  {!jwToken && (
                    <NavLink
                      className={`${classes.itemNav} btn`}
                      to={"/auth/login"}>
                      <span
                        className={`icon-user-circle ${classes.icon}`}></span>
                    </NavLink>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      {searchIsActive ? (
        <Search
          onDataFromSearch={handleSearch}
          searchIsActive={searchIsActive}
          className={`${classes.searchContainer}`}
        />
      ) : (
        ""
      )}
      <Cart onDataFromCart={handleCart} cartIsActive={cartIsActive} />

      <ResponsiveNavbar className />
    </>
  );
};
