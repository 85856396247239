import React, { useState, useEffect } from "react";
import classes from "./Orders.module.css";
import { NavLink } from "react-router-dom";
import moment from "moment";
import {
  fetchOrders,
  cancelOrder,
  clearState,
  handlePagePagination,
} from "@store/orders";
import { useDispatch, useSelector } from "react-redux";
import { CircleSpinner, EmptyProductsMsg, Paginate } from "@components";
import { useTranslation } from "react-i18next";
import { Spinner } from "reactstrap";

/** Sweetalert **/
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

export const Orders = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { data, paginate, loading, pagePaginate } = useSelector(
    (state) => state.order.orders
  );
  const { loading: cancelOrderLoading, success } = useSelector(
    (state) => state.order.cancelOrder
  );

  const onCancelOrder = async (id) => {
    await dispatch(cancelOrder({ id }));
  };

  useEffect(() => {
    if (success) {
      MySwal.fire({
        icon: "success",
        title: t("order-canceled"),
        text: t("order-canceled-successfully"),
        confirmButtonText: t("ok"),
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
      dispatch(fetchOrders());
      dispatch(clearState());
    }
  }, [dispatch, t, success]);

  const handleConfirmCancel = (id) => {
    return MySwal.fire({
      title: "",
      text: `${t("are-you-sure-you-would-like-to-remove-this-order")} ${id}`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: t("cancel"),
      confirmButtonText: cancelOrderLoading ? (
        <>
          <Spinner color="white" size="sm" /> {t("loading")}
        </>
      ) : (
        t("yes")
      ),
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-outline-danger ms-1",
      },
      buttonsStyling: false,
    }).then(async function (result) {
      if (result.value) {
        onCancelOrder(id);
      } else if (result.dismiss === MySwal.DismissReason.cancel) {
        MySwal.fire({
          title: t("cancelled"),
          text: "",
          icon: "error",
          confirmButtonText: t("ok"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      }
    });
  };

  const onPagePaginate = (data) => {
    dispatch(handlePagePagination({ page: data }));
    if (data > 1) {
      dispatch(fetchOrders({ page: data }));
    } else {
      dispatch(fetchOrders());
    }
  };

  useEffect(() => {
    dispatch(fetchOrders());
  }, [dispatch]);

  return (
    <>
      <div className={`${classes.table} main-table`}>
        <table className="w-100">
          <thead>
            <tr>
              <th>{t("order")}</th>
              <th>{t("date")}</th>
              <th>{t("payment-method")}</th>
              <th>{t("fulfillment-status")}</th>
              <th>{t("total")}</th>
              <th className="text-end">{t("cancel")}</th>
            </tr>
          </thead>
          {data?.length > 0 ? (
            <tbody>
              {data?.map((row, index) => {
                return (
                  <tr key={row.id}>
                    <td>
                      <NavLink
                        to={`/a/o/${row?.encryptId}`}
                        className={`btn btn-normal`}>
                        {row?.id}# {t("show-order")}
                      </NavLink>
                    </td>
                    <td>
                      <div className={`${classes.text} table-text`}>
                        {/* {moment(row?.orderedDate).format("MM-DD-YYYY")} */}
                        {row?.orderedDate}
                      </div>
                    </td>
                    <td>
                      <span className={`${classes.bold}`}>
                        {t(
                          row?.payment?.paymentMethod === "cash"
                            ? "PaymentMethod.cash"
                            : "PaymentMethod.credit-card"
                        )}
                      </span>
                    </td>
                    <td>
                      <div
                        className={`${classes.text} text-capitalize table-text`}>
                        <div
                          className={`${classes.status} status ${row?.orderStatus}`}>
                          <span
                            className={`${classes.bullet} bullet ${row?.orderStatus}Bullet`}></span>{" "}
                          {t(`Status.${row?.orderStatus}`)}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div
                        className={`${classes.text} ${classes.totalPrice} table-text`}>
                        {row?.totalGrandPrice} {t("currency")}
                      </div>
                    </td>
                    <td>
                      <div
                        className={`${classes.text} table-text justify-content-end`}>
                        {row?.cancelled ? (
                          <b>{t("cancelled")}</b>
                        ) : (
                          <button
                            disabled={row?.cancelOrderLoading}
                            className="btn btn-danger"
                            onClick={() => handleConfirmCancel(row.id)}>
                            {t("cancel")}
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            ""
          )}
        </table>

        {data?.length === 0 ? (
          <EmptyProductsMsg
            className={"pt-4"}
            text={t("you-havent-placed-any-orders-yet")}
          />
        ) : (
          ""
        )}
        {loading ? <CircleSpinner className={`pt-4`} size="lg" /> : ""}
      </div>

      {paginate?.last_page > 1 ? (
        <Paginate
          page={pagePaginate}
          count={paginate?.last_page}
          onPage={onPagePaginate}
        />
      ) : (
        ""
      )}
    </>
  );
};
