import React, { useState } from "react";
import classes from "./Delivery.module.css";

/** Third Party **/
import { Helmet } from "react-helmet";
import { Config } from "../../config/config";

const Delivery = () => {
  return (
    <>
      <Helmet>
        <title>{Config.name} | Delivery</title>
      </Helmet>
      <div className={`${classes.container}`}>
        <div className={`container`}>
          <div className={`${classes.block} container-sm`}>
            <div className={`title-container text-center`}>
              <div className={`title`}>Delivery</div>
            </div>

            <div className={`page-content pt-0`}>
              {/* Details */}
              <div className={`${classes.details}`}>
                <div className={`${classes.text} text mb-2`}>
                  <div class="PageContent PageContent--narrow Rte">
                    <h4>
                      <span>&nbsp;Which company do you ship with ?</span>
                    </h4>
                    <ul>
                      <li>
                        In Kuwait, we in-house drivers our courier solution for
                        local deliveries.
                      </li>
                      <li>
                        For GCC and international deliveries through Fedex, DHL,
                        and Aramex&nbsp;
                      </li>
                    </ul>
                    <h4>
                      <span>
                        How long does it take to deliver an order in Kuwait ?
                      </span>
                    </h4>
                    <ul>
                      <li>
                        <span>
                          Since one of our logistic center&nbsp;is based in
                          Kuwait, orders get fulfilled and delivered within 1 to
                          2 working days in normal conditions as long as the
                          full address is mentioned clearly with a correct and
                          active phone number.
                        </span>
                      </li>
                    </ul>
                    <h4>
                      <span>
                        How long does it take to deliver an order outside Kuwait
                        ?
                      </span>
                    </h4>
                    <ul>
                      <li>Deliveries for GCC will be 3-5 working days&nbsp;</li>
                      <li>
                        Deliveries for international will be 5-7 working days
                      </li>
                    </ul>
                    <h4>
                      <span>How can I track my order ?</span>
                    </h4>
                    <ul>
                      <li>
                        <span>
                          If you’re a registered user, you can head over to your
                          orders page, and hit the “Track Your Order” button and
                          it’ll show you the exact status of your order or you
                          can go and to"Track Your Order" in the bottom of home
                          page and fill details. If you have any further
                          inquiries, please feel free to reach us by email, live
                          chat or over the phone and we’ll assist you at the
                          earliest.
                        </span>
                      </li>
                    </ul>
                    <p>
                      <span>&nbsp;</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { Delivery };
