import React, { useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import classes from "./CategoryCardDouble.module.css";
import { Avatar, OnSale } from "@components";
import { useTranslation } from "react-i18next";
import { slug } from "@services";

export const CategoryCardDouble = ({ row, className }) => {
  const { t } = useTranslation();

  return (
    <>
      <NavLink
        data-aos="zoom-out-down"
        to={`/sc/${slug(row?.title)}/${row?.encryptId}?cid=${row?.id}`}
        className={`${classes.card}`}>
        <div className={`${classes.cardTitle}`}>
          <span className="lines-2">{row?.title}</span>
        </div>
        <div className={`${classes.cardImg}`}>
          <Avatar className={`${classes.cardImgView}`} src={row?.media?.url} />
        </div>
      </NavLink>
    </>
  );
};
