import React, { useEffect, useState } from "react";
import classes from "../ContactUs.module.css";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";

/** Redux **/
import { createContactUs, clearState } from "@store/contactUs";
import { useDispatch, useSelector } from "react-redux";

export const ContactForm = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { data, loading, success } = useSelector(
    (state) => state.contactUs.contactUs
  );

  const schema = yup
    .object({
      fullName: yup
        .string()
        .min(3, t("fullNameIsTooShort"))
        .required(t("fullNameIsRequired")),
      email: yup
        .string()
        .required(t("emailAddressIsRequired"))
        .email(t("emailAddressIsValid"))
        .min(6, t("emailAddressIsTooShort")),
      phoneNumber: yup
        .string()
        .required(t("phoneNumberIsRequired"))
        .min(6, t("phoneNumberIsTooShort")),
      subject: yup
        .string()
        .min(2, t("subjectIsTooShort"))
        .required(t("subjectIsRequired")),
      message: yup
        .string()
        .min(3, t("messageIsTooShort"))
        .required(t("messageIsRequired")),
    })
    .required();

  const defaultValues = {
    fullName: "",
    email: "",
    phoneNumber: "",
    subject: "",
    message: "",
  };

  const {
    setError,
    clearErrors,
    reset,
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = async (data) => {
    dispatch(createContactUs(data));
    console.log(data);
  };

  useEffect(() => {
    if (success) {
      reset();
      dispatch(clearState());
    }
  }, [dispatch, reset, success]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item lg={12} sm={12} xs={12}>
            <Controller
              control={control}
              {...register("fullName")}
              render={({ field: { onChange, value } }) => (
                <TextField
                  helperText={
                    errors?.fullName ? errors?.fullName.message : null
                  }
                  size="small"
                  error={!!errors?.fullName}
                  onChange={onChange}
                  value={value}
                  fullWidth
                  label={t("fullName")}
                  variant="outlined"
                />
              )}
            />
          </Grid>

          <Grid item lg={6} sm={6} xs={12}>
            <Controller
              control={control}
              {...register("email")}
              render={({ field: { onChange, value } }) => (
                <TextField
                  helperText={errors?.email ? errors?.email.message : null}
                  size="small"
                  error={!!errors?.email}
                  onChange={onChange}
                  value={value}
                  fullWidth
                  label={t("emailAddress")}
                  variant="outlined"
                />
              )}
            />
          </Grid>

          <Grid item lg={6} sm={6} xs={12}>
            <Controller
              control={control}
              {...register("phoneNumber")}
              render={({ field: { onChange, value } }) => (
                // <TextField
                //   helperText={
                //     errors?.phoneNumber ? errors?.phoneNumber.message : null
                //   }
                //   size="small"
                //   error={!!errors?.phoneNumber}
                //   onChange={onChange}
                //   value={value}
                //   fullWidth
                //   label={t("phoneNumber")}
                //   variant="outlined"
                // />

                <PhoneInput
                  helperText={
                    errors?.phoneNumber ? errors?.phoneNumber.message : null
                  }
                  error={!!errors?.phoneNumber}
                  country={"kw"}
                  value={value}
                  onChange={onChange}
                  className={"phone-number-container"}
                />
              )}
            />
          </Grid>

          <Grid item lg={12} sm={12} xs={12}>
            <Controller
              control={control}
              {...register("subject")}
              render={({ field: { onChange, value } }) => (
                <TextField
                  helperText={errors?.subject ? errors?.subject.message : null}
                  size="small"
                  error={!!errors?.subject}
                  onChange={onChange}
                  value={value}
                  fullWidth
                  label={t("subject")}
                  variant="outlined"
                />
              )}
            />
          </Grid>

          <Grid item lg={12} xs={12}>
            <Controller
              control={control}
              {...register("message")}
              render={({ field: { onChange, value } }) => (
                <TextField
                  helperText={errors?.message ? errors?.message.message : null}
                  size="small"
                  error={!!errors?.message}
                  onChange={onChange}
                  value={value}
                  fullWidth
                  label={t("yourMessage")}
                  variant="outlined"
                  multiline
                  rows={6}
                  defaultValue="Default Value"
                />
              )}
            />
          </Grid>
        </Grid>
        <div className="d-flex justify-content-center">
          <Button
            disabled={loading}
            center
            className="mt-3"
            type="submit"
            size="large"
            color="primary"
            variant="contained">
            {loading ? (
              <>
                <CircularProgress size={17} style={{ color: "#fff" }} />
                {t("loading")}
              </>
            ) : (
              t("sendMessage")
            )}
          </Button>
        </div>
      </form>
    </>
  );
};
