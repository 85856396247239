import React, { useState, useEffect } from "react";
import classes from "./Filter.module.css";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Button from "@mui/material/Button";
import { CircleSpinner } from "@components";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

/** Redux **/
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFilterOptions,
  fetchOptionsFiltered,
  fetchProducts,
  fetchFilteredProducts,
  onFilterIds,
} from "@store/storeCollection";

/** Third Party **/
import { Config } from "@config/config";
import { Categories } from "./Categories/Categories";
import { Brands } from "./Brands/Brands";
import { PriceRange } from "./PriceRange/PriceRange";
import { Discounts } from "./Discounts/Discounts";
import { Colors } from "./Colors/Colors";
import { Attributes } from "./Attributes/Attributes";

export const Filter = ({ onIdsFromParent, onSortFromParent, sortGrid }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { category, title, slug: slugOrId } = useParams();
  const stateData = location.state;
  // const categoryKeyQuery = new URLSearchParams(location.search).get("c");
  const categoryIdQuery = new URLSearchParams(location.search).get("cid");
  const {
    categories,
    brands,
    discounts,
    attributes: {
      colors,
      sizes,
      printSpeed,
      sensor,
      printQuality,
      connectivity,
      duplex,
      type,
      functions,
      monthlyDutyCycle,
      bagSize,
    },
    loading,
  } = useSelector((state) => state.storeCollection.filter);

  const [finalCategoriesIds, setFinalCategoriesIds] = useState(null);
  const [finalBrandsIds, setFinalBrandsIds] = useState(null);
  const [finalDiscountsIds, setFinalDiscountsIds] = useState(null);
  const [finalColorsIds, setFinalColorsIds] = useState(null);
  const [finalSizesIds, setFinalSizesIds] = useState(null);
  const [priceRange, setPriceRange] = useState([20, 80]);
  const [finalPrintSpeedIds, setFinalPrintSpeedIds] = useState(null);
  const [finalSensorIds, setFinalSensorIds] = useState(null);
  const [finalPrintQualityIds, setFinalPrintQualityIds] = useState(null);
  const [finalConnectivityIds, setFinalConnectivityIds] = useState(null);
  const [finalDuplexIds, setFinalDuplexIds] = useState(null);
  const [finalTypeIds, setFinalTypeIds] = useState(null);
  const [finalFunctionsIds, setFinalFunctionsIds] = useState(null);
  const [finalMonthlyDutyCycleIds, setFinalMonthlyDutyCycleIds] =
    useState(null);
  const [finalBagSizeIds, setFinalBagSizeIds] = useState(null);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filterContainer, setFilterContainer] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const toggleFilter = () => setFilterContainer((prevState) => !prevState);

  const {
    data,
    paginate,
    pagePaginate,
    loading: filteredProductsLoading,
  } = useSelector((state) => state.storeCollection.products);

  const onCategoriesIds = (data) => {
    setFinalCategoriesIds(data);
    dispatch(onFilterIds({ mainCategoriesIds: data }));
  };

  const onPriceRange = (data) => {
    setPriceRange(data);
    dispatch(onFilterIds({ priceRange: data }));
  };

  const onBrandsIds = (data) => {
    setFinalBrandsIds(data);
    dispatch(onFilterIds({ brandsIds: data }));
  };

  const onDiscountsIds = (data) => {
    setFinalDiscountsIds(data);
    dispatch(onFilterIds({ discountsIds: data }));
  };

  const onColorsIds = (data) => {
    setFinalColorsIds(data);
    dispatch(onFilterIds({ colorsIds: data }));
  };

  const onSizesIds = (data) => {
    setFinalSizesIds(data);
    dispatch(onFilterIds({ sizesIds: data }));
  };

  const onPrintSpeedIds = (data) => {
    setFinalPrintSpeedIds(data);
    dispatch(onFilterIds({ printSpeed: data }));
  };

  const onSensorIds = (data) => {
    setFinalSensorIds(data);
    dispatch(onFilterIds({ sensor: data }));
  };

  const onPrintQualityIds = (data) => {
    setFinalPrintQualityIds(data);
    dispatch(onFilterIds({ printQuality: data }));
  };

  const onConnectivityIds = (data) => {
    setFinalConnectivityIds(data);
    dispatch(onFilterIds({ connectivity: data }));
  };

  const onDuplexIds = (data) => {
    setFinalDuplexIds(data);
    dispatch(onFilterIds({ duplex: data }));
  };

  const onTypeIds = (data) => {
    setFinalTypeIds(data);
    dispatch(onFilterIds({ type: data }));
  };

  const onFunctionsIds = (data) => {
    setFinalFunctionsIds(data);
    dispatch(onFilterIds({ functions: data }));
  };

  const onMonthlyDutyCycleIds = (data) => {
    setFinalMonthlyDutyCycleIds(data);
    dispatch(onFilterIds({ monthlyDutyCycle: data }));
  };

  const onBagSizeIds = (data) => {
    setFinalBagSizeIds(data);
    dispatch(onFilterIds({ bagSize: data }));
  };

  useEffect(() => {
    dispatch(
      fetchFilterOptions({
        category: category,
        categoryId: categoryIdQuery,
      })
    );

    setFinalCategoriesIds(null);
    setPriceRange([20, 80]);
    setFinalBrandsIds(null);
    setFinalDiscountsIds(null);
    setFinalColorsIds(null);
    setFinalSizesIds(null);
    setFinalPrintSpeedIds(null);
    setFinalSensorIds(null);
    setFinalPrintQualityIds(null);
    setFinalConnectivityIds(null);
    setFinalDuplexIds(null);
    setFinalTypeIds(null);
    setFinalFunctionsIds(null);
    setFinalMonthlyDutyCycleIds(null);
    setFinalBagSizeIds(null);
    dispatch(
      onFilterIds({
        mainCategoriesIds: null,
        priceRange: null,
        brandsIds: null,
        discountsIds: null,
        colorsIds: null,
        sizesIds: null,
        printSpeed: null,
        sensor: null,
        printQuality: null,
        connectivity: null,
        duplex: null,
        type: null,
        functions: null,
        monthlyDutyCycle: null,
        bagSize: null,
      })
    );
  }, [dispatch, category, categoryIdQuery]);

  const onSubmitFilter = () => {
    const data = {
      mainCategoriesIds: finalCategoriesIds,
      priceRange,
      brandsIds: finalBrandsIds,
      discountsIds: finalDiscountsIds,
      colorsIds: finalColorsIds,
      sizesIds: finalSizesIds,
      printSpeed: finalPrintSpeedIds,
      sensor: finalSensorIds,
      printQuality: finalPrintQualityIds,
      connectivity: finalConnectivityIds,
      duplex: finalDuplexIds,
      type: finalTypeIds,
      functions: finalFunctionsIds,
      monthlyDutyCycle: finalMonthlyDutyCycleIds,
      bagSize: finalBagSizeIds,
      category: category,
      categoryId: categoryIdQuery,
    };

    dispatch(fetchFilteredProducts(data));
    // onIdsFromParent(data);
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.block}>
          <div className={classes.left}>
            <div className={classes.sortGrid}>
              {/* <button
                onClick={() => onSortFromParent(2)}
                className={`${classes.sortGridBtn} ${
                  sortGrid === 2 ? classes.active : ""
                } btn-empty`}>
                <span className={`icon-double-grid ${classes.icon}`}></span>
              </button> */}
              <button
                className={`${classes.sortGridBtn} ${classes.active} btn-empty`}>
                <span className={`icon-terrible-grid ${classes.icon}`}></span>
              </button>
            </div>
          </div>

          <div className={classes.right}>
            {/* <div className={classes.sortList}>
              <Dropdown
                isOpen={dropdownOpen}
                toggle={toggle}
                direction={"direction"}>
                <DropdownToggle>
                  {t("sort")}
                  <span className={`icon-chevron-down icon`}></span>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem>Test 2</DropdownItem>
                  <DropdownItem>Test 3</DropdownItem>
                  <DropdownItem>Test 4</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div> */}

            <div className={classes.sortFilter}>
              <button
                onClick={toggleFilter}
                className={`${classes.sortFilterBtn} btn-empty`}>
                {t("filter")}
              </button>
              <div
                className={`${classes.sortFilterContainer} ${
                  filterContainer ? classes.active : ""
                }`}>
                <div className={classes.sortContent}>
                  {/* Header */}
                  <div className={classes.sortHeader}>
                    <div className={`${classes.sortTitle} text-center`}>
                      {t("filter")}
                    </div>
                    <button
                      onClick={toggleFilter}
                      className={`${classes.sortExit} btn-empty`}>
                      <span className={`icon-cancel ${classes.icon}`}></span>
                    </button>
                  </div>

                  {/* Body */}
                  <div className={`${classes.sortBody} scroller`}>
                    {/* Categories */}
                    {category === "b" ? (
                      <div className={classes.sortType}>
                        <div className={classes.sortBodyTitle}>Categories</div>
                        <Categories
                          onCategoriesFromParent={onCategoriesIds}
                          categories={categories}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {/* Price */}
                    <PriceRange
                      onPriceRangeFromParent={onPriceRange}
                      priceRange={priceRange}
                    />

                    {/* Brands */}
                    <div className={classes.sortAvailability}>
                      <div className={classes.sortBodyTitle}>{t("brands")}</div>
                      <Brands
                        onBrandsFromParent={onBrandsIds}
                        brands={brands}
                        loading={loading}
                      />
                    </div>

                    {/* Discounts */}
                    <div className={classes.sortAvailability}>
                      <div className={classes.sortBodyTitle}>
                        {t("discounts")}
                      </div>
                      <Discounts
                        onDiscountsFromParent={onDiscountsIds}
                        discounts={discounts}
                        loading={loading}
                      />
                    </div>

                    {/* Colors */}
                    {colors?.length > 0 ? (
                      <div className={classes.sortAvailability}>
                        <div className={classes.sortBodyTitle}>
                          {t("colors")}
                        </div>
                        <Colors
                          onColorsFromParent={onColorsIds}
                          colors={colors}
                          loading={loading}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {/* Size */}
                    {sizes?.length > 0 ? (
                      <div className={classes.sortAvailability}>
                        <div className={classes.sortBodyTitle}>
                          {t("sizes")}
                        </div>
                        <Attributes
                          onDataAttrsFromParent={onSizesIds}
                          dataAttrs={sizes}
                          loading={loading}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {/* Print Speed */}
                    {printSpeed?.length > 0 ? (
                      <div className={classes.sortAvailability}>
                        <div className={classes.sortBodyTitle}>
                          {t("print-speed")}
                        </div>
                        <Attributes
                          onDataAttrsFromParent={onPrintSpeedIds}
                          dataAttrs={printSpeed}
                          loading={loading}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {/* Sensor */}
                    {sensor?.length > 0 ? (
                      <div className={classes.sortAvailability}>
                        <div className={classes.sortBodyTitle}>
                          {t("sensor")}
                        </div>
                        <Attributes
                          onDataAttrsFromParent={onSensorIds}
                          dataAttrs={sensor}
                          loading={loading}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {/* Print Quality */}
                    {printQuality?.length > 0 ? (
                      <div className={classes.sortAvailability}>
                        <div className={classes.sortBodyTitle}>
                          {t("print-quality")}
                        </div>
                        <Attributes
                          onDataAttrsFromParent={onPrintQualityIds}
                          dataAttrs={printQuality}
                          loading={loading}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {/* Connectivity */}
                    {connectivity?.length > 0 ? (
                      <div className={classes.sortAvailability}>
                        <div className={classes.sortBodyTitle}>
                          {t("connectivity")}
                        </div>
                        <Attributes
                          onDataAttrsFromParent={onConnectivityIds}
                          dataAttrs={connectivity}
                          loading={loading}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {/* Duplex */}
                    {duplex?.length > 0 ? (
                      <div className={classes.sortAvailability}>
                        <div className={classes.sortBodyTitle}>
                          {t("connectivity")}
                        </div>
                        <Attributes
                          onDataAttrsFromParent={onDuplexIds}
                          dataAttrs={duplex}
                          loading={loading}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {/* Type */}
                    {type?.length > 0 ? (
                      <div className={classes.sortAvailability}>
                        <div className={classes.sortBodyTitle}>{t("type")}</div>
                        <Attributes
                          onDataAttrsFromParent={onTypeIds}
                          dataAttrs={type}
                          loading={loading}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {/* Functions */}
                    {functions?.length > 0 ? (
                      <div className={classes.sortAvailability}>
                        <div className={classes.sortBodyTitle}>
                          {t("functions")}
                        </div>
                        <Attributes
                          onDataAttrsFromParent={onFunctionsIds}
                          dataAttrs={functions}
                          loading={loading}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {/* Monthly Duty Cycle */}
                    {monthlyDutyCycle?.length > 0 ? (
                      <div className={classes.sortAvailability}>
                        <div className={classes.sortBodyTitle}>
                          {t("monthly-duty-cycle")}
                        </div>
                        <Attributes
                          onDataAttrsFromParent={onMonthlyDutyCycleIds}
                          dataAttrs={monthlyDutyCycle}
                          loading={loading}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {/* Bag Size */}
                    {bagSize?.length > 0 ? (
                      <div className={classes.sortAvailability}>
                        <div className={classes.sortBodyTitle}>
                          {t("bag-size")}
                        </div>
                        <Attributes
                          onDataAttrsFromParent={onBagSizeIds}
                          dataAttrs={bagSize}
                          loading={loading}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  {/* Footer */}
                  <div className={`${classes.sortFooter} text-center`}>
                    <Button
                      disabled={filteredProductsLoading}
                      onClick={onSubmitFilter}
                      fullWidth
                      size="large"
                      color="primary"
                      variant="contained">
                      {filteredProductsLoading ? (
                        <>
                          <CircularProgress
                            size={17}
                            style={{ color: "#fff" }}
                          />
                          {t("loading")}
                        </>
                      ) : (
                        t("see-results")
                      )}
                    </Button>
                  </div>
                </div>
              </div>

              {/* Backdrop */}
              <div
                onClick={toggleFilter}
                className={`${classes.sortBackdrop} ${
                  filterContainer ? classes.active : ""
                }`}></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
