import React, { useState, useEffect } from "react";
import classes from "./ProductCardActions.module.css";
import { Avatar, OnSale, Bookmark, Share } from "@components";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { jwt } from "@services";
import CircularProgress from "@mui/material/CircularProgress";
import { fetchCart, addCart, clearState } from "@store/cart";
import { createFavorite, deleteFavorite } from "@store/wishlist";
import { useDispatch, useSelector } from "react-redux";

export const ProductCardActions = ({ row, className }) => {
  const { t } = useTranslation();
  const jwToken = jwt();
  const dispatch = useDispatch();
  const [currentId, setCurrentId] = useState(null);
  const [currentIdFavorite, setCurrentIdFavorite] = useState(null);
  const [inWishlist, setInWishlist] = useState(row?.inWishlist);
  const {
    data: cartData,
    loading: cartLoading,
    success: successAddToCart,
  } = useSelector((state) => state.cart.addCart);
  const {
    data: favoriteData,
    loading: favoriteLoading,
    success: favoriteSuccess,
  } = useSelector((state) => state.wishlist.addFavorite);
  const {
    data: deleteFavoriteData,
    loading: deleteFavoriteLoading,
    success: deleteFavoriteSuccess,
  } = useSelector((state) => state.wishlist.deleteFavorite);

  const onAddToCart = (id) => {
    setCurrentId(id);
    dispatch(
      addCart({
        productId: id,
        qty: 1,
      })
    )
      .then((res) => {
        setCurrentId(null);
        dispatch(clearState());
        dispatch(fetchCart());
      })
      .catch((err) => {
        setCurrentId(null);
        dispatch(clearState());
      });
  };

  const onToggleFavorite = (id) => {
    setCurrentIdFavorite(id);
    if (inWishlist) {
      dispatch(deleteFavorite({ productId: id }))
        .then((res) => {
          setCurrentIdFavorite(null);
          const status = res?.payload?.status;
          if (status === 200 || status === 201 || status === 422) {
            setInWishlist(false);
          }
          console.log(res);
          console.log(inWishlist);
        })
        .catch((err) => {
          setCurrentIdFavorite(null);
          console.log(err);
        });
    } else {
      dispatch(createFavorite({ productId: id }))
        .then((res) => {
          setCurrentIdFavorite(null);
          const status = res?.payload?.status;
          if (status === 200 || status === 201) {
            setInWishlist(true);
          }
        })
        .catch((err) => {
          setCurrentIdFavorite(null);
          console.log(err);
        });
    }
  };

  return (
    <div className={`${classes.action} ${className} pt-3`}>
      {/* <NavLink to={`/products/${row?.id}`} className={`btn btn-corner`}>
            {t("view-product")}{" "}
            <span className={`icon-redirection icon`}></span>
          </NavLink> */}

      <button
        disabled={cartLoading}
        className={`${classes.actionBtn} ${classes.actionBtnCart} ${
          currentId === row.id ? classes.active : ""
        } `}
        onClick={() => onAddToCart(row.id)}>
        {currentId === row.id && cartLoading ? (
          <>
            <CircularProgress size={17} style={{ color: "#fff" }} />
            {t("loading")}
          </>
        ) : (
          <>
            {t("add-to-cart")}
            <span className={`icon-handbag ${classes.icon}`}></span>
          </>
        )}
      </button>

      {jwToken ? (
        <button
          disabled={
            (favoriteLoading && currentIdFavorite) ||
            (deleteFavoriteLoading && currentIdFavorite)
          }
          onClick={() => onToggleFavorite(row?.id)}
          className={`${classes.actionBtn} ${classes.actionBtnFavorite} ${
            inWishlist ? classes.active : ""
          } ${classes.actionBtnIcon} `}>
          {(favoriteLoading && currentIdFavorite) ||
          (deleteFavoriteLoading && currentIdFavorite) ? (
            <div className={`${classes.smLoading}`}>
              <CircularProgress size={25} />
            </div>
          ) : (
            ""
          )}
          <span
            className={`icon-heart-${inWishlist ? "full" : "empty"} ${
              classes.icon
            }`}></span>
        </button>
      ) : (
        ""
      )}

      <button
        // to={`/products/${row?.id}`}
        className={`${classes.actionBtn} ${classes.actionBtnShare} ${classes.actionBtnIcon} `}>
        <span className={`icon-share-3  ${classes.icon}`}></span>
        <Share className={`${classes.shareContainer}`} row={row} />
      </button>
    </div>
  );
};
