import React, { useState } from "react";
import classes from "./LangSwitcher.module.css";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { Config } from "@config/config";

export const LangSwitcher = ({ className }) => {
  const langs = Config.langs;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const { t, i18n } = useTranslation();

  const changeLanguage = async (event) => {
    await i18n.changeLanguage(event);
    const bodyEl = document.body;
    if (event === "ar") {
      bodyEl.classList.add("rtl-dir");
      window.location.reload();
    } else {
      bodyEl.classList.remove("rtl-dir");
      window.location.reload();
    }
  };

  return (
    <div className={className}>
      <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={"direction"}>
        <DropdownToggle>
          {t("lang")}
          <span className={`icon-chevron-down icon`}></span>
        </DropdownToggle>
        <DropdownMenu>
          {langs.map((row, index) => (
            <DropdownItem onClick={() => changeLanguage(row.code)}>
              {row.title}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
