import React, { useState } from "react";
import classes from "./ContactUs.module.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

/** Third Party **/
import { Helmet } from "react-helmet";
import { Config } from "@config/config";
import { ContactForm } from "./ContactForm/ContactForm";
import { Seo } from "@components";

export const ContactUs = () => {
  const { t } = useTranslation();
  const whatsAppNumber = useSelector(
    (state) => state.general.appSettings.socialMediaData.whatsAppNumber
  );
  return (
    <>
      <Seo title={`${Config.name} | ${t("contactUs")}`} />
      <div className={`${classes.container}`}>
        <div className={`container`}>
          <div className={`${classes.block} container-sm`}>
            <div className={`title-container text-center`}>
              <div className={`title`}>{t("contactUs")}</div>
            </div>

            <div className={`page-content pt-0`}>
              {/* Details */}
              <div className={`${classes.details} mb-5`}>
                <div className={`${classes.text} text mb-2`}>
                  {t("email")}:{" "}
                  <a href="mailto:info@briggekw.com">info@briggekw.com</a>
                </div>

                <div className={`${classes.text} text mb-2`}>
                  {t("phone")}: +965 22216677
                </div>

                {whatsAppNumber ? (
                  <div className={`${classes.text} text`}>
                    {t("whatsapp-chat")}: {whatsAppNumber}
                  </div>
                ) : (
                  ""
                )}
              </div>

              {/* Form */}
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
