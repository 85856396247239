import React, { useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import classes from "./Tracking.module.css";
import { MapContainer } from "./Map/Map";
import { useTranslation } from "react-i18next";

export const Tracking = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes.block}>
        <div className={`${classes.header} mb-4 d-flex align-items-center`}>
          <div className={`${classes.iconBox}`}>
            <span className={`icon-correct ${classes.icon}`}></span>
          </div>
          <div className={`${classes.headerDetails}`}>
            <div className={`${classes.text} small-text`}>
              {t("order")} <b>#9912965</b>
            </div>
            <div className={`${classes.title} title`}>
              {t("thank-you")} emad!
            </div>
          </div>
        </div>

        <div className={`${classes.mapContainer} mb-3`}>
          <div className={`${classes.contentBox}`}>
            <div className={`${classes.map}`}>{<MapContainer />}</div>
            <div className={`${classes.details}`}>
              <div className={`${classes.title} title-regular mb-1`}>
                {t("your-order-is-confirmed")}
              </div>
              <div className={`${classes.text} small-text`}>
                {t("cash-on-delivery-available-only-for-Kuwait")}
              </div>
            </div>
          </div>
        </div>

        <div className={`${classes.trackingContainer}`}>
          <div className={`${classes.contentBox}`}>
            <div className={`${classes.details}`}>
              <div className={`${classes.title} title-md mb-3`}>
                {t("customer-information")}
              </div>
              <div className={`${classes.grid}`}>
                <div className={`${classes.left}`}>
                  <div className={`${classes.row} mb-3`}>
                    <div className={`${classes.title} title-sm mb-1`}>
                      {t("contact-information")}
                    </div>
                    <div className={`${classes.text} small-text`}>
                      emadsamy3030@gmail.com
                    </div>
                  </div>

                  <div className={`${classes.row} mb-3`}>
                    <div className={`${classes.title} title-sm mb-1`}>
                      {t("shipping-address")}
                    </div>
                    <div className={`${classes.text} small-text`}>
                      emad samy
                      <br />
                      يبيبيب
                      <br />
                      يب
                      <br />
                      AH
                      <br />
                      Kuwait
                      <br />
                      +96551084200
                    </div>
                  </div>

                  <div className={`${classes.row}`}>
                    <div className={`${classes.title} title-sm mb-1`}>
                      {t("shipping-method")}
                    </div>
                    <div className={`${classes.text} small-text`}>
                      {t("standard")}
                    </div>
                  </div>
                </div>

                {/* Right */}
                <div className={`${classes.right}`}>
                  <div className={`${classes.row} mb-3`}>
                    <div className={`${classes.title} title-sm mb-1`}>
                      {t("Cart.payment-method")}
                    </div>
                    <div className={`${classes.text} small-text`}>
                      {t("cash-on-delivery")} - <b>{t("currency")} 1,166.000</b>
                    </div>
                  </div>

                  {/* <div className={`${classes.row}`}>
                    <div className={`${classes.title} title-sm mb-1`}>
                      {t("billing-address")}
                    </div>
                    <div className={`${classes.text} small-text`}>
                      emad samy
                      <br />
                      يبيبيب
                      <br />
                      يب
                      <br />
                      AH
                      <br />
                      Kuwait
                      <br />
                      ‎+96551084200
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`d-flex justify-content-between align-items-center pt-3 pb-3`}>
          <div className="small-text d-flex align-items-center">
            {t("need-help")}
            <a
              className="ml-2 mr-2 d-block"
              href="mailto:customerservice@wardrobefashion.com">
              {t("contact-us")}
            </a>
          </div>

          <Button
            component={Link}
            to="/"
            size="large"
            color="info"
            variant="contained">
            {t("continue-shopping")}
          </Button>
        </div>
      </div>
    </div>
  );
};
