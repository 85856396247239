import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import classes from "./Footer.module.css";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Footer = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");

  const handleClickOpen = (data) => {
    setDialogTitle(data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <>
      {/* <Dialog
        open={open}
        onClose={handleClose}
        scroll={"body"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description">
        <DialogTitle id="scroll-dialog-title">{dialogTitle}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
        <DialogContent className="pt-0" dividers={false}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}>
            {[...new Array(50)]
              .map(
                () => `Cras mattis consectetur purus sit amet fermentum.
Cras justo odio, dapibus ac facilisis in, egestas eget quam.
Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`
              )
              .join("\n")}
          </DialogContentText>
        </DialogContent>
      </Dialog> */}

      <NavLink to="/contact-us">
        <div className={`${classes.sfItem}`}>{t("contact-us")}</div>
      </NavLink>

      <NavLink to="/about">
        <div className={`${classes.sfItem}`}>{t("about-us")}</div>
      </NavLink>

      <NavLink to="/faqs">
        <div className={`${classes.sfItem}`}>{t("faqs")}</div>
      </NavLink>

      <NavLink to="/terms-conditions">
        <div className={`${classes.sfItem}`}>{t("terms-of-service")}</div>
      </NavLink>

      <NavLink to="/privacy-policy">
        <div className={`${classes.sfItem}`}>{t("privacy-policy")}</div>
      </NavLink>

      {/* <div>
        <div
          onClick={() => handleClickOpen("Shipping Policy")}
          className={`${classes.sfItem}`}>
          {t("shipping-policy")}
        </div>
      </div>

      <div>
        <div
          onClick={() => handleClickOpen("Privacy Policy")}
          className={`${classes.sfItem}`}>
          {t("privacy-policy")}
        </div>
      </div>

      <div>
        <div
          onClick={() => handleClickOpen("Terms of service")}
          className={`${classes.sfItem}`}>
          {t("terms-of-service")}
        </div>
      </div>

      <div>
        <div
          onClick={() => handleClickOpen("Contact Information")}
          className={`${classes.sfItem}`}>
          {t("contact-information")}
        </div>
      </div> */}
    </>
  );
};
