import React, { useState } from "react";
import classes from "./Seo.module.css";
import { Config } from "@config";
import { Helmet } from "react-helmet";

export const Seo = ({ title, description, keywords }) => {
  return (
    <Helmet prioritizeSeoTags>
      <title>{title}</title>
      <meta name="description" content={description ?? null} />
      <meta name="keywords" content={keywords ?? null} />
    </Helmet>
  );
};
