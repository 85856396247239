import React, { useState } from "react";
import classes from "./Color.module.css";
import { useTranslation } from "react-i18next";

export const Color = ({ onColorFromParent, colors, color }) => {
  const { t } = useTranslation();
  const onColor = (row, id) => {
    onColorFromParent(row, id);
  };

  return (
    <>
      <div className={`${classes.sizeContainer}`}>
        <div className={`${classes.chooseSizeTitle} mb-2`}>
          {t("choose-color")}
        </div>
        <div className={`${classes.sizeGrid}`}>
          {colors?.length > 0
            ? colors?.map((row, index) => {
                const colorExist = row?.title?.match(/#[^\s]+/g);
                return (
                  <button
                    style={{ background: `${colorExist}` }}
                    key={row.id}
                    onClick={() => onColor(row, row.id)}
                    className={`${classes.sizeCard} ${
                      row.id === color ? classes.active : ""
                    }`}>
                    {/* {colorExist} */}
                    {row.id === color ? (
                      <span className={`icon-correct ${classes.icon}`}></span>
                    ) : (
                      ""
                    )}
                  </button>
                );
              })
            : ""}
        </div>
      </div>
    </>
  );
};
