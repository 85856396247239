import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import axiosInstance from "../interceptor/axiosInstance";

const initialState = {
  loading: false,
  data: [],
  rowData: { loading: false, data: {} },
  add: { loading: false, success: false },
  delete: { loading: false, success: false },
  update: { loading: false, success: false },
  status: { loading: false, success: false },
};

// Fetch Data
export const fetchPrivacies = createAsyncThunk(
  "privacyPolicy/fetchPrivacies",
  async (action) => {
    const config = {
      url: `privacyPolicy`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return await axiosInstance(config)
      .then((response) => response.data)
      .catch((errors) => errors.response);
  }
);

// Fetch Data
export const fetchPrivacy = createAsyncThunk(
  "privacyPolicy/fetchPrivacy",
  async (action) => {
    const config = {
      url: `privacyPolicy/${action.dataId}`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return await axiosInstance(config)
      .then((response) => response.data)
      .catch((errors) => errors.response);
  }
);

// Add Data
export const addPrivacy = createAsyncThunk(
  "privacyPolicy/addPrivacy",
  async (action) => {
    const config = {
      url: `privacyPolicy`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        key: action.key,
        value: action.value,
        status: action.isActive ? 1 : 0,
      },
    };
    return await axiosInstance(config)
      .then((response) => response.data)
      .catch((errors) => errors.response);
  }
);

// Update Data
export const updatePrivacy = createAsyncThunk(
  "privacyPolicy/updatePrivacy",
  async (action) => {
    const config = {
      url: `privacyPolicy/${action.rowId}`,
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        id: action.rowId,
        key: action.key,
        value: action.value,
        status: action.isActive ? 1 : 0,
      },
    };
    return await axiosInstance(config)
      .then((response) => response.data)
      .catch((errors) => errors.response);
  }
);

// Delete Data
export const deletePrivacy = createAsyncThunk(
  "privacyPolicy/deletePrivacy",
  async (action) => {
    const config = {
      url: `privacyPolicy/${action.dataId}`,
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return await axiosInstance(config)
      .then((response) => response.data)
      .catch((errors) => errors.response);
  }
);

// Switch Status Data
export const switchStatusPrivacy = createAsyncThunk(
  "privacyPolicy/switchStatusPrivacy",
  async (action) => {
    const config = {
      url: `privacyPolicy/status`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        id: action.dataId,
        status: action.isActive ? 1 : 0,
      },
    };
    return await axiosInstance(config)
      .then((response) => response.data)
      .catch((errors) => errors.response);
  }
);

const privacyPolicyReducer = createSlice({
  name: "privacyPolicy",
  initialState,
  reducers: {
    closeModal: (state) => {
      state.add.success = false;
      state.update.success = false;
      state.delete.success = false;
    },
  },
  extraReducers: (builder) => {
    /********** Fetch Data **********/
    builder.addCase(fetchPrivacies.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchPrivacies.fulfilled, (state, action) => {
      state.loading = false;
      state.add.success = false;
      state.update.success = false;
      if (action.payload && action.payload.success) {
        state.data = action.payload.data;
      }
    });
    builder.addCase(fetchPrivacies.rejected, (state, action) => {
      state.loading = false;
      state.add.success = false;
      state.update.success = false;
    });

    /********** Fetch Single Data **********/
    builder.addCase(fetchPrivacy.pending, (state, action) => {
      state.rowData.loading = true;
    });
    builder.addCase(fetchPrivacy.fulfilled, (state, action) => {
      state.rowData.loading = false;
      if (action.payload && action.payload.success) {
        state.rowData.data = action.payload.data;
      }
    });
    builder.addCase(fetchPrivacy.rejected, (state, action) => {
      state.rowData.loading = false;
    });

    /********** Add Data **********/
    builder.addCase(addPrivacy.pending, (state, action) => {
      state.add.loading = true;
    });
    builder.addCase(addPrivacy.fulfilled, (state, action) => {
      state.add.loading = false;
      if (action.payload && action.payload.success) {
        state.add.success = true;
        toast.success(action.payload.message);

        // For Add
        // const row = action.payload.data
        // state.data = [{ ...row }, ...state.data]
      } else {
        state.add.success = false;
        toast.error(action.payload.message);
        const errorsObj = action.payload.data;
        if (errorsObj) {
          errorsObj.map((row) => {
            toast.error(`${row}`);
          });
        }
      }
    });
    builder.addCase(addPrivacy.rejected, (state, action) => {
      state.add.loading = false;
    });

    /********** Update Data **********/
    builder.addCase(updatePrivacy.pending, (state, action) => {
      state.update.loading = true;
    });
    builder.addCase(updatePrivacy.fulfilled, (state, action) => {
      state.update.loading = false;
      if (action.payload && action.payload.success) {
        state.update.success = true;
        toast.success(action.payload.message);

        // For Edit
        // const row = action.payload.data
        // const newArr = [...state.data]
        // const index = newArr.findIndex(obj => obj.id === row.id) // i can get index
        // newArr[index].name = row.name
        // newArr[index].email = row.email
        // newArr[index].role = "admin"
      } else {
        state.update.success = false;
        toast.error(action.payload.data.message);
        const errorsObj = action.payload.data.data;
        if (errorsObj) {
          errorsObj.map((row) => {
            toast.error(`${row}`);
          });
        }
      }
    });
    builder.addCase(updatePrivacy.rejected, (state, action) => {
      state.update.loading = false;
    });

    /********** Delete Data **********/
    builder.addCase(deletePrivacy.pending, (state, action) => {
      state.delete.loading = true;
    });
    builder.addCase(deletePrivacy.fulfilled, (state, action) => {
      state.delete.loading = false;
      if (action.payload && action.payload.success) {
        state.delete.success = true;
        toast.success(action.payload.message);

        // For Delete
        const newArr = state.data.filter(
          (idx) => idx.id !== action.meta.arg.dataId
        );
        state.data = newArr;
      } else {
        state.delete.success = false;
        toast.error(action.payload.data.message);
      }
    });
    builder.addCase(deletePrivacy.rejected, (state, action) => {
      state.delete.loading = false;
    });

    /********** Switch Status Data **********/
    builder.addCase(switchStatusPrivacy.pending, (state, action) => {
      state.update.loading = true;
    });
    builder.addCase(switchStatusPrivacy.fulfilled, (state, action) => {
      state.update.loading = false;
      if (action.payload && action.payload.success) {
        toast.success(action.payload.message);
        // For Edit
        const row = action.payload.data;
        const newArr = [...state.data];
        const index = newArr.findIndex((obj) => obj.id === row.id);
        newArr[index].status = row.status;
      } else {
        toast.error(action.payload.data.message);
      }
    });
    builder.addCase(switchStatusPrivacy.rejected, (state, action) => {
      state.update.loading = false;
    });
  },
});

export const { closeModal } = privacyPolicyReducer.actions;

export default privacyPolicyReducer.reducer;
