import React, { useState } from "react";
import classes from "./HeadingBar.module.css";
import { useTranslation } from "react-i18next";
import { jwt } from "@services";
import { NavLink } from "react-router-dom";

export const HeadingBar = ({ title, linkTitle, link }) => {
  const { t } = useTranslation();
  const jwToken = jwt();

  return (
    <div className={`${classes.header}`}>
      <div className={`${classes.title}`}>{title}</div>
      {/* {link ? (
        <NavLink className={`btn btn-corner-flat ${classes.link}`} to={link}>
          {linkTitle}
          <span className={`icon-chevron-right icon-chevron`}></span>
        </NavLink>
      ) : (
        ""
      )} */}

      {link ? (
        <div className={`${classes.viewMore}`}>
          <NavLink className={`${classes.viewMoreLink}`} to={link}>
            {t("see-more")}
          </NavLink>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
