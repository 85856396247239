import React, { useState, useEffect } from "react";
import classes from "../Filter.module.css";
import { CircleSpinner, EmptyProductsMsg } from "@components";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";

const Brands = ({ onBrandsFromParent, brands, loading }) => {
  const { t } = useTranslation();
  const [brandsFilter, setBrandsFilter] = useState(null);
  const [brandsFiltered, setBrandsFiltered] = useState([]);
  const location = useLocation();
  const { category, title, slug: slugOrId } = useParams();
  const categoryIdQuery = new URLSearchParams(location.search).get("cid");

  const reinitBrands = async () => {
    let newArray = null;
    newArray = await brands?.map((row, index) => {
      if (brandsFiltered) {
        const data = brandsFiltered.includes(row.id);
        return {
          id: row.id,
          title: row.title,
          selected: data ? true : false,
        };
      } else {
        return {
          id: row.id,
          title: row.title,
          selected: false,
        };
      }
    });

    setBrandsFilter(newArray);
  };

  useEffect(() => {
    if (brands) {
      reinitBrands();
    }
  }, [brands]);

  // useEffect(() => {
  //   if (loading) {
  //     setBrandsFiltered([]);
  //   }
  // }, [loading]);

  const onActiveBrand = (index, id) => {
    if (brandsFilter[index]) {
      let newArr = [...brandsFilter];
      const newSelected = !newArr[index].selected;
      newArr[index].selected = newSelected;
      setBrandsFilter(newArr);

      const exists = brandsFiltered.includes(id);
      if (exists) {
        if (!newSelected) {
          const removeValue = brandsFiltered.filter((item) => item !== id);
          setBrandsFiltered(removeValue);
          onBrandsFromParent(removeValue);
        }
      } else {
        if (newSelected) {
          const newBrandsFiltered = [...brandsFiltered, id];
          setBrandsFiltered(newBrandsFiltered);
          onBrandsFromParent(newBrandsFiltered);
        }
      }
    }
  };

  useEffect(() => {
    // setBrandsFilter(brands);
    reinitBrands();
  }, [category, categoryIdQuery]);

  return loading ? (
    <CircleSpinner size={35} />
  ) : brandsFilter?.length ? (
    <div className={`${classes.filterItemsGrid}`}>
      {brandsFilter?.map((row, index) => {
        return (
          <div key={row.id}>
            <button
              onClick={() => onActiveBrand(index, row.id)}
              className={`btn btn-empty ${classes.sortItemTitle} ${
                row.selected ? classes.active : ""
              }`}>
              {row?.title}
            </button>
          </div>
        );
      })}
    </div>
  ) : (
    <EmptyProductsMsg fontSize={16} text={t("no-data-description")} />
  );
};

export { Brands };
