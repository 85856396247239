import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import classes from "./Header.module.css";
import { Avatar } from "@components";
import { Config } from "@config/config";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { jwt } from "@services";

export const Header = (props) => {
  const jwToken = jwt();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [activeBtn, setActiveBtn] = useState("");

  const onTab = (tab) => {
    // console.log(new URLSearchParams(location.search).get("tab"));
    // navigate(`${location.pathname}?tab=${tab}`);
    setActiveBtn(tab);
    props.onTabFromParent(tab);
  };

  useEffect(() => {
    const activeQuery = new URLSearchParams(location.search).get("tab");
    setActiveBtn(activeQuery || "information");
  }, [location.search]);

  const breadcrumbs = [
    <NavLink
      className={classes.breadcrumbBtn}
      underline="hover"
      key="1"
      color="inherit"
      to="/cart">
      {t("cart")}
    </NavLink>,
    jwToken ? (
      <button
        className={`btn ${classes.breadcrumbBtn} ${
          activeBtn == "information" ? classes.active : ""
        }`}
        onClick={() => onTab("information")}>
        {t("choose-address")}
      </button>
    ) : (
      ""
    ),
    <button
      className={`btn ${classes.breadcrumbBtn} ${
        activeBtn == "payment" ? classes.active : ""
      }`}
      onClick={() => onTab("payment")}>
      {t("payment")}
    </button>,
  ];

  return (
    <>
      <div className={classes.logo}>
        <NavLink to="/">
          <Avatar src={Config?.logo} alt={Config?.title} />
        </NavLink>
      </div>

      {!props.orderCompleted ? (
        <div className={`${classes.breadcrumb} d-flex justify-content-center`}>
          <Stack spacing={2}>
            <Breadcrumbs
              separator={
                <NavigateNextIcon
                  className={`${classes.nextIcon}`}
                  fontSize="small"
                />
              }
              aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </Stack>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
