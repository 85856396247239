import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import classes from "./CircleCard.module.css";
import { Avatar, OnSale } from "@components";
import WhiteBg from "@assets/img/white-bg.png";
import { slug } from "@services";

export const CircleCard = ({
  row,
  onActiveDropdown,
  className,
  keyword,
  keywordID,
  keywordEncryptID,
}) => {
  const [key, setKey] = useState(null);
  useEffect(() => {
    if (keyword === "c") {
      setKey("sc");
    } else if (keyword === "sc") {
      setKey("s2c");
    } else if (keyword === "b") {
      setKey("b");
    } else {
      setKey("c");
    }
  }, [keyword]);

  return (
    // <div data-aos="zoom-out">
    <NavLink
      data-aos="zoom-out"
      className={classes.card}
      onClick={onActiveDropdown}
      to={`/${keyword}/${slug(row?.title)}/${
        keywordEncryptID ? keywordEncryptID : row?.encryptId
      }?cid=${keywordID}`}>
      {row?.avatar ? (
        row?.avatar?.url?.includes("image-not-found") ? (
          <div className={`${classes.cardBg}`}>
            <Avatar className={`${classes.cardBgImg}`} src={WhiteBg} />
            <div className={`${classes.bg}`}>
              <div className={`${classes.cardBgTitle} lines-2`}>
                {row?.title}
              </div>
              <OnSale sm sale={row?.hasOffer} className={classes.onSale} />
            </div>
          </div>
        ) : (
          <div className={`${classes.dropdownCard}`}>
            <div className={`${classes.imgBlock}`}>
              <Avatar className={`${classes.cardImg}`} src={row?.avatar?.url} />
              <OnSale sm sale={row?.hasOffer} className={classes.onSale} />
            </div>
            <div className={`${classes.dropdownTitle} lines-1`}>
              {row?.title}
            </div>
          </div>
        )
      ) : (
        <div className={`${classes.cardBg}`}>
          <Avatar className={`${classes.cardBgImg}`} src={WhiteBg} />
          <div className={`${classes.bg}`}>
            <div className={`${classes.cardBgTitle} max-1-line`}>
              {row?.title}
            </div>
            <OnSale sm sale={row?.hasOffer} className={classes.onSale} />
          </div>
        </div>
      )}
    </NavLink>
    // </div>
  );
};
