import React, { useState } from "react";
import classes from "./OnSale.module.css";
import { useTranslation } from "react-i18next";

export const OnSale = ({ sale, sm, className }) => {
  const { t } = useTranslation();
  return sale ? (
    <div className={`on-sale ${sm ? "sm" : ""} ${className}`}>
      {t("onSale")}
      {/* <span className={`icon-sale icon`}></span> */}
    </div>
  ) : null;
};
