import React, { useState, useEffect } from "react";
import classes from "./Address.module.css";
import { AddressCard } from "../Account/AddressCard/AddressCard";
import Button from "@mui/material/Button";
import { NavLink, Link } from "react-router-dom";
import { Create } from "./Create/Create";
import { Edit } from "./Edit/Edit";
import { Paginate, CircleSpinner } from "@components";
import { useTranslation } from "react-i18next";
import { Seo } from "@components";

/** Redux **/
import { useDispatch, useSelector } from "react-redux";
import { fetchAddresses, handlePagePagination } from "@store/user";

export const Address = () => {
  const { t, i18n } = useTranslation();
  // const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [editSelected, setEditSelected] = useState(null);
  const pagePaginate = useSelector((state) => state.user.addresses.page);
  const { data, paginate, loading } = useSelector(
    (state) => state.user.addresses
  );

  const handleCreateDialog = (data) => {
    setOpenCreate(!openCreate);
  };

  const handleEditDialog = (isOpen, data) => {
    setOpenEdit(!openEdit);
    console.log(data);
    setEditSelected(data);
  };

  const onPagePaginate = (data) => {
    // setPage(data);
    dispatch(handlePagePagination({ page: data }));
    if (data > 1) {
      dispatch(fetchAddresses({ page: data }));
    } else {
      dispatch(fetchAddresses());
    }
  };

  useEffect(() => {
    dispatch(fetchAddresses());
  }, [dispatch]);

  return (
    <>
      <Seo title={t("my-addresses")} />
      <div className={classes.container}>
        <div className={`container`}>
          <div className={`page-content`}>
            <div className={classes.block}>
              <div className={`${classes.addressDetails}`}>
                <NavLink
                  to="/account"
                  className={`btn default-btn back-btn mb-4`}>
                  <span className={`icon-chevron-left icon`}> </span>
                  {t("back-to-account")}
                </NavLink>

                <div className={`title mb-4`}>{t("my-addresses")}</div>
                <Button
                  onClick={handleCreateDialog}
                  size="large"
                  color="primary"
                  variant="contained">
                  {t("add-new-address")}
                </Button>
              </div>

              <div className={`${classes.data}`}>
                {loading ? (
                  <CircleSpinner size="lg" />
                ) : (
                  <>
                    <div className={`${classes.grid}`}>
                      {data &&
                        data?.map((row, index) => {
                          return (
                            <AddressCard
                              key={row.id}
                              index={index}
                              title
                              actions
                              row={row}
                              onEditToChild={handleEditDialog}
                            />
                          );
                        })}
                    </div>
                    {paginate?.last_page > 1 ? (
                      <Paginate
                        page={pagePaginate}
                        count={paginate?.last_page}
                        onPage={onPagePaginate}
                      />
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Create onCreateFromParent={handleCreateDialog} open={openCreate} />
      {openEdit ? (
        <Edit
          onEditFromParent={handleEditDialog}
          open={openEdit}
          row={editSelected}
        />
      ) : (
        ""
      )}
    </>
  );
};
