import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import classes from "./Checkout.module.css";
import { Info } from "./Info/Info";
import { Payment } from "./Payment/Payment";
import { Footer } from "./Footer/Footer";
import { Header } from "./Header/Header";
import { Item } from "./Item/Item";
import { Tracking } from "./Tracking/Tracking";
import { useSelector, useDispatch } from "react-redux";
import { fetchCart, deleteCartItem, clearState } from "@store/cart";
import { CircleSpinner, EmptyProductsMsg, Seo } from "@components";
import { useTranslation } from "react-i18next";
import { jwt } from "@services";
import { Coupon } from "./Coupon/Coupon";
import { Config } from "@config";

export const Checkout = () => {
  const jwToken = jwt();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activeQuery = new URLSearchParams(location.search).get("tab");
  const [tabActive, setTabActive] = useState("");
  const [areaDeliveryFees, setAreaDeliveryFees] = useState("");
  const [isItemsResponsive, setIsItemsResponsive] = useState(false);
  const { data, loading } = useSelector((state) => state.cart.cartData);
  const { data: userData } = useSelector((state) => state.auth.userData);

  const onTabActive = (dataTab) => {
    navigate(`${location.pathname}?tab=${dataTab}`);
    setTabActive(dataTab);
  };

  useEffect(() => {
    const activeQuery = new URLSearchParams(location.search).get("tab");

    if (activeQuery === "information" && !jwToken) {
      navigate(`${location.pathname}?tab=payment`);
    }

    if (activeQuery === null) {
      navigate(`${location.pathname}?tab=information`);
      setTabActive("information");
    }
  }, [location.search, location.pathname, navigate, jwToken]);

  const onGetFinalDeliveryValue = (value) => {
    setAreaDeliveryFees(value);
  };

  useEffect(() => {
    dispatch(fetchCart());
  }, [dispatch]);

  return (
    <>
      <Seo title={`${Config.name} | ${t("checkout")}`} />
      <div className={classes.container}>
        <div className={classes.block}>
          <div className={classes.grid}>
            {/* Details */}
            <div className={classes.details}>
              <div className={classes.detailsContent}>
                <div className={`${classes.detailsHeader} text-center`}>
                  <Header
                    onTabFromParent={onTabActive}
                    tabActive={tabActive}
                    // orderCompleted={true}
                  />
                </div>

                <div className={`${classes.detailsBody}`}>
                  {activeQuery === "information" || !activeQuery ? (
                    <Info />
                  ) : (
                    ""
                  )}
                  {activeQuery === "payment" ? (
                    <Payment
                      onReceivedAreaDeliveryValue={onGetFinalDeliveryValue}
                    />
                  ) : (
                    ""
                  )}
                  {activeQuery === "tracking" ? <Tracking /> : ""}
                </div>

                <div
                  className={`${classes.shippingFooter} pt-3 pb-3 mt-4 d-flex`}>
                  <Footer />
                </div>
              </div>
            </div>
            {/* Details */}

            {/* Items */}
            <div className={`${classes.items}`}>
              <div className={`${classes.itemsHeaderResponsive}`}>
                <button
                  onClick={() => setIsItemsResponsive(!isItemsResponsive)}
                  className={`w-100 ${classes.btnToggleItemsRes} ${
                    isItemsResponsive ? classes.active : ""
                  } d-flex align-items-center justify-content-between`}>
                  <div
                    className={`${classes.resToggleTitle} d-flex align-items-center`}>
                    <span className={`icon-cart-bag ${classes.icon}`}></span>
                    {t("show-order-summary")}
                    <span
                      className={`icon-chevron-down ${classes.icon} ${classes.iconChevron}`}></span>
                  </div>
                  <div className={`${classes.resTotalPrice}`}>
                    {data?.totaGrand} {t("currency")}
                  </div>
                </button>
              </div>

              <div
                className={`${classes.itemsContent} ${
                  isItemsResponsive ? classes.active : ""
                }`}>
                {loading ? (
                  <CircleSpinner className={"pb-4"} size={"lg"} />
                ) : data?.rows?.length > 0 ? (
                  <>
                    {data?.rows?.map((row, index) => {
                      return <Item key={row.id} row={row} />;
                    })}

                    <Coupon data={data} />

                    <div className={`${classes.billContainer} pt-3`}>
                      <div
                        className={`${classes.billRow} mb-2 d-flex justify-content-between`}>
                        <div className={`${classes.billRowTitle}`}>
                          {t("subtotal")}
                        </div>
                        <div className={`${classes.billRowValue}`}>
                          <b>
                            {data?.subTotal} {t("currency")}
                          </b>
                        </div>
                      </div>

                      <div
                        className={`${classes.billRow} ${
                          data?.discount > 0 ? classes.successColor : ""
                        } mb-2 d-flex justify-content-between`}>
                        <div className={`${classes.billRowTitle} `}>
                          {t("discount")} (-)
                        </div>
                        <div className={`${classes.billRowValue}`}>
                          <b>
                            {data?.discount} {t("currency")}
                          </b>
                        </div>
                      </div>

                      {data?.applyCoupon ? (
                        <div
                          className={`${classes.billRow} ${classes.successColor} mb-2 d-flex justify-content-between`}>
                          <div className={`${classes.billRowTitle}`}>
                            {t("coupon")} (-)
                          </div>
                          <div className={`${classes.billRowValue}`}>
                            <b>
                              {data?.couponValue} {t("currency")}
                            </b>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      <div
                        className={`${classes.billRow} mb-2 d-flex justify-content-between`}>
                        <div className={`${classes.billRowTitle}`}>
                          {t("shipping")} (+)
                        </div>
                        <div className={`${classes.billRowValue}`}>
                          <b>
                            {userData
                              ? data?.deliveryFees
                              : areaDeliveryFees
                              ? areaDeliveryFees
                              : data?.deliveryFees}{" "}
                            {t("currency")}
                          </b>
                        </div>
                      </div>

                      <div
                        className={`${classes.billRow} mb-2 d-flex justify-content-between`}>
                        <div className={`${classes.billRowTitle}`}>
                          {t("tax")} (+)
                        </div>
                        <div className={`${classes.billRowValue}`}>
                          <b>
                            {data?.tax} {t("currency")}
                          </b>
                        </div>
                      </div>

                      <div
                        className={`${classes.billRow} mt-3 d-flex justify-content-between`}>
                        <div className={`${classes.billRowTitleLarge}`}>
                          {t("total-price")}
                        </div>
                        <div className={`${classes.billRowValueLarge}`}>
                          {userData
                            ? data?.totaGrand
                            : areaDeliveryFees
                            ? +data?.totaGrand + +areaDeliveryFees
                            : data?.totaGrand}{" "}
                          {/* {areaDeliveryFees
                            ? +data?.totaGrand + areaDeliveryFees
                            : data?.totaGrand}{" "} */}
                          {t("currency")}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <EmptyProductsMsg text={t("no-data-description")} />
                )}
              </div>
            </div>
            {/* Items */}
          </div>
        </div>
      </div>
    </>
  );
};
