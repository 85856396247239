import React, { useEffect, useState } from "react";
import classes from "./CircleSpinner.module.css";
import CircularProgress from "@mui/material/CircularProgress";

export const CircleSpinner = ({ size, color, padding, className }) => {
  const [selectSize, setSelectSize] = useState(null);
  useEffect(() => {
    if (size === "lg") {
      setSelectSize(70);
    } else {
      setSelectSize(size);
    }
  }, []);

  return (
    <div
      className={`text-center ${
        padding ? classes.loading : ""
      } d-flex align-items-center justify-content-center w-100 ${className}`}>
      <CircularProgress
        size={selectSize ? selectSize : 17}
        style={{ color: color ? color : "var(--main-color)" }}
      />
    </div>
  );
};
