import React, { useState, useEffect } from "react";
import classes from "./SlideCard.module.css";
import { Avatar } from "@components";
import { NavLink } from "react-router-dom";
import { slug } from "@services";

export const SlideCard = ({ row }) => {
  const [key, setKey] = useState(null);
  const [keyName, setKeyName] = useState(null);
  const [keyId, setKeyId] = useState(null);
  const [keyEncryptId, setKeyEncryptId] = useState(null);

  useEffect(() => {
    if (row) {
      setKeyName(slug(row?.title));
      setKeyId(row?.displayInId || row?.actionId);
      setKeyEncryptId(row?.displayInEncryptId || row?.actionEncryptId);

      if (row?.displayIn === "brands" || row?.actionType === "brands") {
        setKey("b");
      } else if (
        row?.displayIn === "sub2Categories" ||
        row?.actionType === "sub2Categories"
      ) {
        setKey("s2c");
      } else if (
        row?.displayIn === "subCategories" ||
        row?.actionType === "subCategories"
      ) {
        setKey("sc");
      } else if (
        row?.displayIn === "mainCategories" ||
        row?.actionType === "mainCategories"
      ) {
        setKey("c");
      } else {
        setKey("c");
      }
    }
  }, [row]);
  return (
    <>
      <div className={`${classes.card}`}>
        <NavLink
          className={`${classes.link}`}
          to={`/${key}/${keyName}/${keyEncryptId}?cid=${keyId}`}>
          <Avatar
            className={classes.image}
            src={row?.media?.url}
            alt={row?.title}
          />
        </NavLink>
      </div>
    </>
  );
};
