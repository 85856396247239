import React, { useState, useEffect } from "react";
import classes from "../Filter.module.css";
import { CircleSpinner, EmptyProductsMsg } from "@components";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";

export const Colors = ({ onColorsFromParent, colors, loading }) => {
  const { t } = useTranslation();
  const [colorsFilter, setColorsFilter] = useState(null);
  const [colorsFiltered, setColorsFiltered] = useState([]);
  const location = useLocation();
  const { category, title, slug: slugOrId } = useParams();
  const categoryIdQuery = new URLSearchParams(location.search).get("cid");

  const reinitColors = async () => {
    let newArray = null;
    newArray = await colors?.map((row, index) => {
      if (colorsFiltered) {
        const data = colorsFiltered.includes(row.id);
        return {
          id: row.id,
          title: row.title,
          selected: data ? true : false,
        };
      } else {
        return {
          id: row.id,
          title: row.title,
          selected: false,
        };
      }
    });

    setColorsFilter(newArray);
  };

  useEffect(() => {
    if (colors) {
      reinitColors();
    }
  }, [colors]);

  const onActiveColor = (index, id) => {
    if (colorsFilter[index]) {
      let newArr = [...colorsFilter];
      const newSelected = !newArr[index].selected;
      newArr[index].selected = newSelected;
      setColorsFilter(newArr);

      const exists = colorsFiltered.includes(id);
      if (exists) {
        if (!newSelected) {
          const removeValue = colorsFiltered.filter((item) => item !== id);
          setColorsFiltered(removeValue);
          onColorsFromParent(removeValue);
        }
      } else {
        if (newSelected) {
          const newColorsFiltered = [...colorsFiltered, id];
          setColorsFiltered(newColorsFiltered);
          onColorsFromParent(newColorsFiltered);
        }
      }
    }
  };

  useEffect(() => {
    setColorsFilter(colors);
    reinitColors();
  }, [category, categoryIdQuery]);

  return loading ? (
    <CircleSpinner size={35} />
  ) : colorsFilter?.length ? (
    <div className={`${classes.filterColorsGrid}`}>
      {colorsFilter?.map((row, index) => {
        const colorExist = row.title.match(/#[^\s]+/g);
        return colorExist ? (
          <div
            key={row.id}
            onClick={() => onActiveColor(index, row.id)}
            className={`${classes.colorCard} ${
              row.selected ? classes.active : ""
            }`}
            style={{
              backgroundColor: colorExist,
            }}>
            <span className={`icon-correct ${classes.colorCardIcon}`}></span>
          </div>
        ) : (
          ""
        );
      })}
    </div>
  ) : (
    <EmptyProductsMsg fontSize={16} text={t("no-data-description")} />
  );
};
