import { createTheme } from "@mui/material/styles";

export const MuiTheme = createTheme({
  typography: {
    // fontFamily: "Poppins, sans-serif",
    breadcrumb: {
      fontSize: "10px !import",
    },
    allVariants: {
      fontFamily: "Almarai, sans-serif",
      // textTransform: "none",
      // fontSize: 16,
    },
  },
  palette: {
    primary: {
      main: "#230B45",
      contrastText: "#ffffff !important",
      borderRadius: "0px",
    },
    secondary: {
      main: "#ffffff",
    },
    info: {
      main: "#5c5c5c",
      contrastText: "#ffffff !important",
    },
  },
  components: {
    MuiSlider: {
      styleOverrides: {
        root: {
          color: "#230B45",
          height: 4,
          "& .MuiSlider-thumb": {
            width: 13,
            height: 13,
            transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
            "&::before": {
              boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)",
            },
            "&:hover, &.Mui-focusVisible": {
              boxShadow: `0px 0px 0px 8px ${"rgb(0 0 0 / 16%)"}`,
            },
            "&.Mui-active": {
              width: 16,
              height: 16,
            },
          },
          "& .MuiSlider-rail": {
            opacity: 0.28,
          },
        },
        // thumb: {
        //   backgroundColor: "green", // Change slider thumb color
        //   width: 16, // Change slider thumb width
        //   height: 16, // Change slider thumb height
        // },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          // color: "white", // Change to the desired color
          marginInlineEnd: "10px",
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          fontSize: "7px !important",
          borderRadius: "0",
          border: "1px solid var(--main-border) !important",
          background: "none",
          outline: "none",
          "&.MuiFilledInput-input": {
            fontSize: "10px !important",
          },
          "&.MuiFilledInput-root:before": {
            border: "none !important",
          },
          "&.MuiFilledInput-root:after": {
            border: "none !important",
          },
          "&.MuiFilledInput-root:before:hover": {
            border: "none",
            outline: "none",
            background: "none",
          },
          "&.MuiFilledInput-root:hover": {
            border: "none",
            background: "none",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&.MuiButton-sizeLarge": {
            // fontFamily: "century-gothic, sans-serif",
            fontSize: "15px",
            textTransform: "capitalize",
            borderRadius: "0",
            // margin: "4px 0",
            padding: "10px 30px",
          },
          "&.MuiButton-sizeMedium": {
            // fontFamily: "century-gothic, sans-serif",
            fontSize: "14px",
            textTransform: "capitalize",
            borderRadius: "0",
            margin: "4px 0",
            padding: "8px 25px",
          },
          "&.MuiButton-sizeSmall": {
            // fontFamily: "century-gothic, sans-serif",
            fontSize: "15px",
            textTransform: "capitalize",
            borderRadius: "0",
            margin: "4px 0",
            padding: "3px 10px",
          },
          // "&:disabled": {
          //   backgroundColor: "#444", // Change to your desired color for disabled buttons
          // },
        },
        containedSuccess: {
          // fontFamily: "century-gothic, sans-serif",
          backgroundColor: "green",
          color: "white",
          "&:hover": {
            backgroundColor: "darkgreen",
          },
        },
        containedInfo: {
          boxShadow: "none",
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          background: "#fff",
          "& .MuiInputBase-root, & .MuiInputLabel-root": {
            fontSize: "1rem",
            minHeight: "46px",
            // fontFamily: "century-gothic, sans-serif",
          },
          "& .MuiInputLabel-root.MuiInputLabel-shrink": {
            // With the smaller font size, the shrink effect
            // shouldn't go quite as high ("-6px" instead of "-9px")
            // in order for it to look correct.
            // transform: "translate(14px, -6px) scale(0.75)",
          },
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          height: "45.75px",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          height: "46px",
          fontSize: "rem",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {},
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: "#666",
          // fontFamily: "century-gothic, sans-serif",
          fontSize: "25px",
          "& .MuiTypography-root": {
            // fontFamily: "century-gothic, sans-serif",
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          maxWidth: "390px",
          width: "100%",
          // transition: "all 0.5s ease-in-out",
          // transform: "translateX(100%)",
        },
      },
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#ccc",
          "& .MuiSvgIcon-root": {
            fontSize: 20,
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          // border: "none",
          // borderRadius: 0,
          paddingInline: "20px",
          // background: "none",
          // marginBottom: "7px",
          textTransform: "capitalize",
          "&.Mui-selected": {
            backgroundColor: "#230B45",
            border: "1px solid #230B45 !important",
            outline: "none !important",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#230B45",
            },
          },
          "&:hover": {
            // backgroundColor: "#230B45 !important",
            // border: "1px solid #230B45 !important",
            // outline: "none !important",
            // color: "#fff",
            fontWeight: "500",
            fontSize: "0.875rem",
            lineHeight: "1.75",
          },
        },
      },
    },
    MuiSvgIcon: {
      root: {
        "body[dir=rtl] &": {
          transform: "scaleX(-1)",
        },
      },
    },
  },
});
