import React, { useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import classes from "./CategoryCardDefault.module.css";
import { Avatar, OnSale } from "@components";
import { useTranslation } from "react-i18next";
import { slug } from "@services";

export const CategoryCardDefault = ({
  row,
  className,
  category,
  keyword,
  keywordID,
  keywordEncryptID,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [categoryType, setCategoryType] = useState(null);
  const idQuery = new URLSearchParams(location.search).get("cid");
  const categoryQuery = new URLSearchParams(location.search).get("c");

  const onMoreCategories = () => {
    if (!categoryQuery) {
      navigate(`/categories?c=scid&cid=${row?.encryptId}`);
    } else {
      navigate(`/categories?c=s2id&cid=${row?.encryptId}`);
    }
  };

  return (
    <>
      <div data-aos="zoom-out-down" className={`${classes.card}`}>
        <NavLink
          to={`/${keyword}/${slug(
            row?.title
          )}/${keywordEncryptID}?cid=${keywordID}`}>
          <OnSale sale={row?.hasOffer} />
          <Avatar
            className={`${className} ${classes.image}`}
            src={row?.media?.url}
            alt={row?.title}
          />
          <div className={`${classes.overlay}`}>
            <div>
              <div className={`${classes.title}`}>{row?.title}</div>
            </div>
          </div>
        </NavLink>
        {row?.childs?.length > 0 ? (
          <Tooltip title={t("view-more")}>
            <div className={`${classes.viewMore}`} onClick={onMoreCategories}>
              {t("sub-categories")}
              <span className={`icon-redirection ${classes.icon}`}></span>
            </div>
          </Tooltip>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
