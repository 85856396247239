import React, { useEffect, useState } from "react";
import classes from "../Store.module.css";
import { Avatar } from "@components/index";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { CircleCard, CircleCollection } from "@components";

export const Categories = ({ items, keyword }) => {
  const { t, i18n } = useTranslation();
  const [enteredKeyword, setEnteredKeyword] = useState(null);

  useEffect(() => {
    if (keyword === "c") {
      setEnteredKeyword("sc");
    } else if (keyword === "sc") {
      setEnteredKeyword("s2c");
    }
  }, [keyword]);

  return (
    <div className={`${classes.categoriesContainer}`}>
      <div className={`${classes.categoriesBlock}`}>
        <CircleCollection
          keyword={enteredKeyword}
          items={items}
          noPadding
          noContainer
        />

        {/* <div className={`${classes.categoriesGrid}`}>
          {items?.map((row, index) => {
            return (
              <CircleCard key={row?.id} row={row} keyword={enteredKeyword} />
            );
          })}
        </div> */}
      </div>
    </div>
  );
};
