import React, { useEffect, useState } from "react";
import Router from "./router";
import classes from "./App.module.css";
import { MainHeader, UpperHeader, Footer, WhatsApp } from "./components";
import { jwt } from "./services/index";

/** Redux **/
import { fetchAppSettings } from "./store/general";
import { userData, logout } from "./store/auth";
import { useDispatch, useSelector } from "react-redux";

function App(props) {
  const dispatch = useDispatch();
  const jwToken = jwt();
  const { loading } = useSelector((state) => state.general.appSettings);
  const socialMedia = useSelector(
    (state) => state.general.appSettings.socialMediaData.data
  );
  const whatsAppNumber = useSelector(
    (state) => state.general.appSettings.socialMediaData.whatsAppNumber
  );

  useEffect(() => {
    dispatch(fetchAppSettings());
    if (jwToken) {
      dispatch(userData());
    }
  }, [dispatch, jwToken]);

  return (
    <>
      <div className={classes.wrapperContainer}>
        <UpperHeader
          socialMedia={socialMedia}
          whatsAppNumber={whatsAppNumber}
        />
        <MainHeader />
        <div className={classes.content}>
          <Router />
        </div>
        <Footer socialMedia={socialMedia} whatsAppNumber={whatsAppNumber} />
      </div>
      {whatsAppNumber ? <WhatsApp whatsAppNumber={whatsAppNumber} /> : ""}
    </>
  );
}

export default App;
