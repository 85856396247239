import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { NavLink, useLocation } from "react-router-dom";

/** Third Party **/
import { Config } from "@config/config";
import classes from "./SuperDeals.module.css";
import {
  Avatar,
  Paginate,
  Product,
  CircleSpinner,
  CategoryCard,
  SearchBar,
  Seo,
} from "@components";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories, handlePagePagination } from "@store/categories";
import { useTranslation } from "react-i18next";

export const SuperDeals = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const [search, setSearch] = useState(null);
  const { data, paginate, loading, pagePaginate } = useSelector(
    (state) => state.categories.categories
  );

  return (
    <>
      <Seo title={`${Config.name} | ${t("super-deals")}`} />
      <div className={classes.container}>
        <div className={`container-fluid`}>
          <div
            className={`${classes.header} title-container text-center d-flex align-items-center justify-content-center pb-4`}>
            <div className={`title ${classes.mainTitle}`}>
              {t("super-deals")}
            </div>
          </div>

          <div className={classes.block}></div>
        </div>
      </div>
    </>
  );
};
