import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import axiosInstance from "../interceptor/axiosInstance";

const initialState = {
  wishlist: {
    data: null,
    paginate: null,
    pagePaginate: 1,
    loading: false,
    success: false,
  },
  addFavorite: { loading: false, success: false },
  deleteFavorite: { loading: false, success: false },
};

// Fetch Wishlist
export const fetchWishlist = createAsyncThunk(
  "fetchWishlist/wishlist",
  async (action) => {
    const config = {
      url: `wishlist?paginate=30`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        page: action?.page,
      },
      params: {
        page: action?.page,
        search: action?.search,
        parentId: action?.parentId,
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

// Create Favorite
export const createFavorite = createAsyncThunk(
  "createFavorite/wishlist",
  async (action) => {
    const config = {
      url: `wishlist`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        productId: action?.productId,
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

// Remove Favorite
export const deleteFavorite = createAsyncThunk(
  "deleteFavorite/wishlist",
  async (action) => {
    const config = {
      url: `wishlist`,
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        productId: action?.productId,
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

const wishlistReducer = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    clearState: (state) => {
      state.wishlist.success = false;
      state.addFavorite.success = false;
      state.deleteFavorite.success = false;
    },
    handlePagePagination: (state, action) => {
      state.wishlist.page = action.payload.page;
    },
  },
  extraReducers: (builder) => {
    /********** Fetch Categories **********/
    builder.addCase(fetchWishlist.pending, (state, action) => {
      state.wishlist.loading = true;
      state.wishlist.data = null;
    });
    builder.addCase(fetchWishlist.fulfilled, (state, action) => {
      state.wishlist.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        state.wishlist.data = payload?.data?.data?.rows;
        state.wishlist.paginate = payload?.data?.data?.paginate;
        state.wishlist.success = true;
        state.wishlist.pagePaginate =
          payload?.data?.data?.paginate?.current_page;
      }
    });
    builder.addCase(fetchWishlist.rejected, (state, action) => {
      state.wishlist.loading = false;
      state.wishlist.data = null;
    });
    /********** Fetch Categories **********/

    /********** Create Address **********/
    builder.addCase(createFavorite.pending, (state, action) => {
      state.addFavorite.loading = true;
    });
    builder.addCase(createFavorite.fulfilled, (state, action) => {
      state.addFavorite.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        state.addFavorite.data = payload?.data?.data?.rows;
        state.addFavorite.success = true;
        state.wishlist.page = 1;
      }
    });
    builder.addCase(createFavorite.rejected, (state, action) => {
      state.addFavorite.loading = false;
    });
    /********** Create Address **********/

    /********** Delete Address **********/
    builder.addCase(deleteFavorite.pending, (state, action) => {
      state.deleteFavorite.loading = true;
    });
    builder.addCase(deleteFavorite.fulfilled, (state, action) => {
      state.deleteFavorite.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        state.deleteFavorite.success = true;
        // const newArr = state.wishlist.data.filter(
        //   (idx) => idx.encryptId !== action.meta.arg.rowId
        // );
        // state.wishlist.data = newArr;
        state.wishlist.page = 1;
      }
    });
    builder.addCase(deleteFavorite.rejected, (state, action) => {
      state.deleteFavorite.loading = false;
    });
    /********** Delete Address **********/
  },
});

export const { handlePagePagination, clearState } = wishlistReducer.actions;

export default wishlistReducer.reducer;
