import React, { useState, useEffect } from "react";
import { Routes, Route, Outlet } from "react-router";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import classes from "./Account.module.css";
import { Addresses } from "./Addresses/Addresses";
import { Orders as OrdersComponent } from "./Orders/Orders";
import { jwt } from "@services";
import { Config } from "@config/config";
import { useTranslation } from "react-i18next";

/** Redux **/
import { useDispatch, useSelector } from "react-redux";
import { userData, logout } from "@store/auth/auth";

export const Orders = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const jwToken = jwt();
  const { data, loading } = useSelector((state) => state.auth.userData);

  const onLogout = () => {
    dispatch(logout());
  };

  useEffect(() => {
    dispatch(userData());
  }, [dispatch, jwToken]);

  return (
    <>
      <div className={classes.grid}>
        <div className={`${classes.orders} w-100 mx-100`}>
          <OrdersComponent />
        </div>
        <div className={`${classes.addresses}`}>
          <div className={`${classes.cardTitle} title-regular`}>
            {t("primary-address")}
          </div>
          <div className={classes.content}>
            <Addresses />
          </div>
        </div>
      </div>
    </>
  );
};
