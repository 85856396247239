import React, { useState } from "react";
import classes from "../Tracking.module.css";
import Map from "google-map-react";

const MarkerContainer = () => (
  <div>
    <img
      alt="Map"
      className="img-fluid location-marker-img"
      style={{
        width: "30px",
        height: "30px",
        // position: "relative",
        // left: "-16px",
        // top: "-13px",
      }}
      src={require("../../../../assets/img/icons/location.svg").default}
    />
  </div>
);

const MapContainer = () => {
  function mapOnChange(e) {
    console.log(e.lat);
    console.log(e.lng);
  }

  function initMap(locLat = 30.0100916, locLng = 31.2053958) {
    const defaultProps = {
      center: {
        lat: locLat,
        lng: locLng,
      },
      zoom: 16,
    };

    return (
      <Map
        className={classes.mapProvider}
        showsUserLocation={true}
        showsTraffic={true}
        showsCompass={true}
        options={
          {
            // styles: mapStyles.default,
          }
        }
        bootstrapURLKeys={{ key: "AIzaSyBtwuPEM2cVeJ6U5AIrVYhE-uTVGR7S0oo" }}
        // AIzaSyBtwuPEM2cVeJ6U5AIrVYhE-uTVGR7S0oo
        center={defaultProps.center}
        zoom={defaultProps.zoom}
        // yesIWantToUseGoogleMapApiInternals={true}
        onClick={mapOnChange}
        // onChange={(e) => console.log(e)}
        // onLoad={(e) => console.log(e)}
        // onGoogleApiLoaded={({ map, maps, places }) =>
        //   handleApiLoaded(map, maps, places)
        // }
        // google={(e) => console.log(e)}
      >
        <MarkerContainer
          lat={defaultProps.center.lat}
          lng={defaultProps.center.lng}
          label="titlelll"
        />
        {/* {marker} */}
      </Map>
    );
  }

  return initMap();
};

export { MapContainer };
