import React, { useState, useEffect } from "react";
import { Routes, Route, Outlet } from "react-router";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import classes from "./Account.module.css";
import { Addresses } from "./Addresses/Addresses";
import { jwt } from "@services";
import { Config } from "@config/config";
import { useTranslation } from "react-i18next";
import { Seo } from "@components";

/** Redux **/
import { useDispatch, useSelector } from "react-redux";
import { userData, logout } from "@store/auth/auth";

/** Pages  **/
import { Orders } from "./Orders";
import UpdateLayout from "./UpdateLayout";

export default function AccountLayout(props) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const updateProfilePage = pathname?.includes("update-") ?? true;
  const jwToken = jwt();
  const { data, loading } = useSelector((state) => state.auth.userData);

  const onLogout = () => {
    dispatch(logout());
  };

  useEffect(() => {
    dispatch(userData());
  }, [dispatch, jwToken]);

  return (
    <>
      <Seo title={`${Config.name} | ${data?.name}`} />
      <div className={classes.container}>
        <div className={`container`}>
          <div className={`page-content`}>
            <div className={classes.block}>
              {/* Details */}
              <div className={`${classes.accountDetails}`}>
                <div
                  className={`d-flex align-items-center justify-content-between`}>
                  <button
                    onClick={onLogout}
                    className={`btn default-btn mb-4 ${classes.logoutBtn}`}>
                    {t("Profile.logout")}
                  </button>
                  <div className={`${classes.accountActions}`}>
                    {!updateProfilePage ? (
                      <NavLink
                        className={`btn-corner btn ${classes.accountAction}`}
                        to={`/account/update-profile`}>
                        {t("Profile.update-profile")}
                        <span className={`icon-edit icon`}></span>
                      </NavLink>
                    ) : (
                      ""
                    )}
                    <NavLink
                      className={`btn-corner btn ${classes.ml}  ${classes.accountAction}`}
                      to={`/account/wishlist`}>
                      {t("Profile.wishlist")}
                      <span className={`icon-heart-full icon`}></span>
                    </NavLink>
                  </div>
                </div>

                <div className={`title mb-3`}>{t("my-account")}</div>

                <div className={`text-2 ${classes.text}`}>
                  {t("welcome-back")} <b>{data?.name}!</b>
                </div>
              </div>

              <Outlet />
              <Routes>
                <Route exact path="" element={<Orders />} />
                <Route path="/*" element={<UpdateLayout />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
