import React, { useState } from "react";
import classes from "./Grid.module.css";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import {
  Avatar,
  Product,
  ProductCardDefault,
  ProductCard,
  HeadingBar,
} from "@components";

export const Grid = ({ title, items, itemsCount, link, linkTitle }) => {
  return (
    <>
      <div className={`${classes.container} text-center`}>
        <div className={`container-fluid`}>
          <HeadingBar title={title} />
          <div className={classes.block}>
            <div className={`cards-grid-four`}>
              {/* ${classes.grid} */}
              {items
                ?.slice(0, itemsCount ? itemsCount : 8)
                .map((row, index) => (
                  <ProductCardDefault row={row} />
                ))}
            </div>
            {/* <div className={classes.action}>
              <Button
                component={Link}
                to={`/store`}
                size="large"
                color="primary"
                variant="contained">
                View All Products
              </Button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
