import React, { useState } from "react";
import classes from "./Subscribe.module.css";
import Button from "@mui/material/Button";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormFeedback } from "reactstrap";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";

/** Redux **/
import {
  createContactUs,
  createNewsLetterContact,
  clearState,
} from "@store/contactUs";
import { useDispatch, useSelector } from "react-redux";

export const Subscribe = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { data, loading, success } = useSelector(
    (state) => state.contactUs.newsLetter
  );
  const whatsAppNumber = useSelector(
    (state) => state.general.appSettings.socialMediaData.whatsAppNumber
  );

  const schema = yup
    .object({
      email: yup
        .string()
        .email(t("Validations.email-must-be-a-valid-email"))
        .min(6, t("Validations.email-too-short"))
        .required(t("Validations.email-address-required")),
    })
    .required();

  const defaultValues = {
    email: "",
  };
  const {
    setError,
    clearErrors,
    reset,
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = async (data) => {
    dispatch(
      createNewsLetterContact({
        fullName: "Brigge",
        email: data.email,
        subject: "Brigge",
        phoneNumber: whatsAppNumber ?? 12345678910,
        message: "From Brigge",
      })
    ).then((res) => {
      const status = res.payload.status;
      if (status === 200 || status === 201) {
        reset();
      }
    });
  };

  return (
    <div className={classes.subscribe}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={`input-box`}>
          <Controller
            control={control}
            {...register("email")}
            render={({ field }) => (
              <input
                className={classes.input}
                invalid={errors.email && true}
                {...field}
                type="text"
                placeholder={t("enterEmail")}
              />
            )}
          />
          {errors.email && <FormFeedback>{errors.email?.message}</FormFeedback>}
        </div>
        <Button
          type="submit"
          size="large"
          color="secondary"
          variant="contained">
          {loading ? (
            <>
              <CircularProgress size={17} style={{ color: "#fff" }} />
              {t("loading")}
            </>
          ) : (
            t("subscribe")
          )}
        </Button>
      </form>
    </div>
  );
};
