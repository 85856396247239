// ** Reducers Imports
import privacyPolicy from "./privacyPolicy/privacyPolicy";
import auth from "./auth/auth";
import general from "./general/general";
import user from "./user/user";
import home from "./home/home";
import product from "./product/product";
import storeCollection from "./storeCollection/storeCollection";
import cart from "./cart/cart";
import checkout from "./checkout/checkout";
import order from "./orders/orders";
import brands from "./brands/brands";
import categories from "./categories/categories";
import search from "./search/search";
import contactUs from "./contactUs/contactUs";
import discounts from "./discounts/discounts";
import wishlist from "./wishlist/wishlist";

const rootReducer = {
  privacyPolicy,
  auth,
  general,
  user,
  home,
  product,
  storeCollection,
  cart,
  checkout,
  order,
  brands,
  search,
  categories,
  contactUs,
  discounts,
  wishlist,
};

export default rootReducer;
