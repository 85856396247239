import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import classes from "./Info.module.css";
import Button from "@mui/material/Button";
import { Paginate, CircleSpinner } from "@components";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import { jwt } from "@services";

/** Redux **/
import { useDispatch, useSelector } from "react-redux";
import { fetchAddresses, handlePagePagination } from "@store/user";
import { updateUserAddress, clearState } from "@store/checkout";

export const AuthenticatedAddress = () => {
  const jwToken = jwt();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [addressId, setAddressId] = useState(null);
  const pagePaginate = useSelector((state) => state.user.addresses.page);
  const { data, paginate, loading } = useSelector(
    (state) => state.user.addresses
  );

  const { loading: loadingUserAddress, success: successUserAddress } =
    useSelector((state) => state.checkout.userAddress);

  const onPagePaginate = (data) => {
    // setPage(data);
    dispatch(handlePagePagination({ page: data }));
    if (data > 1) {
      dispatch(fetchAddresses({ page: data }));
    } else {
      dispatch(fetchAddresses());
    }
  };

  const onSelectAddress = (id) => {
    setAddressId(id);
  };

  const onSubmitSelectAddressId = () => {
    dispatch(updateUserAddress({ userAddressId: addressId }));
  };

  useEffect(() => {
    if (jwToken) {
      dispatch(fetchAddresses());
    }
  }, [dispatch, jwToken]);

  useEffect(() => {
    if (successUserAddress) {
      dispatch(clearState());
      navigate(`${location.pathname}?tab=payment`);
    }
  }, [
    dispatch,
    successUserAddress,
    location.search,
    location.pathname,
    navigate,
  ]);

  return (
    <>
      {loading ? (
        <CircleSpinner size="lg" />
      ) : data?.length > 0 ? (
        <div className={`${classes.addressesGrid}`}>
          {data?.map((row, index) => {
            return (
              <div
                onClick={() => onSelectAddress(row.id)}
                className={`${classes.addressCard} ${
                  addressId === row.id ? classes.selectCardActive : ""
                } `}>
                <div className={`${classes.acIcon}`}>
                  <span className={`icon-home ${classes.icon}`}></span>
                </div>
                <div className={`${classes.acDetails}`}>
                  <div className={`${classes.detailsBody}`}>
                    <div className={`${classes.acTitle} lines-2`}>
                      {row?.title}
                    </div>
                    <div className={`${classes.acText} lines-1`}>
                      {row?.city?.name}
                    </div>
                    <div className={`${classes.acText} lines-1`}>
                      {row?.province?.name}
                    </div>
                    {row?.mobile ? (
                      <div className={`${classes.acPhone} lines-1 mt-1`}>
                        <b>
                          {row?.countryCode ?? ""} {row?.mobile ?? ""}
                        </b>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className={`${classes.selectAction} pt-2`}>
                    <button
                      className={`${classes.selectBtn} ${
                        addressId === row.id ? classes.selectBtnActive : ""
                      } w-100`}>
                      {addressId === row.id ? t("selected") : t("select")}
                      <span className={`icon-correct ${classes.icon}`}></span>
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className={`${classes.emptyAddresses}`}>
          {t("please-add-new-address-and-you-can-continue-checkout")}
          <NavLink
            className={`${classes.emptyAddressesLink}`}
            to="/account/addresses">
            {t("add-new-address")}
          </NavLink>
        </div>
      )}
      {paginate?.last_page > 1 ? (
        <Paginate
          page={pagePaginate}
          count={paginate?.last_page}
          onPage={onPagePaginate}
        />
      ) : (
        ""
      )}
      <div
        className={`checkout-actions d-flex justify-content-between align-items-center pt-3 pb-3`}>
        <div className={`checkout-back-btn`}>
          <NavLink to="/cart" className={`btn default-btn back-btn`}>
            <span className={`icon-chevron-left icon`}> </span>
            {t("return-to-cart")}
          </NavLink>
        </div>

        <div className={`checkout-submit-btn`}>
          <Button
            disabled={loadingUserAddress || addressId === null}
            onClick={onSubmitSelectAddressId}
            type="button"
            size="large"
            color="info"
            variant="contained"
            fullWidth>
            {loadingUserAddress ? (
              <>
                <CircularProgress size={17} style={{ color: "#fff" }} />
                {t("loading")}
              </>
            ) : (
              t("continue-to-payment")
            )}
          </Button>
        </div>
      </div>
    </>
  );
};
