import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import classes from "./Cart.module.css";
import {
  Counter,
  OrderCartCard,
  SliderProducts,
  CircleSpinner,
  EmptyProductsMsg,
  Seo,
} from "@components";
import { useSelector, useDispatch } from "react-redux";
import { fetchCart, deleteCartItem, clearState } from "@store/cart";
import { useTranslation } from "react-i18next";

/** Third Party **/
import { Config } from "@config/config";

export const Cart = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [currentId, setCurrentId] = useState(null);
  const { data, loading } = useSelector((state) => state.cart.cartData);
  const {
    data: dataDeleteCart,
    loading: loadingDeleteCart,
    success: successDeleteCart,
  } = useSelector((state) => state.cart.deleteCart);

  const handleCounter = (data) => {
    console.log(data);
  };

  const onDeleteCartItem = (id, shoppingCartId) => {
    setCurrentId(id);
    dispatch(deleteCartItem({ shoppingCartId: shoppingCartId }));
  };

  useEffect(() => {
    dispatch(fetchCart());
  }, [dispatch]);

  useEffect(() => {
    if (successDeleteCart) {
      dispatch(clearState());
      dispatch(fetchCart({ noLoading: true }));
    }
  }, [dispatch, successDeleteCart]);

  return (
    <>
      <Seo title={`${Config.name} | ${t("cart")}`} />
      <div className={classes.container}>
        <div className={`container-fluid`}>
          <div className={`title-container text-center`}>
            <div className={`title`}>{t("cart")}</div>
          </div>
        </div>

        <div className={`page-content pt-3`}>
          <div className="container">
            <div className={`${classes.itemsContainer}`}>
              {loading ? (
                <CircleSpinner size="lg" />
              ) : data?.rows?.length > 0 ? (
                <>
                  <div className={`${classes.table} main-table`}>
                    <table className="w-100">
                      <thead>
                        <tr>
                          <th>{t("product")}</th>
                          <th className="text-center">{t("quantity")}</th>
                          <th className="text-end">{t("total")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.rows?.map((row, index) => {
                          return (
                            <tr key={row.id}>
                              <td>
                                <OrderCartCard
                                  row={row}
                                  className={classes.cartCard}
                                />
                              </td>
                              <td>
                                <div className={`${classes.counter}`}>
                                  <Counter
                                    // availableQty={row?.availableQty}
                                    // qty={row?.qty}
                                    // onDataFromCounter={handleCounter}
                                    row={row}
                                  />
                                  <div
                                    className={`${classes.tableAction} mt-2`}>
                                    <button
                                      disabled={
                                        currentId === row.id &&
                                        loadingDeleteCart
                                      }
                                      onClick={() =>
                                        onDeleteCartItem(
                                          row.id,
                                          row.shoppingCartId
                                        )
                                      }
                                      className={`btn btn-normal`}>
                                      {currentId === row.id && loadingDeleteCart
                                        ? t("loading")
                                        : t("remove")}
                                    </button>
                                  </div>
                                </div>
                              </td>
                              <td>
                                {row?.totalPrice} {t("currency")}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className={`${classes.itemsCheckout}`}>
                    {/* <div className={classes.note}>
                      <div className={`text mb-2`}>Add Order Note</div>
                      <textarea
                        // onChange={(e) => setNote(e.target.value)}
                        // value={note}
                        className={`${classes.formControl} normal-input`}
                        rows={4}
                        placeholder="How can we help you?"
                      />
                    </div> */}
                    <div className={`${classes.checkout} text-end`}>
                      <div className={`mb-3`}>
                        <div className={`${classes.text} text`}>
                          {t("cart-discount")}{" "}
                          <span>
                            {data?.discount} {t("currency")}
                          </span>
                        </div>
                        <div className={`${classes.text} text`}>
                          {t("delivery")}{" "}
                          <span>
                            {data?.deliveryFees} {t("currency")}
                          </span>
                        </div>
                      </div>
                      <div className={`${classes.title} title`}>
                        {t("total")}: {data?.totaGrand} {t("currency")}
                      </div>
                      <div className={`${classes.checkoutAction} pt-3`}>
                        <Button
                          component={Link}
                          to="/checkout"
                          size="large"
                          // fullWidth
                          color="primary"
                          variant="contained">
                          {t("Cart.checkout")}
                        </Button>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <EmptyProductsMsg text={t("no-data-description")} />
              )}
            </div>
          </div>
        </div>
        {/* <SliderProducts viewAll={false} title={t("recently-viewed")} /> */}
      </div>
    </>
  );
};
