import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import classes from "./Slider.module.css";
import { Avatar } from "../../../components";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  EffectFade,
  Mousewheel,
  Lazy,
  Thumbs,
} from "swiper";
import { SliderPreview } from "./SliderPreview/SliderPreview";

export const Slider = ({
  gallery,
  mainImage,
  onCurrentImgFromChild,
  previewSlider,
  onClosePreviewSliderFromChild,
}) => {
  const swiper = useSwiper();
  const swiperRef = useRef(null);
  const playerRef = useRef(null);
  const [playing, setPlaying] = useState(0);
  const [thumbIsActive, setThumbIsActive] = useState(false);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [imgConfig, setImgConfig] = useState(null);

  const handleSlideChange = (e) => {
    setPlaying(e.activeIndex);
  };

  const closeSlider = () => {
    setThumbIsActive(!thumbIsActive);
    onClosePreviewSliderFromChild();
  };

  const onSetSlide = (index, url) => {
    setImgConfig({ index, url });
    swiperRef.current.swiper.slideTo(index);
    // setThumbIsActive(true);
    onCurrentImgFromChild(index, url);
  };

  return (
    <>
      {/* <button onClick={() => setPlaying(null)}>Stop</button> */}
      <Swiper
        ref={swiperRef}
        className={classes.swiperHeader}
        modules={[Navigation, Scrollbar, A11y, Thumbs, Pagination]}
        thumbs={{ swiper: thumbsSwiper }}
        // direction="horizontal"
        spaceBetween={4}
        slidesPerView={4}
        // mousewheel={true}
        pagination={{ el: ".view-slider-pagination", clickable: true }}
        loop={false}
        speed={1000}
        // onSlideChange={handleSlideChange}
        breakpoints={
          {
            // 350: {
            //   direction: "horizontal",
            // },
          }
        }>
        {mainImage ? (
          <SwiperSlide
            onClick={() => onSetSlide(0, mainImage)}
            className={classes.swiperSlide}>
            <Avatar className={classes.image} src={mainImage} />
          </SwiperSlide>
        ) : (
          ""
        )}

        {gallery?.map((row, index) => {
          const newId = index + 100;
          return (
            <SwiperSlide
              onClick={() => onSetSlide(index + 1, row?.media?.url)}
              className={classes.swiperSlide}>
              {row.media.type === "image" ? (
                <Avatar
                  // style={{
                  //   cursor: `url(${require("../../../assets/img/icons/plus.png")}), auto`,
                  // }}
                  className={classes.image}
                  src={row?.media?.url}
                />
              ) : (
                <>
                  <ReactPlayer
                    key={newId}
                    // ref={playerRef}
                    controls
                    className={classes.videoPlayer}
                    url={row.media.url}
                    playing={playing === index}
                    muted={false}
                  />
                </>
              )}
            </SwiperSlide>
          );
        })}
      </Swiper>

      <div className="view-slider-pagination"></div>

      <SliderPreview
        mainImage={mainImage}
        gallery={gallery}
        onClosePreviewFromParent={closeSlider}
        thumbIsActive={previewSlider}
        setThumbsSwiper={swiper}
        swiperRef={swiperRef}
      />
    </>
  );
};
