import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import classes from "./VideoCard.module.css";
import { Avatar } from "@components";
import { slug } from "@services";

export const VideoCard = ({ row, className }) => {
  const [key, setKey] = useState(null);
  const [keyName, setKeyName] = useState(null);
  const [keyId, setKeyId] = useState(null);
  const [keyEncryptId, setKeyEncryptId] = useState(null);

  useEffect(() => {
    if (row) {
      if (row?.brand) {
        setKey("b");
        setKeyId(row?.brand?.id);
        setKeyEncryptId(row?.brand?.encryptId);
        setKeyName(slug(row?.brand?.name));
      } else if (row?.sub2Category) {
        setKey("s2c");
        setKeyId(row?.sub2Category?.id);
        setKeyEncryptId(row?.sub2Category?.encryptId);
        setKeyName(slug(row?.sub2Category?.name));
      } else if (row?.subCategory) {
        setKey("sc");
        setKeyId(row?.subCategory?.id);
        setKeyEncryptId(row?.subCategory?.encryptId);
        setKeyName(slug(row?.subCategory?.name));
      } else if (row?.mainCategory) {
        setKey("c");
        setKeyId(row?.mainCategory?.id);
        setKeyEncryptId(row?.mainCategory?.encryptId);
        setKeyName(slug(row?.mainCategory?.name));
      }
    }
  }, [row]);

  return (
    <div data-aos="zoom-in-up">
      <NavLink
        className={`${classes.card}`}
        to={`/${key}/${keyName}/${keyEncryptId}?&cid=${keyId}&v=${row?.id}`}>
        <Avatar
          className={`${className} ${classes.image}`}
          src={row?.media?.url}
          alt={row?.title}
        />
        <div className={`${classes.overlay}`}>
          <div className={`${classes.overlayDetails}`}>
            <button className={`${classes.action} m-auto`}>
              <span className={`icon-play ${classes.icon}`}></span>
            </button>
            <div className={`${classes.detailsText}`}>
              <div className={`${classes.title} max-1-line`}>{row?.title}</div>
              <div className={`${classes.brand} max-1-line`}>
                {row?.brand?.name}
              </div>
            </div>
          </div>
        </div>
      </NavLink>
    </div>
  );
};
