import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import axiosInstance from "../interceptor/axiosInstance";

const initialState = {
  cartData: {
    data: null,
    loading: false,
    success: false,
  },
  addCart: {
    data: null,
    loading: false,
    success: false,
  },
  deleteCart: {
    data: null,
    loading: false,
    success: false,
  },
  updateCartQty: {
    data: null,
    loading: false,
    success: false,
  },
  userNote: {
    data: null,
    loading: false,
    success: false,
  },
};

// Fetch Cart
export const fetchCart = createAsyncThunk("fetchCart/cart", async (action) => {
  const config = {
    url: `shoppingCart`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return await axiosInstance(config)
    .then((response) => response)
    .catch((errors) => errors.response);
});

// Add to Cart
export const addCart = createAsyncThunk("addCart/cart", async (action) => {
  let size = {};
  let color = {};
  let attrs = [];

  if (action?.size) {
    size = {
      id: action?.sizeTypeId,
      selected: {
        id: action?.size,
      },
    };
    attrs = [...attrs, size];
  }

  if (action?.color) {
    color = {
      id: action?.colorTypeId,
      selected: {
        id: action?.color,
      },
    };
    attrs = [...attrs, color];
  }

  const config = {
    url: `shoppingCart`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: {
      productId: action?.productId,
      qty: action?.qty,
      attributes: attrs,
    },
  };
  return await axiosInstance(config)
    .then((response) => response)
    .catch((errors) => errors.response);
});

// Delete Cart Item
export const deleteCartItem = createAsyncThunk(
  "deleteCartItem/cart",
  async (action) => {
    const config = {
      url: `shoppingCart`,
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        shoppingCartId: action?.shoppingCartId,
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

// Update Cart Item Qty
export const updateCartItemQty = createAsyncThunk(
  "updateCartItemQty/cart",
  async (action) => {
    const config = {
      url: `shoppingCart`,
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        shoppingCartId: action.shoppingCartId,
        productId: action.productId,
        qty: action.qty,
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

// Add Cart Note
export const addCartNote = createAsyncThunk(
  "addCartNote/cart",
  async (action) => {
    const config = {
      url: `shoppingCart/checkout`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        userNote: action?.userNote,
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

const CartReducer = createSlice({
  name: "cart",
  initialState,
  reducers: {
    clearState: (state) => {
      state.cartData.success = false;
      state.addCart.success = false;
      state.deleteCart.success = false;
      state.updateCartQty.success = false;
    },
  },
  extraReducers: (builder) => {
    /********** Fetch Cart **********/
    builder.addCase(fetchCart.pending, (state, action) => {
      const noLoading = action?.meta?.arg?.noLoading;
      state.cartData.loading = noLoading ? false : true;
    });
    builder.addCase(fetchCart.fulfilled, (state, action) => {
      state.cartData.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        const data = payload?.data?.data;
        state.cartData.data = data;
        state.cartData.success = true;
      }
    });
    builder.addCase(fetchCart.rejected, (state, action) => {
      state.cartData.loading = false;
    });
    /********** Fetch Cart **********/

    /********** Add Cart **********/
    builder.addCase(addCart.pending, (state, action) => {
      state.addCart.loading = true;
    });
    builder.addCase(addCart.fulfilled, (state, action) => {
      state.addCart.loading = false;
      const payload = action.payload;
      if (payload?.status === 200 || payload?.status === 201) {
        const data = payload?.data?.data?.message;
        toast.success(data);
        state.addCart.data = data;
        state.addCart.success = true;
      }
    });
    builder.addCase(addCart.rejected, (state, action) => {
      state.addCart.loading = false;
    });
    /********** Add Cart **********/

    /********** Delete Cart Item **********/
    builder.addCase(deleteCartItem.pending, (state, action) => {
      state.deleteCart.loading = true;
    });
    builder.addCase(deleteCartItem.fulfilled, (state, action) => {
      state.deleteCart.loading = false;
      const payload = action.payload;
      if (payload?.status === 200 || payload?.status === 201) {
        // const data = payload?.data?.data?.message;
        // toast.success(data);
        state.deleteCart.success = true;
      }
    });
    builder.addCase(deleteCartItem.rejected, (state, action) => {
      state.deleteCart.loading = false;
    });
    /********** Delete Cart Item **********/

    /********** Update Cart Item Qty **********/
    builder.addCase(updateCartItemQty.pending, (state, action) => {
      state.updateCartQty.loading = true;
    });
    builder.addCase(updateCartItemQty.fulfilled, (state, action) => {
      state.updateCartQty.loading = false;
      const payload = action.payload;
      if (payload?.status === 200 || payload?.status === 201) {
        const data = payload?.data?.data?.message;
        toast.success(data);
        state.updateCartQty.success = true;
      }
    });
    builder.addCase(updateCartItemQty.rejected, (state, action) => {
      state.updateCartQty.loading = false;
    });
    /********** Update Cart Item Qty **********/

    /********** Add User Note **********/
    builder.addCase(addCartNote.pending, (state, action) => {
      state.userNote.loading = true;
    });
    builder.addCase(addCartNote.fulfilled, (state, action) => {
      state.userNote.loading = false;
      const payload = action.payload;
      if (payload?.status === 200 || payload?.status === 201) {
        const data = payload?.data?.data?.message;
        toast.success(data);
        state.userNote.success = true;
      }
    });
    builder.addCase(addCartNote.rejected, (state, action) => {
      state.userNote.loading = false;
    });
    /********** Add User Note **********/
  },
});

export const { clearState } = CartReducer.actions;

export default CartReducer.reducer;
