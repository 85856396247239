import React, { useState, useEffect } from "react";
import classes from "../Auth.module.css";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Helmet } from "react-helmet";
import { jwt } from "@services";
import CircularProgress from "@mui/material/CircularProgress";
import { NavLink, useNavigate, Navigate, useLocation } from "react-router-dom";
import OtpInput from "react-otp-input";
import { Seo } from "@components";

/** Redux **/
import { createOtp, verifyEmail, clearState } from "@store/auth/auth";
import { useDispatch, useSelector } from "react-redux";

/** Third Party **/
import { Config } from "@config/config";

export const Verify = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const stateData = location?.state;
  const [otp, setOtp] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [mainEmail, setMainEmail] = useState(null);
  const { data, loading, success } = useSelector((state) => state.auth.sendOtp);
  const { loading: loadingVerified, success: successVerified } = useSelector(
    (state) => state.auth.verified
  );

  const schema = yup
    .object({
      email: yup
        .string()
        .required(t("Validations.email-address-required"))
        .email(t("Validations.email-must-be-a-valid-email"))
        .min(6, t("Validations.email-too-short")),
    })
    .required();

  const defaultValues = {
    email: stateData?.registerdEmail ? stateData?.registerdEmail : "",
  };

  const {
    setError,
    clearErrors,
    reset,
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = async (data) => {
    dispatch(createOtp({ email: data?.email }));
    setMainEmail(data?.email);
  };

  useEffect(() => {
    if (success) {
      setEmailValid(true);
    } else {
      setEmailValid(false);
    }
  }, [dispatch, success]);

  useEffect(() => {
    if (!mainEmail) {
      setEmailValid(false);
    }
  }, [mainEmail]);

  const submitOtpCode = () => {
    dispatch(
      verifyEmail({
        otp,
        email: stateData?.registerdEmail
          ? stateData?.registerdEmail
          : mainEmail,
      })
    );
  };

  useEffect(() => {
    if (successVerified) {
      setEmailValid(false);
      dispatch(clearState());
      navigate("/auth/login");
    }
  }, [dispatch, successVerified, navigate]);

  return (
    <>
      <Seo title={`${Config.name} | ${t("verify")}`} />

      <div className={classes.container}>
        <div className={`container-fluid text-center`}>
          <div className={classes.block}>
            {stateData?.registerdEmail != null || emailValid ? (
              <>
                <div className={`title`}>{t("code-verification")}</div>
                <div className={`sub-title mt-3 mb-4`}>
                  {t("enter-the-code-weve-sent-to")}{" "}
                  {stateData?.registerdEmail
                    ? stateData?.registerdEmail?.substring(0, 4)
                    : mainEmail?.substring(0, 4)}
                  *********
                </div>
                <div className={`${classes.otpInput} mb-3`}>
                  <OtpInput
                    focus
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    // renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                  />
                </div>

                <Button
                  onClick={submitOtpCode}
                  disabled={loadingVerified}
                  type="button"
                  size="large"
                  fullWidth
                  color="primary"
                  variant="contained">
                  {loadingVerified ? (
                    <>
                      <CircularProgress size={17} style={{ color: "#fff" }} />
                      {t("loading")}
                    </>
                  ) : (
                    t("verify")
                  )}
                </Button>
              </>
            ) : !emailValid ? (
              <>
                <div className={`title`}>{t("verify-your-email")}</div>
                <form className="mt-2" onSubmit={handleSubmit(onSubmit)}>
                  <div className={`input-box`}>
                    <Controller
                      control={control}
                      {...register("email")}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          helperText={
                            errors?.email ? errors?.email.message : null
                          }
                          size="small"
                          error={!!errors?.email}
                          onChange={onChange}
                          value={value}
                          fullWidth
                          label={t("email")}
                          variant="outlined"
                        />
                      )}
                    />
                  </div>

                  <Button
                    disabled={loading}
                    type="submit"
                    size="large"
                    fullWidth
                    color="primary"
                    variant="contained">
                    {loading ? (
                      <>
                        <CircularProgress size={17} style={{ color: "#fff" }} />
                        {t("loading")}
                      </>
                    ) : (
                      t("verify")
                    )}
                  </Button>
                </form>
              </>
            ) : (
              ""
            )}
            {/* {!emailValid ? (
              <>
                {defaultValues?.email}
                <div className={`title`}>{t("verify-your-email")}</div>
                <form className="mt-2" onSubmit={handleSubmit(onSubmit)}>
                  <div className={`input-box`}>
                    <Controller
                      control={control}
                      {...register("email")}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          helperText={
                            errors?.email ? errors?.email.message : null
                          }
                          size="small"
                          error={!!errors?.email}
                          onChange={onChange}
                          value={value}
                          fullWidth
                          label={t("email")}
                          variant="outlined"
                        />
                      )}
                    />
                  </div>

                  <Button
                    disabled={loading}
                    type="submit"
                    size="large"
                    fullWidth
                    color="primary"
                    variant="contained">
                    {loading ? (
                      <>
                        <CircularProgress size={17} style={{ color: "#fff" }} />
                        {t("loading")}
                      </>
                    ) : (
                      t("verify")
                    )}
                  </Button>
                </form>
              </>
            ) : (
              <>
                <div className={`title`}>{t("code-verification")}</div>
                <div className={`sub-title mt-3 mb-4`}>
                  {t("enter-the-code-weve-sent-to")}{" "}
                  {mainEmail?.substring(0, 4)}
                  *********
                </div>
                <div className={`${classes.otpInput} mb-3`}>
                  <OtpInput
                    focus
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    // renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                  />
                </div>

                <Button
                  onClick={submitOtpCode}
                  disabled={loadingVerified}
                  type="button"
                  size="large"
                  fullWidth
                  color="primary"
                  variant="contained">
                  {loadingVerified ? (
                    <>
                      <CircularProgress size={17} style={{ color: "#fff" }} />
                      {t("loading")}
                    </>
                  ) : (
                    t("verify")
                  )}
                </Button>
              </>
            )} */}

            <div className={classes.accountCheck}>
              {t("dont-have-an-account")}
              <NavLink className={classes.accountCheckLink} to="/auth/register">
                {t("create-one")}
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
