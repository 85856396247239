import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import axiosInstance from "../interceptor/axiosInstance";
import { CastConnectedOutlined } from "@mui/icons-material";

const initialState = {
  brands: {
    data: null,
    paginate: null,
    pagePaginate: 1,
    loading: false,
    success: false,
  },
};

// Fetch Brands
export const fetchBrands = createAsyncThunk(
  "fetchBrands/brands",
  async (action) => {
    // let url = "";
    let page = {
      page: action?.page,
    };
    let query = {
      page: action?.page,
      search: action?.search,
    };

    // url = `brands?paginate=30&${query}`;
    // page = {
    //   page: action?.page,
    // };

    // if (action?.page && action?.page != 1) {
    //   url = `brands?paginate=30&page=${action.page}`;
    //   page = {
    //     page: action?.page,
    //   };

    //   if (action?.search && action?.search !== (null || "")) {
    //     url = `brands?paginate=30&page=${action.page}&search=${action.search}`;
    //     page = {
    //       page: action?.page,
    //     };
    //   }
    // }

    // if (action?.search && action?.search !== (null || "")) {
    //   url = `brands?paginate=30&search=${action.search}`;
    // }

    const config = {
      url: `brands?paginate=30`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: page,
      params: query,
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

const brandsReducer = createSlice({
  name: "brands",
  initialState,
  reducers: {
    handlePagePagination: (state, action) => {
      state.brands.pagePaginate = action.payload.page;
    },
  },
  extraReducers: (builder) => {
    /********** Fetch Brands **********/
    builder.addCase(fetchBrands.pending, (state, action) => {
      state.brands.loading = true;
      state.brands.data = null;
    });
    builder.addCase(fetchBrands.fulfilled, (state, action) => {
      state.brands.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        state.brands.data = payload?.data?.data?.rows;
        state.brands.paginate = payload?.data?.data?.paginate;
        state.brands.success = true;
        state.brands.pagePaginate = payload?.data?.data?.paginate?.current_page;
        // if (action?.meta?.arg?.search) {
        //   state.brands.pagePaginate = 1;
        // }
        // const pageIfFound = action?.meta?.arg?.page;
        // if (pageIfFound) {
        // } else {
        //   state.brands.pagePaginate = 1;
        // }
      }
    });
    builder.addCase(fetchBrands.rejected, (state, action) => {
      state.brands.loading = false;
      state.brands.data = null;
    });
    /********** Fetch Brands **********/
  },
});

export const { handlePagePagination } = brandsReducer.actions;

export default brandsReducer.reducer;
