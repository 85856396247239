import React, { useState } from "react";
import classes from "./OrderedAlert.module.css";
import { useTranslation } from "react-i18next";

export const OrderedAlert = ({ row }) => {
  const { t } = useTranslation();
  return (
    <div className={`${classes.container}`}>
      <div className={`${classes.block}`}>
        <div className={`${classes.view}`}>
          <span className={`icon-ordered ${classes.icon}`}></span>
        </div>
        <div className={`${classes.details}`}>
          <div className={`${classes.title}`}>{t("order-delivered")}</div>
          <div className={`${classes.text}`}>
            {t("your-order-has-been-successfully-delivered-description", {
              title: row?.name,
            })}
            {/* <br /> Don’t forget to give us your feedback here:
            http://bit.ly/p1we1 */}
          </div>
        </div>
      </div>
    </div>
  );
};
