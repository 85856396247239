import React, { useState, useEffect } from "react";
import classes from "./PrivacyPolicy.module.css";

/** Third Party **/
import { Helmet } from "react-helmet";
import { Config } from "@config";
import { fetchPages } from "@store/general";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { CircleSpinner, Seo } from "@components";

export const PrivacyPolicy = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [content, setContent] = useState(null);
  const { data, loading } = useSelector((state) => state.general.pages);

  useEffect(() => {
    dispatch(fetchPages());
  }, [dispatch]);

  useEffect(() => {
    if (data) {
      const contentData = data?.find((row) => row?.slug === "privacypolicy");
      setContent(contentData);
    }
  }, [data, setContent]);

  return (
    <>
      <Seo
        title={`${Config.name} | ${
          content ? content[i18n?.language]?.title : ""
        }`}
      />
      <div className={`${classes.container}`}>
        <div className={`container`}>
          <div className={`${classes.block}`}>
            {loading ? (
              <CircleSpinner className={"pt-5 pb-5"} size="lg" />
            ) : (
              <>
                <div className={`title-container text-center`}>
                  <div className={`title`}>
                    {content ? content[i18n?.language]?.title : ""}
                  </div>
                </div>

                <div className={`page-content pt-0`}>
                  {/* Details */}
                  <div className={`${classes.details}`}>
                    <div className={`${classes.text} text mb-2`}>
                      {content ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: content[i18n?.language]?.body,
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
