import React, { useState } from "react";
import classes from "./EmptyProductsMsg.module.css";

export const EmptyProductsMsg = ({ text, className, fontSize }) => {
  return (
    <div
      style={{ fontSize: `${fontSize}px` }}
      className={`text ${classes.text} ${className}`}>
      {text}
    </div>
  );
};
