import React, { useState, useEffect } from "react";
import classes from "../Auth.module.css";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Helmet } from "react-helmet";
import { jwt } from "@services";
import CircularProgress from "@mui/material/CircularProgress";
import { NavLink, useNavigate, Navigate, useLocation } from "react-router-dom";
import OtpInput from "react-otp-input";

/** Redux **/
import { resetPassword, clearState } from "@store/auth/auth";
import { useDispatch, useSelector } from "react-redux";

/** Third Party **/
import { Config } from "@config/config";

export const ResetPassword = ({ mainEmail }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const stateData = location?.state;
  const [otp, setOtp] = useState("");
  const { data, loading, success } = useSelector(
    (state) => state.auth.resetPassword
  );

  const schema = yup
    .object({
      password: yup
        .string()
        .required(t("Validations.password-required"))
        .min(3, t("Validations.password-too-short")),
      confirmationPassword: yup
        .string()
        .required(t("Validations.confirm-password-required"))
        .oneOf(
          [yup.ref("password")],
          t("Validations.passwords-does-not-match")
        ),
    })
    .required();

  const defaultValues = {
    email: "",
    newPassword: "",
    newPasswordConfirmation: "",
  };

  const {
    setError,
    clearErrors,
    reset,
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = async (data) => {
    dispatch(
      resetPassword({
        email: mainEmail,
        validationCode: otp,
        password: data?.password,
        confirmationPassword: data?.confirmationPassword,
      })
    );
  };

  useEffect(() => {
    if (success) {
      dispatch(clearState());
      navigate("/auth/login");
    }
  }, [dispatch, success, navigate]);

  return (
    <>
      <form className="mt-2" onSubmit={handleSubmit(onSubmit)}>
        <div className={`${classes.otpInput} mb-3`}>
          <OtpInput
            focus
            value={otp}
            onChange={setOtp}
            numInputs={4}
            // renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
          />
        </div>

        <div className={`input-box`}>
          <Controller
            control={control}
            {...register("password")}
            render={({ field: { onChange, value } }) => (
              <div>
                <TextField
                  helperText={
                    errors?.password ? errors?.password.message : null
                  }
                  type="password"
                  size="small"
                  error={!!errors?.password}
                  onChange={onChange}
                  value={value}
                  fullWidth
                  label={t("password")}
                  variant="outlined"
                />
              </div>
            )}
          />
        </div>

        <div className={`input-box`}>
          <Controller
            control={control}
            {...register("confirmationPassword")}
            render={({ field: { onChange, value } }) => (
              <div>
                <TextField
                  helperText={
                    errors?.confirmationPassword
                      ? errors?.confirmationPassword.message
                      : null
                  }
                  type="password"
                  size="small"
                  error={!!errors?.confirmationPassword}
                  onChange={onChange}
                  value={value}
                  fullWidth
                  label={t("confirm-password")}
                  variant="outlined"
                />
              </div>
            )}
          />
        </div>

        <Button
          disabled={loading}
          type="submit"
          size="large"
          fullWidth
          color="primary"
          variant="contained">
          {loading ? (
            <>
              <CircularProgress size={17} style={{ color: "#fff" }} />
              {t("loading")}
            </>
          ) : (
            t("verify")
          )}
        </Button>
      </form>
    </>
  );
};
