import React, { useState } from "react";
import classes from "./RefundPolicy.module.css";

/** Third Party **/
import { Helmet } from "react-helmet";
import { Config } from "../../config/config";

const RefundPolicy = () => {
  return (
    <>
      <Helmet>
        <title>{Config.name} | Refund Policy</title>
      </Helmet>
      <div className={`${classes.container}`}>
        <div className={`container`}>
          <div className={`${classes.block} container-sm`}>
            <div className={`title-container text-center`}>
              <div className={`title`}>Refund Policy</div>
            </div>

            <div className={`page-content pt-0`}>
              {/* Details */}
              <div className={`${classes.details}`}>
                <div className={`${classes.text} text mb-2`}>
                  <div class="rte">
                    <h4>
                      <strong>What is your return policy?</strong>
                    </h4>
                    <ul>
                      <li>
                        After receiving the order and within 14 days you can
                        submit a return request online. Items must be returned
                        unworn, undamaged and unused, with all tags attached and
                        the original packaging included.
                      </li>
                      <li>
                        Footwear and accessories must be returned with the
                        original branded boxes and dust bags, where provided,
                        and placed inside a protective outer box for shipping.
                      </li>
                      <li class="md-list-item">
                        <p class="md-paragraph">
                          <span>
                            Beauty and cosmetic&nbsp;cannot be returned for
                            hygiene purposes.&nbsp;
                          </span>
                        </p>
                      </li>
                    </ul>
                    <h4>Do I have to pay return fees?</h4>
                    <p>
                      <span>
                        Yes, Courier Charges are applied for returning your
                        order and arranged within 48 hours.
                      </span>
                    </p>
                    <h4>
                      <strong>How do I return an item ?</strong>
                    </h4>
                    <p>1. Click in Return Form in the below of homepage.</p>
                    <p>2. Enter your order details.</p>
                    <p>
                      3. Select the items you would like to refund or exchange.
                    </p>
                    <p>
                      4. In case you are in Kuwait, will send driver to pick it
                      up with 2 working days. And for international you
                      will&nbsp;receive DHL or Fedex slip please attach to the
                      package and courier will pick it up.
                    </p>
                    <p>
                      5. We will receive the items and will do our quality check
                      to process for refund or exchange process.
                    </p>
                    <p>
                      You can always contact us for any return question at&nbsp;
                      <a href="mailto:customerservice@wardrobefashion.com">
                        customerservice@wardrobefashion.com
                      </a>
                      .
                    </p>
                    <h4>How will I be refunded?</h4>
                    <p>
                      If the order was paid online, we can easily return the
                      amount back to your bank account. But if the order was
                      paid using the cash on delivery service we can only return
                      the amount back through an online voucher that can be used
                      on your next purchase online.&nbsp;The Return shipping
                      charges will be deducted from the Return amount.
                    </p>
                    <h4>
                      <strong>How long does it take to get my refund?</strong>
                    </h4>
                    <p>&nbsp;</p>
                    <p>
                      <span>
                        We will notify you once we’ve received and inspected
                        your return, and let you know if the refund was approved
                        or not. If approved, you’ll be automatically refunded on
                        your original payment method. Please remember it can
                        take some time for your bank or credit card company to
                        process and post the refund too which up to 14
                        days.&nbsp;
                      </span>
                    </p>
                    <p>
                      <br />
                      <strong>Damages and issues</strong>
                      <br />
                    </p>

                    <p>
                      <span>
                        This is a very rare occurrence but sadly not impossible.
                        We have been working hard on our quality control to
                        ensure that everything that leaves our warehouse is
                        fully what the customer has ordered and with no damages
                        at all. But if in case you’re faced with this incident
                        please do contact us by any form (email, call, live
                        chat) and we’ll directly take care of it.
                      </span>
                    </p>
                    <br />
                    <p>
                      <strong>Exceptions / non-returnable items</strong>
                    </p>

                    <p>
                      <span>
                        Perfumes and cosmetics cannot be returned under any
                        circumstance for hygiene reasons.
                      </span>
                    </p>
                    <p>Unfortunately, we cannot accept returns gift cards.</p>
                    <br />
                    <p>
                      <strong>Exchanges</strong> <br />
                      The fastest way to ensure you get what you want is to
                      return the item you have, and once the return is accepted,
                      make a separate purchase for the new item.
                    </p>
                    <h4>
                      <strong></strong>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { RefundPolicy };
