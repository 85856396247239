import * as React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classes from "./ResponsiveNavbar.module.css";
import { useSelector } from "react-redux";

export const ResponsiveNavbar = ({ className }) => {
  const { t, i18n } = useTranslation();
  const { data: userData, loading } = useSelector(
    (state) => state.auth.userData
  );

  return (
    <div className={`${classes.responsiveNavbar} ${className}`}>
      <div className={`${classes.responsiveNavbarBlock}`}>
        <NavLink className={`${classes.resRow}`} to="/">
          <span className={`icon-home icon ${classes.icon}`}></span>{" "}
          <span className={`${classes.title}`}>{t("home")}</span>
        </NavLink>

        <NavLink className={`${classes.resRow}`} to="/categories">
          <span className={`icon-search1 icon ${classes.icon}`}></span>{" "}
          <span className={`${classes.title}`}>{t("categories")}</span>
        </NavLink>

        <NavLink className={`${classes.resRow}`} to="/discounts">
          <span className={`icon-price-tag icon ${classes.icon}`}></span>{" "}
          <span className={`${classes.title}`}>{t("discounts")}</span>
        </NavLink>

        <NavLink className={`${classes.resRow}`} to="/brands">
          <span className={`icon-hanger icon ${classes.icon}`}></span>{" "}
          <span className={`${classes.title}`}>{t("brands")}</span>
        </NavLink>

        <NavLink
          className={`${classes.resRow}`}
          to={userData ? "/account" : "/auth/login"}>
          <span className={`icon-user-2 icon ${classes.icon}`}></span>{" "}
          <span className={`${classes.title}`}>
            {t(userData ? "my-account" : "login")}
          </span>
        </NavLink>
      </div>
    </div>
  );
};
