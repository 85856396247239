import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { MuiTheme, Config } from "./config";
import "bootstrap/dist/css/bootstrap.min.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "react-phone-input-2/lib/style.css";
import "./index.css";
import "./rtl.css";
import "./i18n/i18n";
import { Toaster } from "react-hot-toast";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import i18next from "i18next";
import { CssBaseline } from "@mui/material";
import { t } from "i18next";
import { HelmetProvider } from "react-helmet-async";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

// You can also pass an optional settings object
// below listed default settings
AOS.init({
  // Global settings:
  disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
  initClassName: "aos-init", // class applied after initialization
  animatedClassName: "aos-animate", // class applied on animation
  useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
  disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 120, // offset (in px) from the original trigger point
  delay: 0, // values from 0 to 3000, with step 50ms
  duration: 800, // values from 0 to 3000, with step 50ms
  easing: "ease", // default easing for AOS animations
  once: true, // whether animation should happen only once - while scrolling down
  mirror: false, // whether elements should animate out while scrolling past them
  anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
});

const root = ReactDOM.createRoot(document.getElementById("root"));

const cacheRtl = createCache({
  key: "mui-style-rtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const cacheLtr = createCache({
  key: "mui-style-ltr",
});

const direction = i18next.language === "ar" ? "rtl" : "ltr";
// i18next.language

let updatedTheme = { ...MuiTheme };
updatedTheme.typography.allVariants.fontFamily =
  i18next.language === "ar" ? "Madani, sans-serif" : "Montserrat, sans-serif";

root.render(
  <CacheProvider value={i18next.language === "en" ? cacheLtr : cacheRtl}>
    <ThemeProvider theme={updatedTheme}>
      <CssBaseline />
      <Provider store={store}>
        <BrowserRouter
          basename={
            process.env.NODE_ENV === "production" ? process.env.PUBLIC_URL : ""
          }>
          <Suspense fallback={t("loading")}>
            <HelmetProvider>
              <App />
            </HelmetProvider>
            <Toaster
              position={Config.layout.toastPosition}
              toastOptions={{ className: "react-hot-toast" }}
            />
          </Suspense>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  </CacheProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
