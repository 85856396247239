import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import axiosInstance from "../interceptor/axiosInstance";

const initialState = {
  paymentOptions: {
    data: null,
    loading: false,
    success: false,
  },
  userAddress: {
    data: null,
    loading: false,
    success: false,
  },
  checkout: {
    data: null,
    loading: false,
    success: false,
  },
  coupon: {
    data: null,
    loading: false,
    success: false,
  },
  removeCoupon: {
    data: null,
    loading: false,
    success: false,
  },
};

// Fetch Payment Options
export const fetchPaymentOptions = createAsyncThunk(
  "fetchPaymentOptions/checkout",
  async (action) => {
    const config = {
      url: `appSettings`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

// Update User Address
export const updateUserAddress = createAsyncThunk(
  "updateUserAddress/checkout",
  async (action) => {
    const config = {
      url: `shoppingCart/selectUserAddress`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        userAddressId: action?.userAddressId,
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

// Create Checkout
export const createCheckout = createAsyncThunk(
  "createCheckout/checkout",
  async (action) => {
    console.log(action);
    const config = {
      url: `shoppingCart/checkout`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        userNote: action?.note,
        paymentMethod: action?.paymentMethod,
        mobile: action?.guestAddress?.phone,
        province_id: action?.guestAddress?.province,
        city_id: action?.guestAddress?.city,
        address: action?.guestAddress?.address,
        email: action?.guestAddress?.email,
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

// Create Coupon
export const createCoupon = createAsyncThunk(
  "createCoupon/checkout",
  async (action) => {
    console.log(action);
    const config = {
      url: `shoppingCart/applyCoupon`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        couponCode: action?.couponCode,
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

// Remove Coupon
export const removeCoupon = createAsyncThunk(
  "removeCoupon/checkout",
  async (action) => {
    console.log(action);
    const config = {
      url: `shoppingCart/removeCoupon`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        couponCode: action?.couponCode,
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

const checkoutReducer = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    clearState: (state) => {
      state.paymentOptions.success = false;
      state.userAddress.success = false;
      state.checkout.success = false;
      state.coupon.success = false;
      state.removeCoupon.success = false;
    },
  },
  extraReducers: (builder) => {
    /********** Fetch Checkout Options **********/
    builder.addCase(fetchPaymentOptions.pending, (state, action) => {
      state.paymentOptions.loading = true;
    });
    builder.addCase(fetchPaymentOptions.fulfilled, (state, action) => {
      state.paymentOptions.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        const data = payload?.data?.data?.paymentMethods;
        if (data) {
          state.paymentOptions.data = data;
          state.paymentOptions.success = true;
        }
      }
    });
    builder.addCase(fetchPaymentOptions.rejected, (state, action) => {
      state.paymentOptions.loading = false;
    });
    /********** Fetch Checkout Options **********/

    /********** Update User Address **********/
    builder.addCase(updateUserAddress.pending, (state, action) => {
      state.userAddress.loading = true;
    });
    builder.addCase(updateUserAddress.fulfilled, (state, action) => {
      state.userAddress.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        const data = payload?.data?.data?.message;
        // toast.success(data);
        // state.userAddress.data = data;
        state.userAddress.success = true;
      }
    });
    builder.addCase(updateUserAddress.rejected, (state, action) => {
      state.userAddress.loading = false;
    });
    /********** Update User Address **********/

    /********** Create Checkout **********/
    builder.addCase(createCheckout.pending, (state, action) => {
      state.checkout.loading = true;
      state.checkout.success = false;
    });
    builder.addCase(createCheckout.fulfilled, (state, action) => {
      state.checkout.loading = false;
      const payload = action.payload;
      if (payload?.status === 200 || payload?.status === 201) {
        const data = payload?.data;
        const paymentURL = data?.data?.paymentURL;
        const isCashMethod = action?.meta?.arg?.paymentMethod === "cash";

        if (data && paymentURL) {
          window.location.href = paymentURL;
        }

        if (isCashMethod) {
          state.checkout.success = true;
        }
      }
    });
    builder.addCase(createCheckout.rejected, (state, action) => {
      state.checkout.loading = false;
      state.checkout.success = false;
    });
    /********** Create Checkout **********/

    /********** Create Coupon **********/
    builder.addCase(createCoupon.pending, (state, action) => {
      state.coupon.loading = true;
    });
    builder.addCase(createCoupon.fulfilled, (state, action) => {
      state.coupon.loading = false;
      const payload = action.payload;
      if (payload?.status === 200 || payload?.status === 201) {
        const data = payload?.data?.data?.message;
        // toast.success(data);
        state.coupon.success = true;
      }
    });
    builder.addCase(createCoupon.rejected, (state, action) => {
      state.coupon.loading = false;
    });
    /********** Create Coupon **********/

    /********** Create Coupon **********/
    builder.addCase(removeCoupon.pending, (state, action) => {
      state.removeCoupon.loading = true;
    });
    builder.addCase(removeCoupon.fulfilled, (state, action) => {
      state.removeCoupon.loading = false;
      const payload = action.payload;
      if (payload?.status === 200 || payload?.status === 201) {
        const data = payload?.data?.data?.message;
        // toast.success(data);
        state.removeCoupon.success = true;
      }
    });
    builder.addCase(removeCoupon.rejected, (state, action) => {
      state.removeCoupon.loading = false;
    });
    /********** Create Coupon **********/
  },
});

export const { clearState } = checkoutReducer.actions;

export default checkoutReducer.reducer;
