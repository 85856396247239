import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import classes from "./Cart.module.css";
import { CartCard } from "./cartCard/CartCard";
import { Note } from "./note/Note";
import { CircleSpinner, EmptyProductsMsg } from "@components";
import { useSelector, useDispatch } from "react-redux";
import { fetchCart } from "@store/cart";
import { useTranslation } from "react-i18next";

export const Cart = (props) => {
  const { t, i18n } = useTranslation();
  const [noteIsActive, setNoteIsActive] = useState(false);
  const [noteText, setNoteText] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.cart.cartData);

  const closeDrawer = () => {
    props.onDataFromCart(false);
  };

  const onCheckoutHandle = (route) => {
    navigate(`/${route}`);
    props.onDataFromCart(false);
  };

  const handleNote = (isActive) => {
    setNoteIsActive(isActive);
  };

  useEffect(() => {
    dispatch(fetchCart());
  }, [dispatch]);

  return (
    <div>
      <React.Fragment key={i18n.language === "en" ? "right" : "left"}>
        <Drawer
          anchor={i18n.language === "en" ? "right" : "left"}
          open={props.cartIsActive}
          onClose={closeDrawer}>
          <div className={`${classes.content} drawer-content`}>
            <div
              className={`${classes.header} drawer-header d-flex justify-content-between`}>
              <div
                onClick={() => onCheckoutHandle("cart")}
                className={`${classes.title} title`}>
                {t("cart")}
              </div>
              <button
                className={`${classes.sortExit} btn-empty`}
                onClick={closeDrawer}>
                <span
                  className={`icon-cancel ${classes.icon} drawer-close-btn`}></span>
              </button>
            </div>

            {/* Body */}
            <div className={`${classes.body} drawer-body scroller`}>
              {loading ? (
                <CircleSpinner size="lg" />
              ) : data?.rows?.length > 0 ? (
                data?.rows?.map((row, index) => {
                  return (
                    <CartCard
                      key={row.id}
                      row={row}
                      className={classes.cartCard}
                    />
                  );
                })
              ) : (
                <EmptyProductsMsg text={t("no-data-description")} />
              )}
            </div>
            {/* Body */}

            <div className={`${classes.footer} drawer-footer`}>
              {data?.rows?.length != 0 ? (
                <div className="pb-3">
                  {/* <div
                    className={`${classes.noteTitle} title`}
                    onClick={() => setNoteIsActive(!noteIsActive)}>
                    {noteText ? "Edit" : "Add"} Order Note
                  </div> */}
                  <div
                    className={`${classes.noteText} d-flex align-items-center justify-content-between text`}>
                    {t("cart-discount")}
                    <span>
                      {data?.discount} {t("currency")}
                    </span>
                  </div>
                  <div
                    className={`${classes.noteText} d-flex align-items-center justify-content-between text`}>
                    {t("delivery")}
                    <span>
                      {data?.deliveryFees} {t("currency")}
                    </span>
                  </div>
                  {/* <Note
                    onDataFromNote={handleNote}
                    noteText={noteText}
                    noteIsActive={noteIsActive}
                  /> */}
                </div>
              ) : (
                ""
              )}
              <Button
                disabled={data?.totalQtyInItems === 0}
                onClick={() => onCheckoutHandle("checkout")}
                fullWidth
                size="large"
                color="primary"
                variant="contained">
                {t("Cart.checkout")}
                {data?.totalQtyInItems != 0
                  ? `. ${data?.totaGrand} ${t("currency")}`
                  : ""}
              </Button>
            </div>
          </div>
        </Drawer>
      </React.Fragment>
    </div>
  );
};
