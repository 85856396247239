import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import classes from "./Coupon.module.css";
import { Avatar, CartAttributes } from "@components";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useSelector, useDispatch } from "react-redux";
import { createCoupon, removeCoupon } from "@store/checkout";
import { fetchCart } from "@store/cart";

export const Coupon = ({ data }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [couponCode, setCouponCode] = useState(
    data?.applyCoupon ? data?.couponCode : null
  );
  const { loading: couponLoading, success: couponSuccess } = useSelector(
    (state) => state.checkout.coupon
  );
  const { loading: removeCouponLoading, success: removeCouponSuccess } =
    useSelector((state) => state.checkout.removeCoupon);

  const onCouponCode = (e) => {
    setCouponCode(e.target.value);
  };

  const onSubmitCouponCode = () => {
    dispatch(createCoupon({ couponCode: couponCode }))
      .then((res) => {
        const payload = res.payload;
        if (payload?.status === 200 || payload?.status === 201) {
          dispatch(fetchCart({ noLoading: true }));
        }
      })
      .catch((err) => {
        // fetchCart({noLoading: true})
      });
  };

  const onRemoveCoupon = () => {
    dispatch(removeCoupon({ couponCode: data?.couponCode }))
      .then((res) => {
        const payload = res.payload;
        if (payload?.status === 200 || payload?.status === 201) {
          dispatch(fetchCart({ noLoading: true }));
        }
      })
      .catch((err) => {
        // fetchCart({noLoading: true})
      });
  };

  return (
    <>
      <div className={`${classes.coupon}`}>
        <div className={`d-flex`}>
          <TextField
            disabled={data?.applyCoupon}
            size="small"
            onChange={(e) => onCouponCode(e)}
            value={couponCode}
            fullWidth
            label={t("discount-code")}
            className="pr-2"
          />

          <Button
            disabled={couponCode === "" || couponLoading || data?.applyCoupon}
            onClick={onSubmitCouponCode}
            type="submit"
            size="large"
            color="primary"
            variant="contained">
            {couponLoading ? (
              <>
                <CircularProgress size={17} style={{ color: "#fff" }} />
                {t("loading")}
              </>
            ) : (
              t("apply")
            )}
          </Button>
        </div>
      </div>

      {data?.applyCoupon ? (
        <div
          className={`${classes.correctCode} d-flex align-items-center justify-content-between`}>
          <div className={`${classes.banner} d-flex align-items-center`}>
            <span className={`icon-coupon ${classes.icon}`}></span>{" "}
            {t("congts-fo-discount")} ({data?.couponCode})
          </div>
          <button
            disabled={removeCouponLoading}
            className={`btn btn-normal ${classes.btn}`}
            onClick={onRemoveCoupon}>
            {removeCouponLoading ? (
              <>
                <CircularProgress size={12} style={{ color: "#fff" }} />
                {t("loading")}
              </>
            ) : (
              t("remove")
            )}
          </button>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
