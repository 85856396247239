import React from "react";
import { Link } from "react-router-dom";
import classes from "./Error404.module.css";
import { Helmet } from "react-helmet";
import Button from "@mui/material/Button";
import Error404Img from "@assets/img/error404.png";
import { useTranslation } from "react-i18next";
import { Seo } from "@components";
import { Config } from "@config";

export const Error404 = () => {
  const { t } = useTranslation();
  return (
    <>
      <Seo title={`${Config.name} | ${t("page-not-found")}`} />
      <div className={classes.error}>
        <div className={classes.error404}>
          <img
            className={`img-fluid ${classes.image}`}
            src={Error404Img}
            alt={t("page-not-found")}
          />
          <div className={classes.errorTitle}>{t("page-not-found")}</div>
          <Button
            component={Link}
            to="/"
            size="large"
            color="primary"
            variant="contained">
            {t("back-to-home")}
          </Button>
        </div>
      </div>
    </>
  );
};
