import React, { useState, useEffect } from "react";
import classes from "./CartCard.module.css";
import { Avatar, CartAttributes, Counter } from "@components";
import {
  fetchCart,
  deleteCartItem,
  updateCartItemQty,
  clearState,
} from "@store/cart";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

export const CartCard = ({ className, row }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [currentId, setCurrentId] = useState(null);
  const {
    data,
    loading,
    success: successDeleteCart,
  } = useSelector((state) => state.cart.deleteCart);
  const { loading: loadingUpdateCartQty, success: successUpdateCartQty } =
    useSelector((state) => state.cart.updateCartQty);

  // const handleCounter = (data) => {
  //   dispatch(
  //     updateCartItemQty({
  //       qty: data,
  //       shoppingCartId: row.shoppingCartId,
  //       productId: row.id,
  //     })
  //   );
  //   // console.log(data);
  // };

  const onDeleteCartItem = (id) => {
    setCurrentId(id);
    dispatch(deleteCartItem({ shoppingCartId: row?.shoppingCartId }));
  };

  useEffect(() => {
    if (successDeleteCart) {
      dispatch(fetchCart({ noLoading: true }));
      dispatch(clearState());
    }
  }, [dispatch, successDeleteCart]);

  // useEffect(() => {
  //   if (successUpdateCartQty) {
  //     dispatch(clearState());
  //     dispatch(fetchCart());
  //   }
  // }, [dispatch, successUpdateCartQty]);

  return (
    <div className={`${classes.card} ${className} d-flex`}>
      <div className={`${classes.view}`}>
        <Avatar className={classes.image} src={row?.media?.url} />
      </div>
      <div className={`${classes.details}`}>
        <div className={classes.top}>
          <div className={`${classes.title} title max-1-line`}>
            {row?.title}
          </div>

          <div className="mt-2">
            {/* <div className={`${classes.text} text`}>040</div> */}
            <div className={`${classes.text} ${classes.price} text`}>
              {row?.unitPrice} {t("currency")}
            </div>
          </div>
          <CartAttributes row={row} />
        </div>

        <div
          className={`${classes.actions} d-flex justify-content-between align-items-end`}>
          <Counter
            // mainId={row?.id}
            // availableQty={row?.availableQty}
            // qty={row?.qty}
            // onDataFromCounter={handleCounter}
            row={row}
          />
          <button
            disabled={currentId === row.id && loading}
            onClick={() => onDeleteCartItem(row.id)}
            className={`btn btn-normal`}>
            {currentId === row.id && loading ? t("loading") : t("remove")}
          </button>
        </div>
      </div>
    </div>
  );
};
