import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import axiosInstance from "../interceptor/axiosInstance";

const initialState = {
  orders: {
    data: null,
    paginate: null,
    pagePaginate: 1,
    loading: false,
    success: false,
  },
  orderRow: { data: null, loading: false, success: false },
  cancelOrder: { data: null, loading: false, success: false },
};

// Fetch Orders
export const fetchOrders = createAsyncThunk(
  "fetchOrders/orders",
  async (action) => {
    const config = {
      url: `orders?paginate=10`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        page: action?.page,
      },
      params: {
        page: action?.page,
        search: action?.search,
        parentId: action?.parentId,
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

// Fetch Orders
export const fetchOrderRow = createAsyncThunk(
  "fetchOrderRow/orders",
  async (action) => {
    const config = {
      url: `orders/${action?.rowId}`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

// Cancel Order
export const cancelOrder = createAsyncThunk(
  "cancelOrder/orders",
  async (action) => {
    const config = {
      url: `orders/cancel`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        orderId: action?.id,
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

const orderReducer = createSlice({
  name: "order",
  initialState,
  reducers: {
    clearState: (state) => {
      state.orders.success = false;
      state.cancelOrder.success = false;
    },
    handlePagePagination: (state, action) => {
      state.orders.pagePaginate = action.payload.page;
    },
  },
  extraReducers: (builder) => {
    /********** Fetch Orders **********/
    builder.addCase(fetchOrders.pending, (state, action) => {
      state.orders.loading = true;
      state.orders.data = null;
      state.orders.paginate = null;
    });
    builder.addCase(fetchOrders.fulfilled, (state, action) => {
      state.orders.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        state.orders.data = payload?.data?.data?.rows;
        state.orders.paginate = payload?.data?.data?.paginate;
        state.orders.success = true;
        state.orders.pagePaginate = payload?.data?.data?.paginate?.current_page;
      }
    });
    builder.addCase(fetchOrders.rejected, (state, action) => {
      state.orders.loading = false;
    });
    /********** Fetch Orders **********/

    /********** Fetch Order Row **********/
    builder.addCase(fetchOrderRow.pending, (state, action) => {
      state.orderRow.loading = true;
    });
    builder.addCase(fetchOrderRow.fulfilled, (state, action) => {
      state.orderRow.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        state.orderRow.data = payload?.data?.data?.row;
        state.orderRow.success = true;
      }
    });
    builder.addCase(fetchOrderRow.rejected, (state, action) => {
      state.orderRow.loading = false;
    });
    /********** Fetch Order Row **********/

    /********** Cancel Order **********/
    builder.addCase(cancelOrder.pending, (state, action) => {
      state.cancelOrder.loading = true;
    });
    builder.addCase(cancelOrder.fulfilled, (state, action) => {
      state.cancelOrder.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        state.cancelOrder.data = payload?.data?.data?.row;
        state.cancelOrder.success = true;
      }
    });
    builder.addCase(cancelOrder.rejected, (state, action) => {
      state.cancelOrder.loading = false;
    });
    /********** Cancel Order **********/
  },
});

export const { handlePagePagination, clearState } = orderReducer.actions;

export default orderReducer.reducer;
