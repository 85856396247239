import React, { useState } from "react";
import classes from "../Auth.module.css";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Helmet } from "react-helmet";
import { jwt } from "@services";
import CircularProgress from "@mui/material/CircularProgress";
import { Seo } from "@components";

/** Redux **/
import { login } from "@store/auth/auth";
import { useDispatch, useSelector } from "react-redux";

/** Third Party **/
import { Config } from "@config/config";
import { NavLink, useNavigate, Navigate } from "react-router-dom";

const Login = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const jwToken = jwt();
  const { loading } = useSelector((state) => state.auth.login);

  const schema = yup
    .object({
      email: yup
        .string()
        .required(t("Validations.email-address-required"))
        .email(t("Validations.email-must-be-a-valid-email"))
        .min(6, t("Validations.email-too-short")),
      password: yup
        .string()
        .required(t("Validations.password-required"))
        .min(6, t("Validations.password-too-short")),
    })
    .required();

  const defaultValues = {
    email: "",
    password: "",
  };

  const {
    setError,
    clearErrors,
    reset,
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = async (data) => {
    dispatch(login(data));
  };

  // let redirect = null;
  // if (jwToken) {
  //   redirect = <Navigate to="/" />;
  // }

  return (
    <>
      <Seo title={`${Config.name} | ${t("login")}`} />
      <div className={classes.container}>
        <div className={`container-fluid text-center`}>
          <div className={classes.block}>
            <div className={`title`}>{t("login")}</div>
            <div className={`sub-title mt-3 mb-4`}>{t("auth-description")}</div>
            <form className="mt-2" onSubmit={handleSubmit(onSubmit)}>
              <div className={`input-box`}>
                <Controller
                  control={control}
                  {...register("email")}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      helperText={errors?.email ? errors?.email.message : null}
                      size="small"
                      error={!!errors?.email}
                      onChange={onChange}
                      value={value}
                      fullWidth
                      label={t("email")}
                      variant="outlined"
                    />
                  )}
                />
              </div>

              <div className={`input-box`}>
                <Controller
                  control={control}
                  {...register("password")}
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <TextField
                        helperText={
                          errors?.password ? errors?.password.message : null
                        }
                        type="password"
                        size="small"
                        error={!!errors?.password}
                        onChange={onChange}
                        value={value}
                        fullWidth
                        label={t("password")}
                        variant="outlined"
                      />
                    </div>
                  )}
                />
              </div>

              <div
                className={`${classes.suggestedLink} d-flex justify-content-end mb-3`}>
                <NavLink to="/auth/forgot-password">
                  {t("forgot-password")}
                </NavLink>
              </div>

              <Button
                disabled={loading}
                type="submit"
                size="large"
                fullWidth
                color="primary"
                variant="contained">
                {loading ? (
                  <>
                    <CircularProgress size={17} style={{ color: "#fff" }} />
                    {t("loading")}
                  </>
                ) : (
                  t("login")
                )}
              </Button>

              <div
                className={`${classes.suggestedLink} d-flex justify-content-center mt-2`}>
                <NavLink to="/auth/verify">{t("verify-your-email")}</NavLink>
              </div>
            </form>

            <div className={classes.accountCheck}>
              {t("dont-have-an-account")}
              <NavLink className={classes.accountCheckLink} to="/auth/register">
                {t("create-one")}
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { Login };
