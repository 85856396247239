import React, { useState, useEffect } from "react";
import { Routes, Route, Outlet } from "react-router";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import classes from "./Account.module.css";
import { jwt } from "@services";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { Config } from "@config";
import { Seo } from "@components";

/** Redux **/
import { useDispatch, useSelector } from "react-redux";
import { userData, logout } from "@store/auth/auth";
import { updatePassword, clearState } from "@store/user";

export const Password = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const jwToken = jwt();
  const { data, success, loading } = useSelector(
    (state) => state.user.updatePassword
  );

  const schema = yup
    .object({
      currentPassword: yup
        .string()
        .required(t("Validations.current-password-required"))
        .min(6, t("Validations.current-password-too-short")),

      newPassword: yup
        .string()
        .required(t("Validations.new-password-required"))
        .min(6, t("Validations.new-password-too-short")),

      confirmPassword: yup
        .string()
        .required(t("Validations.confirm-password-required"))
        .min(6, t("Validations.confirm-password-too-short")),
    })
    .required();

  const defaultValues = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  const {
    setError,
    clearErrors,
    reset,
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onLogout = () => {
    dispatch(logout());
  };

  useEffect(() => {
    dispatch(userData());
  }, [dispatch, jwToken]);

  const onSubmit = async (data) => {
    dispatch(updatePassword(data));
  };

  useEffect(() => {
    if (success) {
      reset();
      navigate("/account");
      dispatch(clearState());
    }
  }, [dispatch, reset, navigate, success]);

  return (
    <>
      <Seo title={`${t("Profile.update-password")}`} />
      <div className={`${classes.infoForm}`}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            spacing={2}
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            wrap="nowrap">
            <Grid
              item
              xs={12}
              sm={10}
              lg={4}
              container
              direction="column"
              justify="center"
              alignItems="center">
              <Controller
                control={control}
                {...register("currentPassword")}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    helperText={
                      errors?.currentPassword
                        ? errors?.currentPassword.message
                        : null
                    }
                    type="password"
                    size="small"
                    error={!!errors?.currentPassword}
                    onChange={onChange}
                    value={value}
                    fullWidth
                    label={t("Profile.current-password")}
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={10}
              lg={4}
              container
              direction="column"
              justify="center"
              alignItems="center">
              <Controller
                control={control}
                {...register("newPassword")}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    helperText={
                      errors?.newPassword ? errors?.newPassword.message : null
                    }
                    type="password"
                    size="small"
                    error={!!errors?.newPassword}
                    onChange={onChange}
                    value={value}
                    fullWidth
                    label={t("Profile.new-password")}
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={10}
              lg={4}
              container
              direction="column"
              justify="center"
              alignItems="center">
              <Controller
                control={control}
                {...register("confirmPassword")}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    helperText={
                      errors?.confirmPassword
                        ? errors?.confirmPassword.message
                        : null
                    }
                    type="password"
                    size="small"
                    error={!!errors?.confirmPassword}
                    onChange={onChange}
                    value={value}
                    fullWidth
                    label={t("Profile.confirm-new-password")}
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={10}
              lg={4}
              container
              direction="column"
              justify="center"
              alignItems="center">
              <Button
                type="submit"
                disabled={loading}
                fullWidth
                size="large"
                color="primary"
                variant="contained">
                {loading ? (
                  <>
                    <CircularProgress size={17} style={{ color: "#fff" }} />
                    {t("loading")}
                  </>
                ) : (
                  t("Profile.update-password")
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </>
  );
};
