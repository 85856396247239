import React, { useState, useEffect } from "react";
import classes from "../Filter.module.css";
import { CircleSpinner, EmptyProductsMsg } from "@components";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";

export const Discounts = ({ onDiscountsFromParent, discounts, loading }) => {
  const { t } = useTranslation();
  const [discountsFilter, setDiscountsFilter] = useState(null);
  const [discountsFiltered, setDiscountsFiltered] = useState([]);
  const location = useLocation();
  const { category, title, slug: slugOrId } = useParams();
  const categoryIdQuery = new URLSearchParams(location.search).get("cid");

  const reinitDiscounts = async () => {
    let newArray = null;
    newArray = await discounts?.map((row, index) => {
      if (discountsFiltered) {
        const data = discountsFiltered?.includes(row.id);
        return {
          id: row.id,
          title: row.title,
          offerPercentage: row.offerPercentage,
          selected: data ? true : false,
        };
      } else {
        return {
          id: row.id,
          title: row.title,
          offerPercentage: row.offerPercentage,
          selected: false,
        };
      }
    });

    setDiscountsFilter(newArray);
  };

  useEffect(() => {
    if (discounts) {
      reinitDiscounts();
    }
  }, [discounts]);

  // useEffect(() => {
  //   if (loading) {
  //     setDiscountsFiltered([]);
  //   }
  // }, [loading]);

  const onActiveDiscount = (index, id) => {
    if (discountsFilter[index]) {
      let newArr = [...discountsFilter];
      const newSelected = !newArr[index].selected;
      newArr[index].selected = newSelected;
      setDiscountsFilter(newArr);

      const exists = discountsFiltered.includes(id);
      if (exists) {
        if (!newSelected) {
          const removeValue = discountsFiltered.filter((item) => item !== id);
          setDiscountsFiltered(removeValue);
          onDiscountsFromParent(removeValue);
        }
      } else {
        if (newSelected) {
          const newdiscountsFiltered = [...discountsFiltered, id];
          setDiscountsFiltered(newdiscountsFiltered);
          onDiscountsFromParent(newdiscountsFiltered);
        }
      }
    }
  };

  useEffect(() => {
    setDiscountsFilter(discounts);
    reinitDiscounts();
  }, [category, categoryIdQuery]);

  return loading ? (
    <CircleSpinner size={35} />
  ) : discountsFilter?.length ? (
    <div className={`${classes.filterItemsGridDiscounts}`}>
      {discountsFilter?.map((row, index) => {
        return (
          <div key={row.id}>
            <button
              onClick={() => onActiveDiscount(index, row.id)}
              className={`btn btn-empty ${classes.sortItemTitle} ${
                row.selected ? classes.active : ""
              }`}>
              {row?.title}{" "}
              <span className={`red-color`}>({row?.offerPercentage}%)</span>
            </button>
          </div>
        );
      })}
    </div>
  ) : (
    <EmptyProductsMsg fontSize={16} text={t("no-data-description")} />
  );
};
