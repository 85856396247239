export const Config = {
  name: "Brigge",
  favIcon: "",
  mainColor: "#000000",
  logo: require("../assets/img/logo.png"),
  hideNavbarOnPages: ["/checkout"],
  layout: {
    toastPosition: "top-right",
  },
  langs: [
    {
      id: 1,
      title: "English",
      code: "en",
    },
    {
      id: 2,
      title: "العربية",
      code: "ar",
    },
  ],
  headerSwitcher: [
    {
      id: 1,
      title: "Brigge",
      link: "/",
    },
  ],
};
