import React, { useState, useEffect } from "react";
import classes from "./Payment.module.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { fetchCart, clearState } from "@store/cart";
import {
  createCheckout,
  clearState as checkoutClearState,
} from "@store/checkout";
import { useDispatch, useSelector } from "react-redux";
import { Note } from "./Note";
import { PaymentMethods } from "./PaymentMethods";
import { useTranslation } from "react-i18next";
import { jwt } from "@services";
import { GuestAddress } from "./GuestAddress";

export const Payment = ({ onReceivedAreaDeliveryValue }) => {
  const jwToken = jwt();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [note, setNote] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState("knet");
  const [guestAddress, setGuestAddress] = useState(null);
  const [gaIsValid, setGaIsValid] = useState(false);
  const { data: cartData, loading: loadingCartData } = useSelector(
    (state) => state.cart.cartData
  );
  const { loading: loadingCheckout, success: successCheckout } = useSelector(
    (state) => state.checkout.checkout
  );
  const { data: userData } = useSelector((state) => state.auth.userData);

  const onActionHandle = (tab) => {
    navigate(`${location.pathname}?tab=${tab}`);
  };

  useEffect(() => {
    dispatch(fetchCart({ noLoading: true }));
  }, [dispatch]);

  // useEffect(() => {
  //   if (cartData && !cartData?.selectUserAddress) {
  //     navigate(`?tab=information`);
  //     dispatch(clearState());
  //   }
  // }, [dispatch, cartData, navigate]);

  const onNote = (value) => {
    setNote(value);
  };

  const onPaymentMethod = (value) => {
    setPaymentMethod(value);
  };

  const onFinalCheckout = () => {
    if (!jwToken) {
      setGaIsValid(true);
      if (guestAddress === null) {
        return;
      }
    }

    dispatch(createCheckout({ note, paymentMethod, guestAddress }));
  };

  useEffect(() => {
    if (successCheckout) {
      dispatch(checkoutClearState());
      navigate(`/`);
    }
  }, [dispatch, navigate, successCheckout]);

  const onGuestAddressData = (data) => {
    setGuestAddress(data);
  };

  const onGetAreaDeliveryValue = (value) => {
    onReceivedAreaDeliveryValue(value);
  };

  return (
    <div className={`${classes.container}`}>
      <div className={`${classes.block}`}>
        {/* Row */}
        {userData && cartData?.selectUserAddress ? (
          <div className={`contact-table mb-5`}>
            <div
              className={`contact-table-row d-flex justify-content-between align-items-center`}>
              <div className={`contact-title`}>{t("address")}</div>
              <div className={`contact-value`}>{t("selected")}</div>
              <div className={`contact-action`}>
                <NavLink to={`?tab=information`} className={`btn btn-normal`}>
                  {t("change")}
                </NavLink>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {/* Row */}

        <div className={`${classes.paymentContainer}`}>
          {!jwToken ? (
            <GuestAddress
              gaIsValid={gaIsValid}
              onAreaDeliveryValue={onGetAreaDeliveryValue}
              onGuestAddressParent={onGuestAddressData}
            />
          ) : (
            ""
          )}

          <PaymentMethods
            onPaymentMethodFromParent={onPaymentMethod}
            paymentMethod={paymentMethod}
          />

          <Note onNoteFromParent={onNote} note={note} />

          <div
            className={`checkout-actions d-flex justify-content-between align-items-center pt-3 pb-3`}>
            <div className={`checkout-back-btn`}>
              {jwToken ? (
                <button
                  onClick={() => onActionHandle("information")}
                  className={`btn default-btn back-btn`}>
                  <span className={`icon-chevron-left icon`}> </span>
                  {t("return-to-information")}
                </button>
              ) : (
                ""
              )}
            </div>

            <div className={`checkout-submit-btn`}>
              <Button
                disabled={
                  paymentMethod === null ||
                  loadingCheckout ||
                  (jwToken && !cartData?.selectUserAddress)
                }
                onClick={onFinalCheckout}
                size="large"
                color="info"
                variant="contained"
                fullWidth>
                {loadingCheckout ? (
                  <>
                    <CircularProgress size={17} style={{ color: "#fff" }} />
                    {t("loading")}
                  </>
                ) : (
                  t("pay-now")
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
