import React from "react";
import classes from "../Filter.module.css";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { useTranslation } from "react-i18next";

export const PriceRange = ({ onPriceRangeFromParent, priceRange }) => {
  const { t } = useTranslation();
  const onPriceRange = (e) => {
    onPriceRangeFromParent(e.target.value);
  };

  // const updateSliderPrice = (event, index) => {
  //   const { value } = event.target;

  //   if (value > 100) {
  //     return;
  //   }

  //   if (index === 1 && priceRange[0] >= value) {
  //     return;
  //   }
  //   const newArray = [...priceRange];
  //   newArray[index] = value;
  //   setPriceRange(newArray);
  // };

  return (
    <div className={classes.sortPriceRange}>
      <div className={classes.sortBodyTitle}>{t("price")}</div>
      <div className={classes.priceRangeContainer}>
        <Box sx={{ width: "100%", direction: "ltr" }}>
          <Slider
            getAriaLabel={() => "Temperature range"}
            value={priceRange}
            onChange={onPriceRange}
            valueLabelDisplay="auto"
            color="secondary"
          />
        </Box>
      </div>

      <div className={classes.priceRangeInputs}>
        <div className={classes.sliderInput}>
          <span className={classes.currency}>{t("currency")}</span>
          <input
            type="text"
            value={priceRange[0]}
            disabled
            // onChange={(e) => updateSliderPrice(e, 0)}
          />
        </div>
        -
        <div className={classes.sliderInput}>
          <span className={classes.currency}>{t("currency")}</span>
          <input
            type="text"
            value={priceRange[1]}
            disabled
            // onChange={(e) => updateSliderPrice(e, 1)}
          />
        </div>
      </div>
    </div>
  );
};
