import React, { useState } from "react";
import classes from "./Counter.module.css";

export const Counter = (props) => {
  const maxCount = props?.availableQty;
  const sendCounterToParent = (type = null) => {
    let data = props.counter;
    if (type === "decrement") {
      if (data === 1) {
        return;
      }
      data -= 1;
    }

    if (type === "increment") {
      if (data === parseInt(maxCount)) {
        return;
      }
      data += 1;
    }
    props.onDataFromCounter(data);
  };

  return (
    <div
      className={[classes.counter, "d-inline-flex align-items-center"].join(
        " "
      )}>
      <div
        onClick={() => sendCounterToParent("decrement")}
        className={[
          classes.decrement,
          classes.counterBtn,
          "d-flex align-items-center",
        ].join(" ")}>
        <span className={`icon-minus ${classes.icon}`}></span>
      </div>
      <input
        className={[classes.inputCounter].join(" ")}
        type="text"
        value={props.counter}
      />
      <div
        onClick={() => sendCounterToParent("increment")}
        className={[
          classes.increment,
          classes.counterBtn,
          "d-flex align-items-center",
        ].join(" ")}>
        <span className={`icon-plus ${classes.icon}`}></span>
      </div>
    </div>
  );
};
