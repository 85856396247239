import React, { Children, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ScrollToTop, jwt } from "./services/index";
import {
  Home,
  Error404,
  Login,
  Register,
  ForgotPassword,
  ResetPassword,
  Verify,
  // Logout,
  Store,
  ViewProduct,
  Account,
  Address,
  Search,
  Brands,
  Categories,
  Cart,
  Checkout,
  Order,
  ContactUs,
  Faq,
  RefundPolicy,
  Delivery,
  TermsConditions,
  PrivacyPolicy,
  CookiesPolicy,
  AccountLayout,
  SuperDeals,
  Discounts,
  AboutUs,
  Wishlist,
  DynamicPage,
} from "./views/index";

function Router() {
  const jwToken = jwt();

  return (
    // jwToken ? <Navigate to="/" /> :
    <>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/auth">
          <Route
            path="login"
            element={jwToken ? <Navigate to="/" /> : <Login />}
          />
          <Route
            path="register"
            element={jwToken ? <Navigate to="/" /> : <Register />}
          />
          <Route
            path="forgot-password"
            element={jwToken ? <Navigate to="/" /> : <ForgotPassword />}
          />
          <Route
            path="reset-password"
            element={jwToken ? <Navigate to="/" /> : <ResetPassword />}
          />
          <Route
            path="verify"
            element={jwToken ? <Navigate to="/" /> : <Verify />}
          />
          {/* <Route path="logout" element={<Logout />} /> */}
        </Route>
        <Route
          path="/account/*"
          element={
            jwToken ? <AccountLayout /> : <Navigate to="/auth/login" />
          }></Route>

        <Route
          path="/account/addresses"
          element={jwToken ? <Address /> : <Navigate to="/auth/login" />}
        />
        <Route
          path="/a/o/:id"
          element={jwToken ? <Order /> : <Navigate to="/auth/login" />}
        />
        <Route
          path="/account/wishlist"
          element={jwToken ? <Wishlist /> : <Navigate to="/auth/login" />}
        />
        <Route path="/:category/:title/:slug" element={<Store />} />
        <Route path="/p/:id" element={<ViewProduct />} />
        <Route path="/search" element={<Search />} />
        <Route path="/brands" element={<Brands />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<Checkout />} />

        <Route path="/super-deals" element={<SuperDeals />} />
        <Route path="/discounts" element={<Discounts />} />

        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/faqs" element={<Faq />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/delivery" element={<Delivery />} />
        <Route path="/cookies-policy" element={<CookiesPolicy />} />
        {/* <Route path="/pages/:slug" element={<DynamicPage />} /> */}
        <Route path="/*" element={<Error404 />} />
      </Routes>
    </>
  );
}

export default Router;
