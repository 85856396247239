import React, { useState } from "react";
import classes from "../Auth.module.css";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Helmet } from "react-helmet";
import CircularProgress from "@mui/material/CircularProgress";
import { jwt } from "@services";

/** Redux **/
import { resetPassword, clearState } from "@store/auth/auth";
import { useDispatch, useSelector } from "react-redux";

/** Third Party **/
import { Config } from "@config/config";
import { NavLink, Navigate } from "react-router-dom";

const ResetPassword = () => {
  const { t, i18n } = useTranslation();
  const jwToken = jwt();
  const dispatch = useDispatch();
  const { loading, success } = useSelector((state) => state.auth.resetPassword);

  const schema = yup
    .object({
      password: yup
        .string()
        .required(t("Validations.password-required"))
        .min(6, t("Validations.password-too-short")),
      confirmationPassword: yup
        .string()
        .required(t("Validations.confirm-password-required"))
        .oneOf(
          [yup.ref("password")],
          t("Validations.passwords-does-not-match")
        ),
    })
    .required();

  const defaultValues = {
    password: "",
    confirmationPassword: "",
  };

  const {
    setError,
    clearErrors,
    reset,
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = async (data) => {
    const newData = Object.assign(data, {
      validationCode: "validationCode",
      email: "email",
    });
    dispatch(resetPassword(newData));
  };

  let redirect = null;
  if (jwToken) {
    redirect = <Navigate to="/" />;
  }

  return (
    redirect ?? (
      <>
        <Helmet>
          <title>
            {Config.name} | {t("reset-password")}
          </title>
        </Helmet>
        <div className={classes.container}>
          <div className={`container-fluid text-center`}>
            <div className={classes.block}>
              <div className={`title`}>{t("reset-password")}</div>
              <div className={`sub-title mt-3 mb-4`}>
                {t("please-enter-a-new-password")}
              </div>
              <form className="mt-2" onSubmit={handleSubmit(onSubmit)}>
                <div className={`input-box`}>
                  <Controller
                    control={control}
                    {...register("password")}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        <TextField
                          helperText={
                            errors?.password ? errors?.password.message : null
                          }
                          type="password"
                          size="small"
                          error={!!errors?.password}
                          onChange={onChange}
                          value={value}
                          fullWidth
                          label={t("password")}
                          variant="outlined"
                        />
                      </div>
                    )}
                  />
                </div>

                <div className={`input-box`}>
                  <Controller
                    control={control}
                    {...register("confirmationPassword")}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        <TextField
                          helperText={
                            errors?.confirmationPassword
                              ? errors?.confirmationPassword.message
                              : null
                          }
                          type="password"
                          size="small"
                          error={!!errors?.confirmationPassword}
                          onChange={onChange}
                          value={value}
                          fullWidth
                          label={t("confirm-password")}
                          variant="outlined"
                        />
                      </div>
                    )}
                  />
                </div>

                <Button
                  type="submit"
                  size="large"
                  fullWidth
                  color="primary"
                  variant="contained">
                  {loading ? (
                    <>
                      <CircularProgress size={17} style={{ color: "#fff" }} />
                      {t("loading")}
                    </>
                  ) : (
                    t("reset")
                  )}
                </Button>
              </form>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export { ResetPassword };
