import React, { useState, useEffect } from "react";
import classes from "./Order.module.css";
import Button from "@mui/material/Button";
import { NavLink, Link, useParams } from "react-router-dom";
import { OrderCard } from "./OrderCard/OrderCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrderRow, cancelOrder, clearState } from "@store/orders";
import { CircleSpinner, EmptyProductsMsg, Seo } from "@components";
import { Helmet } from "react-helmet";
import { Config } from "@config/config";
import { OrderedAlert } from "./OrderedAlert/OrderedAlert";
import { useTranslation } from "react-i18next";
import { Spinner } from "reactstrap";

/** Sweetalert **/
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

export const Order = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isCancelled, setIsCancelled] = useState(false);
  const [orderStatus, setOrderStatus] = useState(null);
  const { data, loading } = useSelector((state) => state.order.orderRow);

  const { loading: cancelOrderLoading, success } = useSelector(
    (state) => state.order.cancelOrder
  );

  const onCancelOrder = async (id) => {
    await dispatch(cancelOrder({ id }));
  };

  useEffect(() => {
    if (success) {
      MySwal.fire({
        icon: "success",
        title: t("order-canceled"),
        text: t("order-canceled-successfully"),
        confirmButtonText: t("ok"),
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
      // dispatch(fetchOrderRow());
      dispatch(clearState());
      setIsCancelled(true);
      setOrderStatus("cancelled");
    }
  }, [dispatch, t, setOrderStatus, success]);

  const handleConfirmCancel = (id) => {
    return MySwal.fire({
      title: "",
      text: `${t("are-you-sure-you-would-like-to-remove-this-order")} ${id}`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: t("cancel"),
      confirmButtonText: cancelOrderLoading ? (
        <>
          <Spinner color="white" size="sm" /> {t("loading")}
        </>
      ) : (
        t("yes")
      ),
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-outline-danger ms-1",
      },
      buttonsStyling: false,
    }).then(async function (result) {
      if (result.value) {
        onCancelOrder(id);
      } else if (result.dismiss === MySwal.DismissReason.cancel) {
        MySwal.fire({
          title: t("cancelled"),
          text: "",
          icon: "error",
          confirmButtonText: t("ok"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      }
    });
  };

  useEffect(() => {
    dispatch(fetchOrderRow({ rowId: id }));
  }, [dispatch, id]);

  useEffect(() => {
    if (data) {
      setOrderStatus(data?.orderStatus);
    }
  }, [data, setOrderStatus]);

  return (
    <>
      <Seo title={`${Config.name} | ${t("order")} #${id}`} />
      <div className={classes.container}>
        <div className={`container`}>
          <div className={`page-content`}>
            <div className={classes.block}>
              {loading ? (
                <CircleSpinner size={"lg"} />
              ) : data ? (
                <>
                  <div className={`${classes.addressDetails} pb-5`}>
                    <NavLink
                      to="/account"
                      className={`btn default-btn back-btn mb-3`}>
                      <span className={`icon-chevron-left icon`}> </span>
                      {t("back-to-account")}
                    </NavLink>

                    <div className={`title mb-3`}>
                      {t("order")} #{data?.id}{" "}
                      <span>
                        {data?.orderStatus != "cancelled" && !isCancelled ? (
                          <button
                            disabled={data?.cancelOrderLoading}
                            className="btn btn-danger"
                            onClick={() => handleConfirmCancel(data.id)}>
                            {t("cancel")}
                          </button>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                    <div className={`text mb-4`}>
                      {t("order-placed-on")} {data?.orderedDate}
                    </div>

                    <div
                      className={`${classes.text} text-capitalize table-text`}>
                      <div
                        className={`${classes.status} status ${orderStatus}`}>
                        <span
                          className={`${classes.bullet} bullet ${orderStatus}Bullet`}></span>{" "}
                        {t(`Status.${orderStatus}`)}
                      </div>
                    </div>

                    <div className={`${classes.paymentMethod}`}>
                      {t("payment-method")} :{" "}
                      {t(
                        data?.payment?.paymentMethod === "cash"
                          ? "PaymentMethod.cash"
                          : "PaymentMethod.credit-card"
                      )}
                    </div>
                  </div>

                  {data?.deliveredDate ? <OrderedAlert row={data?.user} /> : ""}

                  <div className={`${classes.grid}`}>
                    {/* Table */}
                    <div className={classes.orderContainer}>
                      <div className={`${classes.table} main-table`}>
                        <table className="w-100">
                          <thead>
                            <tr>
                              <th>{t("product")}</th>
                              <th className="text-center">{t("quantity")}</th>
                              <th className="text-end">{t("total")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.products?.length > 0
                              ? data?.products?.map((row, index) => {
                                  return <OrderCard key={row?.id} row={row} />;
                                })
                              : ""}
                          </tbody>
                        </table>
                      </div>

                      <div
                        className={`${classes.reset} d-flex justify-content-between`}>
                        <div className={`${classes.orderNote} mb-5`}>
                          <div className={`${classes.orderNoteTitle}`}>
                            {t("order-note")}
                          </div>
                          <div className={`${classes.orderNotetext}`}>
                            {data?.userNote
                              ? data?.userNote
                              : t("unavailable-notes")}
                          </div>
                        </div>
                        <div className={`${classes.resetContent}`}>
                          <div className={`${classes.resetRow}`}>
                            <div className={`${classes.resetRowKey} title-sm`}>
                              {t("subtotal")}
                            </div>
                            <div className={`${classes.resetRowValue} text`}>
                              {data?.subTotal} {t("currency")}
                            </div>
                          </div>

                          <div className={`${classes.resetRow}`}>
                            <div className={`${classes.resetRowKey} title-sm`}>
                              {t("discount")} (-)
                            </div>
                            <div className={`${classes.resetRowValue} text`}>
                              {data?.discount} {t("currency")}
                            </div>
                          </div>

                          {data?.couponValue != "0.000" ? (
                            <div className={`${classes.resetRow}`}>
                              <div
                                className={`${classes.resetRowKey} title-sm`}>
                                {t("coupon")} (-)
                              </div>
                              <div className={`${classes.resetRowValue} text`}>
                                {data?.couponValue} {t("currency")}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          <div className={`${classes.resetRow}`}>
                            <div className={`${classes.resetRowKey} title-sm`}>
                              {t("shipping")} ({t("standard")}) (+)
                            </div>
                            <div className={`${classes.resetRowValue} text`}>
                              {data?.deliveryFees} {t("currency")}
                            </div>
                          </div>

                          <div className={`${classes.resetRow}`}>
                            <div className={`${classes.resetRowKey} title-sm`}>
                              {t("tax")} (+)
                            </div>
                            <div className={`${classes.resetRowValue} text`}>
                              {data?.tax} {t("currency")}
                            </div>
                          </div>

                          <div className={`${classes.resetRow}`}>
                            <div className={`${classes.resetRowKey} title-md`}>
                              {t("total")}
                            </div>
                            <div
                              className={`${classes.resetRowValue} title-md`}>
                              {data?.grandTotal} {t("currency")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Table */}

                    {/* Address */}
                    <div className={classes.address}>
                      <div className={`${classes.addressRow} mb-5`}>
                        <div className={`${classes.cardTitle} title-sm`}>
                          {t("shipping-address")}
                        </div>
                        <div className={`${classes.content}`}>
                          <div className={`${classes.title} text mb-3`}>
                            {data?.user?.name}
                          </div>
                          <div className={`${classes.text} text`}>
                            {data?.user?.address}
                          </div>
                          <div className={`${classes.text} text`}>
                            {data?.user?.province}
                          </div>
                          <div className={`${classes.text} text`}>
                            {data?.user?.city}
                          </div>
                          <div className={`${classes.text} text mt-2 mb-2`}>
                            <a
                              className={`${classes.phoneText}`}
                              href={`tel:${data?.user?.mobile}`}>
                              {data?.user?.mobile}
                            </a>
                          </div>
                          <div className={`${classes.text} text`}>
                            {t("kuwait")}
                          </div>
                        </div>
                      </div>

                      {/* <div className={`${classes.addressRow}`}>
                        <div className={`${classes.cardTitle} title-sm`}>
                          Billing address
                        </div>
                        <div className={`${classes.content}`}>
                          <div className={`${classes.text} text mb-3`}>
                            Emad Samy
                          </div>
                          <div className={`${classes.text} text`}>KW-AH</div>
                          <div className={`${classes.text} text`}>Kuwait</div>
                        </div>
                      </div> */}
                    </div>
                    {/* Address */}
                  </div>
                </>
              ) : (
                <EmptyProductsMsg text={t("no-data-description")} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
