import React, { useState, useEffect } from "react";
import classes from "./Payment.module.css";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";

export const Note = ({ onNoteFromParent, note }) => {
  const { t } = useTranslation();
  return (
    <div className={`${classes.paymentGateaway} pt-4`}>
      <div className="mb-2">
        <div className={`${classes.title} title-md`}>
          {t("add-note-optional")}
        </div>
      </div>
      <TextField
        fullWidth
        id="outlined-multiline-static"
        label={t("order-note")}
        multiline
        rows={4}
        value={note}
        onChange={(e) => onNoteFromParent(e.target.value)}
      />
    </div>
  );
};
