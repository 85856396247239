import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { NavLink, useLocation } from "react-router-dom";

/** Third Party **/
import { Config } from "@config/config";
import classes from "./Wishlist.module.css";
import {
  Avatar,
  Paginate,
  Product,
  CircleSpinner,
  EmptyProductsMsg,
  ProductCardDefault,
  Seo,
} from "@components";
import { useTranslation } from "react-i18next";

/** Redux **/
import { fetchWishlist, handlePagePagination } from "@store/wishlist";
import { useDispatch, useSelector } from "react-redux";

export const Wishlist = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const { data, paginate, pagePaginate, loading } = useSelector(
    (state) => state.wishlist.wishlist
  );

  const onPagePaginate = (data) => {
    dispatch(handlePagePagination({ page: data }));
    if (data > 1) {
      dispatch(fetchWishlist({ page: data }));
    } else {
      dispatch(fetchWishlist());
    }
  };

  useEffect(() => {
    dispatch(fetchWishlist());
  }, [dispatch]);

  return (
    <>
      <Seo title={`${Config.name} | ${t("Profile.wishlist")}`} />
      <div className={classes.container}>
        <div className={`container-fluid text-center`}>
          <div className={`title-container text-center`}>
            <div className={`title`}>{t("Profile.wishlist")}</div>
          </div>

          <div className={classes.block}>
            {loading ? (
              <CircleSpinner className={"pb-5"} size="lg" />
            ) : data?.length > 0 ? (
              <>
                <div className={`${classes.grid} cards-grid-four`}>
                  {data?.map((row, index) => (
                    <ProductCardDefault key={row.id} row={row} />
                  ))}
                </div>
                {paginate?.last_page > 1 ? (
                  <Paginate
                    page={pagePaginate}
                    count={paginate?.last_page}
                    onPage={onPagePaginate}
                  />
                ) : (
                  ""
                )}
              </>
            ) : (
              <EmptyProductsMsg
                text={t("no-data-description")}
                fontSize={25}
                className={"pt-3"}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
