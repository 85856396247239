import React, { useState, useEffect } from "react";
import classes from "./AddressCard.module.css";
import { Spinner } from "reactstrap";

/** Redux **/
import { useDispatch, useSelector } from "react-redux";
import { fetchAddresses, deleteAddress, clearState } from "@store/user";
import { useTranslation } from "react-i18next";

/** Sweetalert **/
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

export const AddressCard = ({ index, actions, title, row, onEditToChild }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const loadingDelete = useSelector(
    (state) => state.user.deleteAddress.loading
  );
  const successDelete = useSelector(
    (state) => state.user.deleteAddress.success
  );

  const deleteData = async (id) => {
    await dispatch(deleteAddress({ rowId: id }));
    console.log(id);
  };

  useEffect(() => {
    if (successDelete) {
      MySwal.fire({
        icon: "success",
        title: t("removed"),
        text: t("address-removed-successfully"),
        confirmButtonText: t("ok"),
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
      dispatch(fetchAddresses());
      dispatch(clearState());
    }
  }, [dispatch, t, successDelete]);

  const handleConfirmCancel = (id, name) => {
    return MySwal.fire({
      title: "",
      text: `${t(
        "are-you-sure-you-would-like-to-remove-this-address"
      )} ${name}`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: t("cancel"),
      confirmButtonText: loadingDelete ? (
        <>
          <Spinner color="white" size="sm" /> {t("loading")}
        </>
      ) : (
        t("yes")
      ),
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-outline-danger ms-1",
      },
      buttonsStyling: false,
    }).then(async function (result) {
      if (result.value) {
        deleteData(id);
      } else if (result.dismiss === MySwal.DismissReason.cancel) {
        MySwal.fire({
          title: t("cancelled"),
          text: t("remove-address-cancelled"),
          icon: "error",
          confirmButtonText: t("ok"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      }
    });
  };

  return (
    <div className={classes.card}>
      {title ? (
        <div className={`${classes.cardTitle} title-regular`}>
          {row.isPrimary ? t("primary-address") : `${t("address")} ` + row.id}
        </div>
      ) : (
        ""
      )}

      {row?.mobile ? (
        <div className={`${classes.row} mb-4`}>
          <div className={`sub-title ${classes.key}`}>{t("phone-number")}</div>
          <div className={`text ${classes.value}`}>
            {row?.countryCode ?? ""} {row?.mobile ?? ""}
          </div>
        </div>
      ) : (
        ""
      )}

      <div className={`${classes.row} mb-4`}>
        <div className={`sub-title ${classes.key}`}>{t("address")}</div>
        <div className={`text ${classes.value}`}>
          {row?.title}
          <br />
          {row?.address}
          <br />
          {row?.city?.name}
          <br />
          {row?.province?.name}
          <br />
          {t("kuwait")}
          <br />
          {row?.zipCode}
        </div>
      </div>

      {actions ? (
        <div className={classes.actions}>
          <button
            onClick={() => onEditToChild(true, row)}
            className="btn btn-normal">
            {t("edit")}
          </button>
          <button
            className="btn btn-normal"
            onClick={() => handleConfirmCancel(row.encryptId, row.title)}>
            {t("remove")}
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
