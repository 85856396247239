import React, { useState, useEffect } from "react";
import classes from "../Filter.module.css";
import { useLocation, useParams } from "react-router-dom";

/** Redux **/
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFilterOptions,
  fetchOptionsFiltered,
} from "@store/storeCollection/index";

export const Categories = ({ onCategoriesFromParent, categories }) => {
  const dispatch = useDispatch();
  const [categoriesFilter, setCategoriesFilter] = useState(null);
  const [categoriesFiltered, setCategoriesFiltered] = useState([]);

  const location = useLocation();
  const { category, title, slug: slugOrId } = useParams();
  const categoryIdQuery = new URLSearchParams(location.search).get("cid");

  const reinitCategories = async () => {
    let newArray = null;
    newArray = await categories?.map((row, index) => {
      let newArrayChilds = null;
      let newArrayGrandsons = null;
      // if (row.childs) {
      //   newArrayChilds = row?.childs?.map((child, childIdx) => {
      //     if (child.childs) {
      //       newArrayGrandsons = child?.childs?.map((grandson, grandsonIdx) => {
      //         return {
      //           id: grandson.id,
      //           title: grandson.title,
      //           selected: false,
      //         };
      //       });
      //     }

      //     return {
      //       id: child.id,
      //       title: child.title,
      //       selected: false,
      //       childs: newArrayGrandsons,
      //     };
      //   });
      // }

      if (categoriesFiltered) {
        const data = categoriesFiltered.includes(row.id);
        return {
          id: row.id,
          title: row.title,
          childs: newArrayChilds,
          selected: data ? true : false,
        };
      } else {
        return {
          id: row.id,
          title: row.title,
          childs: newArrayChilds,
          selected: false,
        };
      }
    });

    setCategoriesFilter(newArray);
  };

  // useEffect(() => {
  //   dispatch(fetchFilterOptions());
  // }, [dispatch]);

  useEffect(() => {
    if (categories) {
      reinitCategories();
    }
  }, [categories]);

  const onActiveCategory = (index, id) => {
    if (categoriesFilter[index]) {
      let newArr = [...categoriesFilter];
      const newSelected = !newArr[index].selected;
      newArr[index].selected = newSelected;
      setCategoriesFilter(newArr);

      const exists = categoriesFiltered.includes(id);
      if (exists) {
        if (!newSelected) {
          const removeValue = categoriesFiltered.filter((item) => item !== id);
          setCategoriesFiltered(removeValue);
          onCategoriesFromParent(removeValue);
          // dispatch(fetchOptionsFiltered({ categoriesId: removeValue }));
        }
      } else {
        if (newSelected) {
          const newCategoriesFiltered = [...categoriesFiltered, id];
          setCategoriesFiltered(newCategoriesFiltered);
          onCategoriesFromParent(newCategoriesFiltered);
          // dispatch(
          //   fetchOptionsFiltered({ categoriesId: newCategoriesFiltered })
          // );
        }
      }
    }
  };

  useEffect(() => {
    setCategoriesFilter(categories);
    reinitCategories();
  }, [category, categoryIdQuery]);

  return (
    <div className={`${classes.filterItemsGrid}`}>
      {categoriesFilter?.map((row, index) => {
        return (
          <div key={row.id}>
            <button
              onClick={() => onActiveCategory(index, row.id)}
              className={`btn btn-empty ${classes.sortItemTitle} ${
                row.selected ? classes.active : ""
              }`}>
              {row?.title}
            </button>
          </div>
        );
      })}
    </div>
  );
};
