import React, { useState } from "react";
import classes from "./ProductCard.module.css";
import {
  Avatar,
  OnSale,
  Bookmark,
  ProductCardActions,
  Share,
} from "@components";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const ProductCard = ({ row }) => {
  const { t } = useTranslation();
  return (
    <div className={`${classes.card}`} data-aos="zoom-in-down">
      <OnSale sale={row?.hasOffer} />
      <NavLink className={`${classes.cardView}`} to={`/p/${row?.encryptId}`}>
        <Avatar className={`${classes.viewImg}`} src={row?.media?.url} />
      </NavLink>
      <div className={`${classes.info}`}>
        <div className={`${classes.infoTop} mb-1`}>
          <div className={`${classes.title} text-capitalize lines-2`}>
            {row?.title}
          </div>
          <div className={`${classes.priceContainer}`}>
            {row?.oldPrice > row?.price ? (
              <div className={`${classes.price}`}>
                <span className={`${classes.currentPrice}`}>
                  {row?.price} {t("currency")}
                </span>
                <span className={`${classes.oldPrice}`}>
                  {row?.oldPrice} {t("currency")}
                </span>
              </div>
            ) : (
              <span className={`${classes.currentPrice}`}>
                {row?.price} {t("currency")}
              </span>
            )}
          </div>
        </div>

        <ProductCardActions row={row} />
      </div>
    </div>
  );
};
