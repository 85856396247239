import React, { useState, useEffect } from "react";
import classes from "../Filter.module.css";
import { CircleSpinner, EmptyProductsMsg } from "@components";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";

export const Attributes = ({ onDataAttrsFromParent, dataAttrs, loading }) => {
  const { t } = useTranslation();
  const [attrsFilter, setAttrsFilter] = useState(null);
  const [attrsFiltered, setAttrsFiltered] = useState([]);
  const location = useLocation();
  const { category, title, slug: slugOrId } = useParams();
  const categoryIdQuery = new URLSearchParams(location.search).get("cid");

  const reinitAttrs = async () => {
    let newArray = null;
    newArray = await dataAttrs?.map((row, index) => {
      if (attrsFiltered) {
        const data = attrsFiltered?.includes(row.id);
        return {
          id: row.id,
          title: row.title,
          selected: data ? true : false,
        };
      } else {
        return {
          id: row.id,
          title: row.title,
          selected: false,
        };
      }
    });

    setAttrsFilter(newArray);
  };

  useEffect(() => {
    if (dataAttrs) {
      reinitAttrs();
    }
  }, [dataAttrs]);

  const onActiveAttr = (index, id) => {
    if (attrsFilter[index]) {
      let newArr = [...attrsFilter];
      const newSelected = !newArr[index].selected;
      newArr[index].selected = newSelected;
      setAttrsFilter(newArr);

      const exists = attrsFiltered.includes(id);
      if (exists) {
        if (!newSelected) {
          const removeValue = attrsFiltered.filter((item) => item !== id);
          setAttrsFiltered(removeValue);
          onDataAttrsFromParent(removeValue);
        }
      } else {
        if (newSelected) {
          const newAttrsFiltered = [...attrsFiltered, id];
          setAttrsFiltered(newAttrsFiltered);
          onDataAttrsFromParent(newAttrsFiltered);
        }
      }
    }
  };

  useEffect(() => {
    setAttrsFilter(dataAttrs);
    reinitAttrs();
  }, [category, categoryIdQuery]);

  return loading ? (
    <CircleSpinner size={35} />
  ) : attrsFilter?.length ? (
    <div className={`${classes.filterItemsGrid}`}>
      {attrsFilter?.map((row, index) => {
        return (
          <div key={row.id}>
            <button
              onClick={() => onActiveAttr(index, row.id)}
              className={`btn btn-empty ${classes.sortItemTitle} ${
                row.selected ? classes.active : ""
              }`}>
              {row?.title}
            </button>
          </div>
        );
      })}
    </div>
  ) : (
    <EmptyProductsMsg fontSize={16} text={t("no-data-description")} />
  );
};
