import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import axiosInstance from "../interceptor/axiosInstance";

const initialState = {
  address: { data: [], loading: false, success: false },
  addresses: {
    data: null,
    paginate: null,
    page: 1,
    loading: false,
    success: false,
  },
  deleteAddress: { loading: false, success: false },
  updateAddress: { loading: false, success: false },
  updateProfile: { loading: false, success: false },
  updatePassword: { loading: false, success: false },
};

// Create Adress
export const createAdress = createAsyncThunk(
  "createAdress/user",
  async (action) => {
    const config = {
      url: `userAddresses`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        province: {
          id: action.province,
        },
        city: {
          id: action.city,
        },
        title: action.addressTitle,
        address: action.address2,
        // countryCode: action.countryCode,
        mobile: action.phone,
        zipCode: action.zipCode,
        primary: action.primary ? true : false,
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

// Fetch Adress
export const fetchAddresses = createAsyncThunk(
  "fetchAddresses/user",
  async (action) => {
    let url = "userAddresses";
    let page = {};

    if (action?.page && action?.page != 1) {
      url = `userAddresses?page=${action.page}`;
      page = {
        page: action?.page,
      };
    }

    const config = {
      url: url,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: page,
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

// Update Adress
export const updateAddress = createAsyncThunk(
  "updateAddress/user",
  async (action) => {
    const config = {
      url: `userAddresses/${action.rowId}`,
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        province: {
          id: action.province,
        },
        city: {
          id: action.city,
        },
        title: action.addressTitle,
        address: action.address2,
        mobile: action.phone,
        zipCode: action.zipCode,
        primary: action.primary ? true : false,
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

// Delete Adress
export const deleteAddress = createAsyncThunk(
  "deleteAddress/user",
  async (action) => {
    const config = {
      url: `userAddresses/${action.rowId}`,
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

// Update Profile
export const updateProfile = createAsyncThunk(
  "updateProfile/user",
  async (action) => {
    const config = {
      url: `auth/updateProfile`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        name: action.fullname,
        email: action.email,
        mobile: action.phoneNumber,
        countryCode: action.selectCountry,
        imageBase64: action.avatar,
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

// Update Profile
export const updatePassword = createAsyncThunk(
  "updatePassword/user",
  async (action) => {
    const config = {
      url: `auth/updatePassword`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        currentPassword: action?.currentPassword,
        newPassword: action?.newPassword,
        confirmNewPassword: action?.confirmPassword,
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

const userReducer = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearState: (state) => {
      state.address.success = false;
      state.addresses.success = false;
      state.deleteAddress.success = false;
      state.updateAddress.success = false;
      state.updateProfile.success = false;
      state.updatePassword.success = false;
    },
    handlePagePagination: (state, action) => {
      state.addresses.page = action.payload.page;
    },
  },
  extraReducers: (builder) => {
    /********** Create Address **********/
    builder.addCase(createAdress.pending, (state, action) => {
      state.address.loading = true;
    });
    builder.addCase(createAdress.fulfilled, (state, action) => {
      state.address.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        state.address.data = payload?.data?.data?.rows;
        state.address.success = true;
        state.addresses.page = 1;

        // // Append row to data
        // const insertedObj = action.meta.arg;
        // const row = null;
        // row.title = insertedObj.address1
        // state.addresses.data = [{ ...row }, ...state.addresses.data];
      }
    });
    builder.addCase(createAdress.rejected, (state, action) => {
      state.address.loading = false;
    });
    /********** Create Address **********/

    /********** Fetch Addresses **********/
    builder.addCase(fetchAddresses.pending, (state, action) => {
      state.addresses.loading = true;
      state.addresses.data = null;
      state.addresses.paginate = null;
    });
    builder.addCase(fetchAddresses.fulfilled, (state, action) => {
      state.addresses.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        state.addresses.data = payload?.data?.data?.rows;
        state.addresses.paginate = payload?.data?.data?.paginate;
        state.addresses.success = true;
        const pageIfFound = action?.meta?.arg?.page;
        if (pageIfFound) {
          state.addresses.page = pageIfFound;
        } else {
          state.addresses.page = 1;
        }
      }
    });
    builder.addCase(fetchAddresses.rejected, (state, action) => {
      state.addresses.loading = false;
    });
    /********** Fetch Addresses **********/

    /********** Delete Address **********/
    builder.addCase(deleteAddress.pending, (state, action) => {
      state.deleteAddress.loading = true;
    });
    builder.addCase(deleteAddress.fulfilled, (state, action) => {
      state.deleteAddress.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        state.deleteAddress.success = true;
        const newArr = state.addresses.data.filter(
          (idx) => idx.encryptId !== action.meta.arg.rowId
        );
        state.addresses.data = newArr;
        state.addresses.page = 1;
      }
    });
    builder.addCase(deleteAddress.rejected, (state, action) => {
      state.deleteAddress.loading = false;
    });
    /********** Delete Address **********/

    /********** Update Address **********/
    builder.addCase(updateAddress.pending, (state, action) => {
      state.updateAddress.loading = true;
    });
    builder.addCase(updateAddress.fulfilled, (state, action) => {
      state.updateAddress.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        state.updateAddress.success = true;
        state.addresses.page = 1;
      }
    });
    builder.addCase(updateAddress.rejected, (state, action) => {
      state.updateAddress.loading = false;
    });
    /********** Update Address **********/

    /********** Update Profile **********/
    builder.addCase(updateProfile.pending, (state, action) => {
      state.updateProfile.loading = true;
    });
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      state.updateProfile.loading = false;
      const payload = action?.payload;
      if (payload?.status === 200 || payload?.status === 201) {
        state.updateProfile.success = true;
      }
    });
    builder.addCase(updateProfile.rejected, (state, action) => {
      state.updateProfile.loading = false;
    });
    /********** Update Profile **********/

    /********** Update Password **********/
    builder.addCase(updatePassword.pending, (state, action) => {
      state.updatePassword.loading = true;
    });
    builder.addCase(updatePassword.fulfilled, (state, action) => {
      state.updatePassword.loading = false;
      const payload = action.payload;
      if (payload?.status === 200 || payload?.status === 201) {
        state.updatePassword.success = true;
      }
    });
    builder.addCase(updatePassword.rejected, (state, action) => {
      state.updatePassword.loading = false;
    });
    /********** Update Password **********/
  },
});

export const { handlePagePagination, clearState } = userReducer.actions;

export default userReducer.reducer;
