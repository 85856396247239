import React, { useState } from "react";
import classes from "./Share.module.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Config } from "@config";
import { Avatar } from "@components";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import Tooltip from "@mui/material/Tooltip";

import {
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  InstapaperShareButton,
} from "react-share";

// Icons
import Facebook from "@assets/img/icons/social-media/facebook-1.svg";
import Messenger from "@assets/img/icons/social-media/messenger.svg";
import Whatsapp from "@assets/img/icons/social-media/whatsapp.svg";
import Copy from "@assets/img/icons/social-media/copy.svg";
import Twitter from "@assets/img/icons/social-media/twitter.svg";
import Linkedin from "@assets/img/icons/social-media/linkedin.svg";
import Telegram from "@assets/img/icons/social-media/telegram.svg";
import Pinterest from "@assets/img/icons/social-media/pinterest.svg";

export const Share = ({ row, className, noTitle }) => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);
  const url = `${window.location.origin}/p/${row?.encryptId}`;

  const clipboard = () => {
    const el = document.createElement("input");
    el.value = url;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
    toast(t("url-copied"), {
      icon: "📋",
      style: {
        borderRadius: "10px",
        background: "#fff",
        color: "#000",
      },
    });
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return (
    <>
      <div className={`${classes.shareContainer} ${className}`}>
        {noTitle ? (
          ""
        ) : (
          <div className={`${classes.title}`}>{t("share-now")}</div>
        )}
        <div className={`${classes.block}`}>
          <div className={`${classes.btnCard}`}>
            <Tooltip title={t("copy-link")} arrow>
              <button
                className={`btn ${classes.btn} ${classes.btnCopyClipboard}`}
                onClick={clipboard}>
                <Avatar
                  className={`img-fluid ${classes.imgBtn}`}
                  src={Copy}
                  alt={"Clipboard"}
                  width={1000}
                  height={1000}
                />
                {/* {copied ? t("copied") : t("copy-link")} */}
              </button>
            </Tooltip>
          </div>

          <div className={`${classes.btnCard}`}>
            <Tooltip title={t("facebook")} arrow>
              <button className={`btn ${classes.btn}`}>
                <FacebookShareButton url={url}>
                  <Avatar
                    className={`img-fluid ${classes.imgBtn}`}
                    src={Facebook}
                    alt={t("facebook")}
                    width={1000}
                    height={1000}
                  />
                </FacebookShareButton>
              </button>
            </Tooltip>
          </div>

          <div className={`${classes.btnCard}`}>
            <Tooltip title={t("twitter")} arrow>
              <button className={`btn ${classes.btn}`}>
                <TwitterShareButton url={url}>
                  <Avatar
                    className={`img-fluid ${classes.imgBtn}`}
                    src={Twitter}
                    alt={t("twitter")}
                    width={1000}
                    height={1000}
                  />
                </TwitterShareButton>
              </button>
            </Tooltip>
          </div>

          <div className={`${classes.btnCard}`}>
            <Tooltip title={t("whatsapp")} arrow>
              <button className={`btn ${classes.btn}`}>
                <WhatsappShareButton url={url}>
                  <Avatar
                    className={`img-fluid ${classes.imgBtn}`}
                    src={Whatsapp}
                    alt={t("whatsapp")}
                    width={1000}
                    height={1000}
                  />
                </WhatsappShareButton>
              </button>
            </Tooltip>
          </div>
        </div>
      </div>
    </>
  );
};
