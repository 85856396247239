import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import axiosInstance from "../interceptor/axiosInstance";

const initialState = {
  filterIds: {
    selected: false,
    mainCategoriesIds: null,
    priceRange: null,
    brandsIds: null,
    discountsIds: null,
    colorsIds: null,
    sizesIds: null,
    printSpeed: null,
    sensor: null,
    printQuality: null,
    connectivity: null,
    duplex: null,
    type: null,
    functions: null,
    monthlyDutyCycle: null,
    bagSize: null,
  },
  products: {
    data: null,
    paginate: null,
    pagePaginate: 1,
    loading: false,
    success: false,
  },
  filter: {
    categories: null,
    brands: null,
    discounts: null,
    attributes: {
      colors: null,
      sizes: null,

      printSpeed: null,
      sensor: null,
      printQuality: null,
      connectivity: null,
      duplex: null,
      type: null,
      functions: null,
      monthlyDutyCycle: null,
      bagSize: null,
    },
    loading: false,
    success: false,
  },
  categories: { data: null, loading: false, success: false },
  videoPromo: { data: null, loading: false, success: false },
};

// Fetch Products
// export const fetchProducts = createAsyncThunk(
//   "fetchProducts/storeCollection",
//   async (action) => {
//     let query = "";
//     if (action?.category && action?.categoryId) {
//       query = `&${action.category}=${action.categoryId}`;
//     }

//     const config = {
//       url: `products?paginate=10${query}`,
//       method: "GET",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//       },
//     };
//     return await axiosInstance(config)
//       .then((response) => response)
//       .catch((errors) => errors.response);
//   }
// );

export const fetchProducts = createAsyncThunk(
  "fetchProducts/storeCollection",
  async (action) => {
    let category = null;
    if (action.category === "c") {
      category = "mainCategoryId";
    } else if (action.category === "sc") {
      category = "subCategoryId";
    } else if (action.category === "s2c") {
      category = "sub2CategoryId";
    } else if (action.category === "b") {
      category = "brandId";
    } else {
      category = action.category;
    }

    const config = {
      url: `products?paginate=25`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      params: {
        [category]: action?.categoryId,
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

// Fetch Filter Options
export const fetchFilterOptions = createAsyncThunk(
  "fetchFilterOptions/storeCollection",
  async (action) => {
    let category = null;
    if (action.category === "c") {
      category = "mainCategoryId";
    } else if (action.category === "sc") {
      category = "subCategoryId";
    } else if (action.category === "s2c") {
      category = "sub2CategoryId";
    } else if (action.category === "b") {
      category = "brandId";
    } else {
      category = action.category;
    }

    const config = {
      url: `products/filterOptions?paginate=10`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      params: {
        [category]: action.categoryId,
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

// Fetch Products Filtered
export const fetchOptionsFiltered = createAsyncThunk(
  "fetchOptionsFiltered/storeCollection",
  async (action) => {
    let categoriesQuery = "";
    let brandsQuery = "";

    if (action?.categoriesId && action?.categoriesId.length > 0) {
      categoriesQuery = `&mainCategoriesIds=${action.categoriesId}`;
    }

    if (action?.brandsId && action?.brandsId.length > 0) {
      brandsQuery = `&brandsIds=${action.brandsId}`;
    }

    const config = {
      url: `products/filterOptions?paginate=10${categoriesQuery}${brandsQuery}`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

// Fetch Filtered Products
export const fetchFilteredProducts = createAsyncThunk(
  "fetchFilteredProducts/storeCollection",
  async (action) => {
    const {
      colorsIds,
      sizesIds,
      printSpeed,
      sensor,
      printQuality,
      connectivity,
      duplex,
      type,
      functions,
      monthlyDutyCycle,
      bagSize,
    } = action;

    let category = null;
    // let priceRangeValues = null;
    // let mainCategoriesIds = null;
    // let mainCategoryId = null;
    // let brandsIds = null;
    // let brandId = null;
    // let offerId = null;
    // let offersIds = null;
    // let attributeIds = null;

    if (action.category === "c") {
      category = "mainCategoryId";
    } else if (action.category === "sc") {
      category = "subCategoryId";
    } else if (action.category === "s2c") {
      category = "sub2CategoryId";
    } else if (action.category === "b") {
      category = "brandId";
    } else {
      category = action.category;
    }

    const concat = (...arrays) => [].concat(...arrays.filter(Array.isArray));
    const concated = concat(
      colorsIds,
      sizesIds,
      printSpeed,
      sensor,
      printQuality,
      connectivity,
      duplex,
      type,
      functions,
      monthlyDutyCycle,
      bagSize
    )?.join(",");

    const config = {
      url: `products?paginate=25`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        page: action?.page,
      },
      params: {
        priceRange: action?.priceRange?.join("-"),
        page: action?.page,
        mainCategoriesIds:
          action?.mainCategoriesIds?.length > 1
            ? `[${action?.mainCategoriesIds?.join(",")}]`
            : null,
        mainCategoryId:
          action?.mainCategoriesIds?.length === 1
            ? action?.mainCategoriesIds?.join(",")
            : null,

        brandsIds:
          action?.brandsIds?.length > 1
            ? `[${action?.brandsIds?.join(",")}]`
            : null,
        brandId:
          action?.brandsIds?.length === 1 ? action?.brandsIds?.join(",") : null,

        offersIds:
          action?.discountsIds?.length > 1
            ? `[${action?.discountsIds?.join(",")}]`
            : null,
        offerId:
          action?.discountsIds?.length === 1
            ? action?.discountsIds?.join(",")
            : null,

        attributesIds: concated?.length ? `[${concated}]` : null,
        [category]:
          category === "brandId" && action?.brandsIds?.length > 1
            ? null
            : category === "brandId" && action?.brandsIds?.length === 1
            ? action?.brandsIds?.join(",")
            : action?.categoryId,
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

// // Fetch Attributes
// export const fetchAttributes = createAsyncThunk(
//   "fetchAttributes/storeCollection",
//   async (action) => {
//     const config = {
//       url: `products/filterOptions`,
//       method: "GET",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//       },
//     };
//     return await axiosInstance(config)
//       .then((response) => response)
//       .catch((errors) => errors.response);
//   }
// );

// Fetch Categories By Parent Id
export const fetchCategoriesForParent = createAsyncThunk(
  "fetchCategoriesForParent/storeCollection",
  async (action) => {
    const config = {
      url: `categories?paginate=40`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      params: {
        parentId: action?.parentId,
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

// Fetch Video Promo
export const fetchVideoPromo = createAsyncThunk(
  "fetchVideoPromo/storeCollection",
  async (action) => {
    const config = {
      url: `tvpromos/${action?.rowId}`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

const storeCollectionReducer = createSlice({
  name: "storeCollection",
  initialState,
  reducers: {
    handlePagePagination: (state, action) => {
      state.products.pagePaginate = action.payload.page;
    },
    onFilterIds: (state, action) => {
      console.log(action?.payload);
      console.log(state.filterIds);
      state.filterIds.selected = true;
      const objName = Object.keys(action?.payload)[0];
      state.filterIds[objName] = action?.payload[objName];
    },
  },
  extraReducers: (builder) => {
    /********** Fetch Products **********/
    builder.addCase(fetchProducts.pending, (state, action) => {
      state.products.loading = true;
      state.products.data = null;
    });
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.products.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        state.products.data = payload?.data?.data?.rows;
        state.products.paginate = payload?.data?.data?.paginate;
        state.products.success = true;

        const pageIfFound = action?.meta?.arg?.page;
        if (pageIfFound) {
          state.products.pagePaginate = pageIfFound;
        } else {
          state.products.pagePaginate = 1;
        }
      }
    });
    builder.addCase(fetchProducts.rejected, (state, action) => {
      state.products.loading = false;
      state.products.data = null;
    });
    /********** Fetch Products **********/

    /********** Fetch Filter Options **********/
    builder.addCase(fetchFilterOptions.pending, (state, action) => {
      state.filter.loading = true;
      state.filter.categories = null;
      state.filter.brands = null;
      state.filter.discounts = null;
      state.filter.attributes.colors = null;
      state.filter.attributes.sizes = null;
    });
    builder.addCase(fetchFilterOptions.fulfilled, (state, action) => {
      state.filter.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        const data = payload?.data?.data?.row;
        state.filter.categories = data?.categories;
        state.filter.brands = data?.brands;
        state.filter.discounts = data?.discounts;
        state.filter.success = true;

        if (data?.attributes) {
          // Sizes
          let mergedArraySizes = [];
          const sizesFiltered = (array, value) => {
            const filteredArraySizes = data?.attributes?.map((row, index) => {
              if (row.title === "Size") {
                return (mergedArraySizes = [
                  ...mergedArraySizes,
                  ...row.childs,
                ]);
              }
            });
            return filteredArraySizes;
          };

          sizesFiltered();

          if (mergedArraySizes) {
            state.filter.attributes.sizes = mergedArraySizes;
          } else {
            state.filter.attributes.sizes = null;
          }
          // Sizes

          // Colors
          let mergedArrayColors = [];
          const colorsFiltered = (array, value) => {
            const filteredArrayColors = data?.attributes?.map((row, index) => {
              if (row.title === "Color") {
                return (mergedArrayColors = [
                  ...mergedArrayColors,
                  ...row.childs,
                ]);
              }
            });
            return filteredArrayColors;
          };

          colorsFiltered();

          if (mergedArrayColors) {
            state.filter.attributes.colors = mergedArrayColors;
            console.log(mergedArrayColors);
          } else {
            state.filter.attributes.colors = null;
          }
          // Colors

          // Print Speed
          let mergedArrayPrintSpeed = [];
          const printSpeedFiltered = (array, value) => {
            const filteredArrayPrintSpeed = data?.attributes?.map(
              (row, index) => {
                if (row.title === "Print Speed") {
                  return (mergedArrayPrintSpeed = [
                    ...mergedArrayPrintSpeed,
                    ...row.childs,
                  ]);
                }
              }
            );
            return filteredArrayPrintSpeed;
          };

          printSpeedFiltered();

          if (mergedArrayPrintSpeed) {
            state.filter.attributes.printSpeed = mergedArrayPrintSpeed;
          } else {
            state.filter.attributes.printSpeed = null;
          }
          // Print Speed

          // Sensor
          let mergedArraySensor = [];
          const sensorFiltered = (array, value) => {
            const filteredArraySensor = data?.attributes?.map((row, index) => {
              if (row.title === "Sensor") {
                return (mergedArraySensor = [
                  ...mergedArraySensor,
                  ...row.childs,
                ]);
              }
            });
            return filteredArraySensor;
          };

          sensorFiltered();

          if (mergedArraySensor) {
            state.filter.attributes.sensor = mergedArraySensor;
          } else {
            state.filter.attributes.sensor = null;
          }
          // Sensor

          // Print Quality
          let mergedArrayPrintQuality = [];
          const printQualityFiltered = (array, value) => {
            const filteredArrayPrintQuality = data?.attributes?.map(
              (row, index) => {
                if (row.title === "Print Quality") {
                  return (mergedArrayPrintQuality = [
                    ...mergedArrayPrintQuality,
                    ...row.childs,
                  ]);
                }
              }
            );
            return filteredArrayPrintQuality;
          };

          printQualityFiltered();

          if (mergedArrayPrintQuality) {
            state.filter.attributes.printQuality = mergedArrayPrintQuality;
          } else {
            state.filter.attributes.printQuality = null;
          }
          // Print Quality

          // Connectivity
          let mergedArrayConnectivity = [];
          const connectivityFiltered = (array, value) => {
            const filteredArrayConnectivity = data?.attributes?.map(
              (row, index) => {
                if (row.title === "Connectivity") {
                  return (mergedArrayConnectivity = [
                    ...mergedArrayConnectivity,
                    ...row.childs,
                  ]);
                }
              }
            );
            return filteredArrayConnectivity;
          };

          connectivityFiltered();

          if (mergedArrayConnectivity) {
            state.filter.attributes.connectivity = mergedArrayConnectivity;
          } else {
            state.filter.attributes.connectivity = null;
          }
          // Connectivity

          // Duplex
          let mergedArrayDuplex = [];
          const duplexFiltered = (array, value) => {
            const filteredArrayDuplex = data?.attributes?.map((row, index) => {
              if (row.title === "Duplex") {
                return (mergedArrayDuplex = [
                  ...mergedArrayDuplex,
                  ...row.childs,
                ]);
              }
            });
            return filteredArrayDuplex;
          };

          duplexFiltered();

          if (mergedArrayDuplex) {
            state.filter.attributes.duplex = mergedArrayDuplex;
          } else {
            state.filter.attributes.duplex = null;
          }
          // Duplex

          // Type
          let mergedArrayType = [];
          const typeFiltered = (array, value) => {
            const filteredArrayType = data?.attributes?.map((row, index) => {
              if (row.title === "Type") {
                return (mergedArrayType = [...mergedArrayType, ...row.childs]);
              }
            });
            return filteredArrayType;
          };

          typeFiltered();

          if (mergedArrayType) {
            state.filter.attributes.type = mergedArrayType;
          } else {
            state.filter.attributes.type = null;
          }
          // Type

          // Functions
          let mergedArrayFunctions = [];
          const functionsFiltered = (array, value) => {
            const filteredArrayFunctions = data?.attributes?.map(
              (row, index) => {
                if (row.title === "Functions") {
                  return (mergedArrayFunctions = [
                    ...mergedArrayFunctions,
                    ...row.childs,
                  ]);
                }
              }
            );
            return filteredArrayFunctions;
          };

          functionsFiltered();

          if (mergedArrayFunctions) {
            state.filter.attributes.functions = mergedArrayFunctions;
          } else {
            state.filter.attributes.functions = null;
          }
          // Functions

          // Monthly Duty Cycle
          let mergedArrayMonthlyDutyCycle = [];
          const monthlyDutyCycleFiltered = (array, value) => {
            const filteredArrayMonthlyDutyCycle = data?.attributes?.map(
              (row, index) => {
                if (row.title === "Monthly Duty Cycle") {
                  return (mergedArrayMonthlyDutyCycle = [
                    ...mergedArrayMonthlyDutyCycle,
                    ...row.childs,
                  ]);
                }
              }
            );
            return filteredArrayMonthlyDutyCycle;
          };

          monthlyDutyCycleFiltered();

          if (mergedArrayMonthlyDutyCycle) {
            state.filter.attributes.monthlyDutyCycle =
              mergedArrayMonthlyDutyCycle;
          } else {
            state.filter.attributes.monthlyDutyCycle = null;
          }
          // Monthly Duty Cycle

          // Bag Size
          let mergedArrayBagSize = [];
          const bagSizeFiltered = (array, value) => {
            const filteredArrayBagSize = data?.attributes?.map((row, index) => {
              if (row.title === "Bag Size") {
                return (mergedArrayBagSize = [
                  ...mergedArrayBagSize,
                  ...row.childs,
                ]);
              }
            });
            return filteredArrayBagSize;
          };

          bagSizeFiltered();

          if (mergedArrayBagSize) {
            state.filter.attributes.bagSize = mergedArrayBagSize;
          } else {
            state.filter.attributes.bagSize = null;
          }
          // Bag Size
        }
      }
    });
    builder.addCase(fetchFilterOptions.rejected, (state, action) => {
      state.filter.loading = false;
    });
    /********** Fetch Filter Options **********/

    /********** Fetch Options Filtered **********/
    builder.addCase(fetchOptionsFiltered.pending, (state, action) => {
      state.filter.loading = true;
      state.filter.categories = null;
      state.filter.brands = null;
      state.filter.discounts = null;
      state.filter.attributes.colors = null;
      state.filter.attributes.sizes = null;
    });
    builder.addCase(fetchOptionsFiltered.fulfilled, (state, action) => {
      state.filter.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        const data = payload?.data?.data?.row;
        state.filter.categories = data?.categories;
        state.filter.brands = data?.brands;
        state.filter.discounts = data?.discounts;
        state.filter.success = true;
        state.products.pagePaginate = 1;

        if (data?.attributes) {
          // const colors = data?.attributes.find(
          //   (item) => item.title === "Color"
          // );
          // if (colors) {
          //   state.filter.attributes.colors = colors?.childs;
          // }

          // const sizes = data?.attributes.find((item) => item.title === "Size");
          // const newSizes = data?.attributes.filter((obj) => {
          //   return Object.values(obj).includes("Size");
          // });

          // if (sizes) {
          //   state.filter.attributes.sizes = sizes?.childs;
          // }

          // Sizes
          let mergedArraySizes = [];
          const sizesFiltered = (array, value) => {
            const filteredArraySizes = data?.attributes?.map((row, index) => {
              if (row.title === "Size") {
                return (mergedArraySizes = [
                  ...mergedArraySizes,
                  ...row.childs,
                ]);
              }
            });
            return filteredArraySizes;
          };

          sizesFiltered();

          if (mergedArraySizes) {
            state.filter.attributes.sizes = mergedArraySizes;
          } else {
            state.filter.attributes.sizes = null;
          }

          // Colors
          let mergedArrayColors = [];
          const colorsFiltered = (array, value) => {
            const filteredArrayColors = data?.attributes?.map((row, index) => {
              if (row.title === "Color") {
                return (mergedArrayColors = [
                  ...mergedArrayColors,
                  ...row.childs,
                ]);
              }
            });
            return filteredArrayColors;
          };

          colorsFiltered();

          if (mergedArrayColors) {
            state.filter.attributes.colors = mergedArrayColors;
            console.log(mergedArrayColors);
          } else {
            state.filter.attributes.colors = null;
          }
        }
      }
    });
    builder.addCase(fetchOptionsFiltered.rejected, (state, action) => {
      state.filter.loading = true;
      state.filter.categories = null;
      state.filter.brands = null;
      state.filter.discounts = null;
      state.filter.attributes.colors = null;
      state.filter.attributes.sizes = null;
    });
    /********** Fetch Options Filtered **********/

    /********** Fetch Filtered Products **********/
    builder.addCase(fetchFilteredProducts.pending, (state, action) => {
      state.products.loading = true;
      state.products.data = null;
    });
    builder.addCase(fetchFilteredProducts.fulfilled, (state, action) => {
      state.products.loading = false;
      console.log(state, action);
      const payload = action.payload;
      if (payload?.status === 200) {
        state.products.data = payload?.data?.data?.rows;
        state.products.success = true;
        state.products.paginate = payload?.data?.data?.paginate;
        state.products.pagePaginate =
          payload?.data?.data?.paginate?.current_page;

        // const pageIfFound = action?.meta?.arg?.page;
        // if (pageIfFound) {
        //   state.products.pagePaginate = pageIfFound;
        // } else {
        //   state.products.pagePaginate = 1;
        // }
      }
    });
    builder.addCase(fetchFilteredProducts.rejected, (state, action) => {
      state.products.loading = false;
      state.products.data = null;
    });
    /********** Fetch Filtered Products **********/

    /********** Fetch Categories By Parent Id **********/
    builder.addCase(fetchCategoriesForParent.pending, (state, action) => {
      state.categories.loading = true;
      state.categories.data = null;
    });
    builder.addCase(fetchCategoriesForParent.fulfilled, (state, action) => {
      state.categories.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        state.categories.data = payload?.data?.data?.rows;
        // state.categories.paginate = payload?.data?.data?.paginate;
        state.categories.success = true;
      }
    });
    builder.addCase(fetchCategoriesForParent.rejected, (state, action) => {
      state.categories.loading = false;
      state.categories.data = null;
    });
    /********** Fetch Categories By Parent Id **********/

    /********** Fetch Video Promo **********/
    builder.addCase(fetchVideoPromo.pending, (state, action) => {
      state.videoPromo.loading = true;
    });
    builder.addCase(fetchVideoPromo.fulfilled, (state, action) => {
      state.videoPromo.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        state.videoPromo.data = payload?.data?.data?.row;
        state.videoPromo.success = true;
      }
    });
    builder.addCase(fetchVideoPromo.rejected, (state, action) => {
      state.videoPromo.loading = false;
    });
    /********** Fetch Video Promo **********/
  },
});

export const { onSortGrid, handlePagePagination, onFilterIds } =
  storeCollectionReducer.actions;

export default storeCollectionReducer.reducer;
