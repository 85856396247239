import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import axiosInstance from "../interceptor/axiosInstance";

const initialState = {
  categories: { data: [], loading: false, success: false },
  categoriesByParenId: { data: [], loading: false, success: false },
  provinces: { data: [], loading: false, success: false },
  cities: { data: [], loading: false, success: false },
  appSettings: {
    data: null,
    socialMediaData: { data: null, whatsAppNumber: null },
    homeStructureData: null,
    loading: false,
    success: false,
  },
  banners: { data: null, loading: false, success: false },
  pages: { data: null, loading: false, success: false },
};

// Fetch Categories
export const fetchCategories = createAsyncThunk(
  "fetchCategories/general",
  async (action) => {
    const config = {
      url: `categories`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

// Fetch Categories By Parent Id
export const fetchCategoriesByParenId = createAsyncThunk(
  "fetchCategoriesByParenId/categories",
  async (action) => {
    const config = {
      url: `categories?paginate=30`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      params: {
        parentId: action?.parentId,
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

// Fetch Provinces
export const fetchProvinces = createAsyncThunk(
  "fetchProvinces/general",
  async (action) => {
    const config = {
      url: `settings/shipping_addresses`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

// Fetch Cities
export const fetchCities = createAsyncThunk(
  "fetchCities/general",
  async (action) => {
    const config = {
      url: `settings/shipping_addresses?parentId=${action.rowId}`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

// Fetch App Settings
export const fetchAppSettings = createAsyncThunk(
  "fetchAppSettings/general",
  async (action) => {
    const config = {
      url: `appSettings`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        enOnly: true,
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

// Fetch Banners
export const fetchBanners = createAsyncThunk(
  "fetchBanners/general",
  async (action) => {
    const config = {
      url: `superDeals`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        lang: false,
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

// Fetch Pages
export const fetchPages = createAsyncThunk(
  "fetchPages/general",
  async (action) => {
    const config = {
      url: `pages`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        lang: false,
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

const generalReducer = createSlice({
  name: "general",
  initialState,
  reducers: {
    closeModal: (state) => {
      state.add.success = false;
      state.update.success = false;
      state.delete.success = false;
    },
  },
  extraReducers: (builder) => {
    /********** Fetch Categories **********/
    builder.addCase(fetchCategories.pending, (state, action) => {
      state.categories.loading = true;
    });
    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      state.categories.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        state.categories.data = payload?.data?.data?.rows;
      }
    });
    builder.addCase(fetchCategories.rejected, (state, action) => {
      state.categories.loading = false;
    });
    /********** Fetch Categories **********/

    /********** Fetch Categories By Parent Id **********/
    builder.addCase(fetchCategoriesByParenId.pending, (state, action) => {
      state.categoriesByParenId.loading = true;
      state.categoriesByParenId.data = null;
    });
    builder.addCase(fetchCategoriesByParenId.fulfilled, (state, action) => {
      state.categoriesByParenId.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        state.categoriesByParenId.data = payload?.data?.data?.rows;
        // state.categoriesByParenId.paginate = payload?.data?.data?.paginate;
        state.categoriesByParenId.success = true;
      }
    });
    builder.addCase(fetchCategoriesByParenId.rejected, (state, action) => {
      state.categoriesByParenId.loading = false;
      state.categoriesByParenId.data = null;
    });
    /********** Fetch Categories By Parent Id **********/

    /********** Fetch Provinces **********/
    builder.addCase(fetchProvinces.pending, (state, action) => {
      state.provinces.loading = true;
    });
    builder.addCase(fetchProvinces.fulfilled, (state, action) => {
      state.provinces.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        state.provinces.data = payload?.data?.data?.rows;
      }
    });
    builder.addCase(fetchProvinces.rejected, (state, action) => {
      state.provinces.loading = false;
    });
    /********** Fetch Provinces **********/

    /********** Fetch Provinces **********/
    builder.addCase(fetchCities.pending, (state, action) => {
      state.cities.loading = true;
    });
    builder.addCase(fetchCities.fulfilled, (state, action) => {
      state.cities.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        state.cities.data = payload?.data?.data?.rows;
      }
    });
    builder.addCase(fetchCities.rejected, (state, action) => {
      state.cities.loading = false;
    });
    /********** Fetch Provinces **********/

    /********** Fetch App Settings **********/
    builder.addCase(fetchAppSettings.pending, (state, action) => {
      state.appSettings.loading = true;
    });
    builder.addCase(fetchAppSettings.fulfilled, (state, action) => {
      state.appSettings.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        const data = payload?.data?.data;
        console.log(data);
        state.appSettings.data = data;
        state.appSettings.homeStructureData =
          data?.appStyle?.homeStyle?.homeStructure;
        state.appSettings.socialMediaData.data = data.socialMedia;
        const whatsApp = data?.socialMedia.find(
          (item) => item.title.toLowerCase() === "whatsapp"
        );
        if (whatsApp) {
          state.appSettings.socialMediaData.whatsAppNumber = whatsApp.value;
        }
      }
    });
    builder.addCase(fetchAppSettings.rejected, (state, action) => {
      state.appSettings.loading = false;
    });
    /********** Fetch App Settings **********/

    /********** Fetch Banners **********/
    builder.addCase(fetchBanners.pending, (state, action) => {
      state.banners.loading = true;
    });
    builder.addCase(fetchBanners.fulfilled, (state, action) => {
      state.banners.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        state.banners.data = payload?.data?.data?.rows;
      }
    });
    builder.addCase(fetchBanners.rejected, (state, action) => {
      state.banners.loading = false;
    });
    /********** Fetch Banners **********/

    /********** Fetch Banners **********/
    builder.addCase(fetchPages.pending, (state, action) => {
      state.pages.loading = true;
    });
    builder.addCase(fetchPages.fulfilled, (state, action) => {
      state.pages.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        state.pages.data = payload?.data?.data?.rows;
      }
    });
    builder.addCase(fetchPages.rejected, (state, action) => {
      state.pages.loading = false;
    });
    /********** Fetch Banners **********/
  },
});

// export const { closeModal } = generalReducer.actions;

export default generalReducer.reducer;
