import React, { useState } from "react";
import classes from "./SliderProducts.module.css";
import { NavLink } from "react-router-dom";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  EffectFade,
} from "swiper";
import {
  Avatar,
  CircleSpinner,
  EmptyProductsMsg,
  Product,
  ProductCard,
} from "@components";
import { useTranslation } from "react-i18next";

export const SliderProducts = ({
  title,
  viewAll,
  index,
  items,
  loading,
  noContainer,
}) => {
  const { t, i18n } = useTranslation();
  const breakpoints = {
    400: {
      slidesPerView: 1.2,
    },
    550: {
      slidesPerView: 1.5,
    },
    576: {
      slidesPerView: 1.6,
    },
    768: {
      slidesPerView: 2,
    },
    992: {
      slidesPerView: 2.6,
    },
    1200: {
      slidesPerView: 3.3,
    },
    1400: {
      slidesPerView: 4,
    },
  };

  return (
    <>
      <div className={`${classes.container}`} data-aos="fade-up">
        <div className={`${noContainer ? "" : "container-fluid"}`}>
          <div className={classes.block}>
            <div className={`title-container pb-4`}>
              <div className={`title`}>{title}</div>
            </div>

            {loading ? (
              <CircleSpinner className={"pb-5"} size="lg" />
            ) : items?.length ? (
              <div className={classes.grid}>
                <Swiper
                  dir={i18n.language === "ar" ? "rtl" : "ltr"}
                  className={classes.swiperHeader}
                  modules={[Navigation, Scrollbar, A11y]} // Pagination
                  spaceBetween={20}
                  // slidesPerView={4}
                  navigation={{
                    prevEl: `.btn-arrow-prev-${index}`,
                    nextEl: `.btn-arrow-next-${index}`,
                  }}
                  // pagination={{ clickable: true }}
                  // loop={false}
                  // autoplay={{
                  //   delay: 3000,
                  //   disableOnInteraction: false,
                  // }}
                  breakpoints={breakpoints}
                  speed={1000}>
                  {items &&
                    items?.map((row, index) => {
                      return (
                        <SwiperSlide
                          key={row?.id}
                          className={classes.swiperSlide}>
                          <ProductCard row={row} />
                        </SwiperSlide>
                      );
                    })}
                </Swiper>

                <div className="arrow-group">
                  <div
                    className={`${classes.btnArrowLeft} btn-arrow btn-arrow-left btn-arrow-prev-${index}`}>
                    <span className="icon-chevron-left icon"></span>
                  </div>

                  <div
                    className={`${classes.btnArrowRight} btn-arrow btn-arrow-right btn-arrow-next-${index}`}>
                    <span className="icon-chevron-right icon"></span>
                  </div>
                </div>

                {/* {viewAll ? (
                  <div className={classes.action}>
                    <Button
                      component={Link}
                      to="/store/products"
                      size="large"
                      color="primary"
                      variant="contained">
                      {t("view-all-products")}
                    </Button>
                  </div>
                ) : (
                  ""
                )} */}
              </div>
            ) : (
              <EmptyProductsMsg text={t("no-data-description")} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
