import React, { useState, useEffect } from "react";
import classes from "./CartAttributes.module.css";

export const CartAttributes = ({ row }) => {
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);

  useEffect(() => {
    if (row?.selectedAttributes) {
      const enteredColor = row?.selectedAttributes?.find(
        (item) => item?.name === "Color"
      );
      if (enteredColor) {
        setSelectedColor(enteredColor?.selected?.name?.match(/#[^\s]+/g));
      }

      const enteredSize = row?.selectedAttributes?.find(
        (item) => item?.name === "Size"
      );
      if (enteredSize) {
        setSelectedSize(enteredSize?.selected?.name);
      }
    }
  }, [row]);

  return selectedColor || selectedSize ? (
    <div className={`mt-1`}>
      {selectedColor ? (
        <span
          className={`${classes.colorSelected}`}
          style={{ background: selectedColor }}></span>
      ) : (
        ""
      )}

      {selectedSize ? (
        <span className={`${classes.sizeSelected}`}>{selectedSize}</span>
      ) : (
        ""
      )}
    </div>
  ) : (
    ""
  );
};
