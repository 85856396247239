import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import toast from "react-hot-toast";
import i18next from "i18next";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  // baseURL: "https://api.briggekw.com/api/v1",
});

const MySwal = withReactContent(Swal);

// Request
axiosInstance.interceptors.request.use(
  (config) => {
    config.headers["appId"] = process.env.REACT_APP_APPID;
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    const enOnly = config?.headers?.enOnly;

    config.params = {
      ...config.params,
      locale: enOnly ? null : i18next.language,
    };
    // if (config.data && !config.data.lang) {
    //   config.url = config.url;
    // } else {
    //   config.url = config.url.includes("?")
    //     ? `${config.url}&locale=${i18next.language}`
    //     : `${config.url}?locale=${i18next.language}`;
    // }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response
axiosInstance.interceptors.response.use(
  (response) => {
    // console.log(response);
    const { status } = response;
    if (status == 200) {
      if (response.data?.data?.message) {
        toast.success(response.data?.data?.message, {
          duration: 5000,
        });
      }
    }
    return response;
  },
  (error) => {
    // console.log(error);
    if (error?.response?.data?.data?.message) {
      toast.error(error?.response?.data?.data?.message);
    }

    const { status } = error.response;

    switch (status) {
      case 401:
        const token = localStorage.getItem("token");
        if (token) {
          localStorage.removeItem("token");
          window.location.href = "/auth/login";
        }
        // toast.error(error.response.data.data.message);
        break;

      case 404:
        MySwal.fire({
          title: "",
          text: error.response?.data?.message ?? `Something wrong 404 !!`,
          icon: "error",
          showCancelButton: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ms-1",
          },
          buttonsStyling: false,
        });
        break;

      // case 500:
      //   MySwal.fire({
      //     title: "",
      //     text: error.response?.data?.message ?? `Something wrong 500 !!`,
      //     icon: "error",
      //     showCancelButton: false,
      //     confirmButtonText: "Ok",
      //     customClass: {
      //       confirmButton: "btn btn-primary",
      //       cancelButton: "btn btn-outline-danger ms-1",
      //     },
      //     buttonsStyling: false,
      //   });
      //   break;

      default:
        return Promise.reject(error);
    }
  }
);

export default axiosInstance;
