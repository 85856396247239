import React, { useState } from "react";
import classes from "./Paginate.module.css";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Directions } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export const Paginate = ({ className, count, onPage, page }) => {
  const { i18n } = useTranslation();
  const handleChange = (event, value) => {
    onPage(value);
  };

  const paginationStyle = {
    "& .MuiPaginationItem-root": {
      borderRadius: 0,
      margin: "0 4px",
      borderBottom: "2px solid transparent",
      color: "#999",
      fontSize: "16px",
      padding: "12px",
    },
    "& .Mui-selected": {
      backgroundColor: "transparent !important",
      color: "#000000 !important",
      borderBottom: "2px solid #000000",
    },
    "& .MuiPagination-ul": {
      display: "flex",
      justifyContent: "center",
      // flexDirection: "row-reverse",
      direction: i18n.language === "ar" ? "rtl" : "ltr",
    },
  };

  return (
    <div className={`${classes.container} ${className ?? ""} mt-3`}>
      <Stack spacing={2}>
        <Pagination
          count={count}
          page={page}
          onChange={handleChange}
          sx={paginationStyle}
        />
      </Stack>
    </div>
  );
};
