import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import axiosInstance from "../interceptor/axiosInstance";

const initialState = {
  product: {
    data: null,
    loading: false,
    success: false,
    sizes: null,
    sizeTypeId: null,
    colors: null,
    colorTypeId: null,
  },
  relatedProducts: { data: null, loading: false, success: false },
};

// Fetch Product
export const fetchProduct = createAsyncThunk(
  "fetchProduct/product",
  async (action) => {
    const config = {
      url: `products/${action.rowId}`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      enOnly: true,
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

// Related Product
export const fetchRelatedProducts = createAsyncThunk(
  "fetchRelatedProducts/product",
  async (action) => {
    const config = {
      url: `products/${action.rowId}/related`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

const productReducer = createSlice({
  name: "product",
  initialState,
  reducers: {
    clearState: (state) => {
      state.product.success = false;
    },
  },
  extraReducers: (builder) => {
    /********** Fetch Product **********/
    builder.addCase(fetchProduct.pending, (state, action) => {
      state.product.loading = true;
    });
    builder.addCase(fetchProduct.fulfilled, (state, action) => {
      state.product.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        const data = payload?.data?.data?.row;
        state.product.data = data;
        state.product.success = true;

        if (data?.attributes) {
          const sizes = data?.attributes.find((item) => item.title === "Size");
          if (sizes) {
            state.product.sizeTypeId = sizes?.id;
            state.product.sizes = sizes?.childs;
          }
        }

        if (data?.attributes) {
          const colors = data?.attributes.find(
            (item) => item.title === "Color"
          );
          if (colors) {
            state.product.colorTypeId = colors?.id;
            state.product.colors = colors?.childs;
          }
        }
      }
    });
    builder.addCase(fetchProduct.rejected, (state, action) => {
      state.product.loading = false;
    });
    /********** Fetch Product **********/

    /********** Related Products **********/
    builder.addCase(fetchRelatedProducts.pending, (state, action) => {
      state.relatedProducts.loading = true;
    });
    builder.addCase(fetchRelatedProducts.fulfilled, (state, action) => {
      state.relatedProducts.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        state.relatedProducts.data = payload?.data?.data?.rows;
        state.relatedProducts.success = true;
      }
    });
    builder.addCase(fetchRelatedProducts.rejected, (state, action) => {
      state.relatedProducts.loading = false;
    });
    /********** Related Products **********/
  },
});

export const { clearState } = productReducer.actions;

export default productReducer.reducer;
