import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import axiosInstance from "../interceptor/axiosInstance";

const initialState = {
  tvPromos: {
    data: null,
    loading: false,
    success: false,
  },
  sliders: {
    data: null,
    loading: false,
    success: false,
  },
  categories: {
    data: null,
    loading: false,
    success: false,
  },
  products: {
    data: null,
    loading: false,
    success: false,
  },
  brands: {
    data: null,
    loading: false,
    success: false,
  },
};

// Fetch Promos
export const fetchTvPromos = createAsyncThunk(
  "fetchTvPromos/discounts",
  async (action) => {
    const config = {
      url: `tvpromos?paginate=100`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

// Fetch Sliders
export const fetchSliders = createAsyncThunk(
  "fetchSliders/discounts",
  async (action) => {
    const config = {
      url: `sliders?paginate=100`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

// Fetch Categories
export const fetchCategories = createAsyncThunk(
  "fetchCategories/discounts",
  async (action) => {
    const config = {
      url: `categories/sale?paginate=100`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

// Fetch Brands
export const fetchBrands = createAsyncThunk(
  "fetchBrands/discounts",
  async (action) => {
    const config = {
      url: `brands/sale?paginate=100`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

// Fetch Products
export const fetchProducts = createAsyncThunk(
  "fetchProducts/discounts",
  async (action) => {
    const config = {
      url: `products/sale?paginate=100`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

const discountsReducer = createSlice({
  name: "discounts",
  initialState,
  reducers: {
    clearState: (state, action) => {
      state.tvPromos.success = false;
      state.sliders.success = false;
      state.categories.success = false;
      state.products.success = false;
      state.brands.success = false;
    },
  },
  extraReducers: (builder) => {
    /********** Fetch Tv Promos **********/
    builder.addCase(fetchTvPromos.pending, (state, action) => {
      state.tvPromos.loading = true;
    });
    builder.addCase(fetchTvPromos.fulfilled, (state, action) => {
      state.tvPromos.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        state.tvPromos.data = payload?.data?.data?.rows;
        state.tvPromos.success = true;
      }
    });
    builder.addCase(fetchTvPromos.rejected, (state, action) => {
      state.tvPromos.loading = false;
    });
    /********** Fetch Tv Promos **********/

    /********** Fetch Sliders **********/
    builder.addCase(fetchSliders.pending, (state, action) => {
      state.sliders.loading = true;
    });
    builder.addCase(fetchSliders.fulfilled, (state, action) => {
      state.sliders.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        state.sliders.data = payload?.data?.data?.rows;
        state.sliders.success = true;
      }
    });
    builder.addCase(fetchSliders.rejected, (state, action) => {
      state.sliders.loading = false;
    });
    /********** Fetch Sliders **********/

    /********** Fetch Categories **********/
    builder.addCase(fetchCategories.pending, (state, action) => {
      state.categories.loading = true;
    });
    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      state.categories.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        state.categories.data = payload?.data?.data?.rows;
        state.categories.success = true;
      }
    });
    builder.addCase(fetchCategories.rejected, (state, action) => {
      state.categories.loading = false;
    });
    /********** Fetch Categories **********/

    /********** Fetch Brands **********/
    builder.addCase(fetchBrands.pending, (state, action) => {
      state.brands.loading = true;
    });
    builder.addCase(fetchBrands.fulfilled, (state, action) => {
      state.brands.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        state.brands.data = payload?.data?.data?.rows;
        state.brands.success = true;
      }
    });
    builder.addCase(fetchBrands.rejected, (state, action) => {
      state.brands.loading = false;
    });
    /********** Fetch Brands **********/

    /********** Fetch Products **********/
    builder.addCase(fetchProducts.pending, (state, action) => {
      state.categories.loading = true;
    });
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.products.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        state.products.data = payload?.data?.data?.rows;
        state.products.success = true;
      }
    });
    builder.addCase(fetchProducts.rejected, (state, action) => {
      state.products.loading = false;
    });
    /********** Fetch Products **********/
  },
});

export const { clearState } = discountsReducer.actions;

export default discountsReducer.reducer;
