import React, { useEffect, useState } from "react";
import classes from "./Addresses.module.css";
import { AddressCard } from "../AddressCard/AddressCard";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { EmptyProductsMsg } from "@components";
import { useTranslation } from "react-i18next";

/** Redux **/
import { useDispatch, useSelector } from "react-redux";
import { fetchAddresses, clearState } from "@store/user";

export const Addresses = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { data, success } = useSelector((state) => state.user.addresses);
  const [address, setAddress] = useState(null);

  useEffect(() => {
    dispatch(fetchAddresses());
  }, [dispatch]);

  useEffect(() => {
    if (success) {
      const primary = data.find((item) => item?.isPrimary);
      setAddress(primary);
    }
    dispatch(clearState());
  }, [dispatch, data, success, setAddress]);

  return (
    <>
      {data?.length > 0 ? (
        address ? (
          <AddressCard row={address} />
        ) : (
          <EmptyProductsMsg
            className={"pt-0 pb-3"}
            text={t("there-are-no-primary-address-selected")}
          />
        )
      ) : (
        <EmptyProductsMsg
          className={"pt-0 pb-3"}
          text={t("no-data-description")}
        />
      )}
      <Button
        component={Link}
        to="/account/addresses"
        size="large"
        fullWidth
        color="primary"
        variant="outlined">
        {t("view-edit-addresses")}
      </Button>
    </>
  );
};
