import React, { useState, useEffect } from "react";
import { Routes, Route, Outlet } from "react-router";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import classes from "./Account.module.css";
import { jwt } from "@services";
import { Config } from "@config/config";
import { useTranslation } from "react-i18next";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import Avatar from "@mui/material/Avatar";
import { countries } from "@config/countries";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { Seo } from "@components";
import PhoneInput from "react-phone-input-2";

/** Redux **/
import { useDispatch, useSelector } from "react-redux";
import { userData, logout } from "@store/auth/auth";
import { updateProfile, clearState } from "@store/user";

/** Pages  **/
import { Orders } from "./Orders";

export const Info = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const jwToken = jwt();
  const [selectCountry, setSelectCountry] = useState(null);
  const [selectCountryCode, setSelectCountryCode] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [avatarImg, setAvatarImg] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const { data } = useSelector((state) => state.auth.userData);
  const { loading, success } = useSelector((state) => state.user.updateProfile);

  const schema = yup
    .object({
      fullname: yup
        .string()
        .min(3, t("Validations.full-name-too-short"))
        .required(t("Validations.full-name-required")),
      email: yup
        .string()
        .required(t("Validations.email-address-required"))
        .email(t("Validations.email-must-be-a-valid-email"))
        .min(6, t("Validations.email-too-short")),
      phoneNumber: yup
        .string()
        .min(3, t("Validations.phone-too-short"))
        .required(t("Validations.phone-required")),
    })
    .required();

  const defaultValues = {
    fullname: "",
    phoneNumber: "",
    email: "",
  };
  const {
    setError,
    clearErrors,
    reset,
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    if (userData) {
      setValue("fullname", data?.name);
      setValue("email", data?.email);
      setValue("phoneNumber", data?.mobile);
      setPhoneNumber(data?.mobile);
      // setSelectCountry(data?.countryCode ? data?.countryCode?.slice(1) : null);
    }
  }, [data, setValue]);

  const onSelectCountry = (e) => {
    setSelectCountry(e.target.value);
  };

  const onSelectCountryItem = (data) => {
    setSelectCountryCode(data.phone);
  };

  const onUploadAvatar = (e) => {
    e.preventDefault();
    setAvatarImg(e.target.files[0]);
    const reader = new FileReader(),
      files = e.target.files;
    reader.onload = function () {
      setAvatar(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const onSubmit = async (data) => {
    const newData = Object.assign(data, {
      // selectCountry,
      avatar,
    });
    dispatch(updateProfile(newData));
  };

  useEffect(() => {
    dispatch(userData());
  }, [dispatch, jwToken]);

  useEffect(() => {
    if (success) {
      dispatch(userData());
      dispatch(clearState());
    }
  }, [dispatch, success]);

  return (
    <>
      <Helmet>
        <title>{`${Config.name} | ${t("Profile.update-profile")}`}</title>
      </Helmet>
      <Seo title={`${t("Profile.update-profile")}`} />
      <div className={`${classes.infoForm}`}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            spacing={2}
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            wrap="nowrap">
            <Grid
              item
              xs={12}
              sm={10}
              lg={4}
              container
              direction="column"
              justify="center"
              alignItems="center">
              <div className={`${classes.avatar}`}>
                {avatar ? (
                  <Avatar
                    className={`${classes.avatarImage}`}
                    src={avatar ?? avatar}
                    // alt={"Avatar"}
                    // width={1000}
                    // height={1000}
                  />
                ) : data?.image ? (
                  <Avatar
                    className={`${classes.avatarImage}`}
                    src={data?.image}
                    // alt={"Avatar"}
                    // width={1000}
                    // height={1000}
                  />
                ) : (
                  <Avatar className={`${classes.avatarImage}`}>
                    {userData?.data?.name?.substring(0, 1)}
                  </Avatar>
                )}
                {/* {userData?.data?.image ? (
            <Image
              className={`${classes.avatarImage}`}
              src={avatar ? avatar : userData?.data?.image}
              alt={"Avatar"}
              width={1000}
              height={1000}
            />
          ) : (
            <Avatar className={`${classes.avatarImage}`}>
              {userData?.data?.name?.substring(0, 1)}
            </Avatar>
          )} */}
                <div className={`${classes.avatarIcon}`}>
                  <input
                    onChange={onUploadAvatar}
                    className={`${classes.avatarFile}`}
                    type="file"
                  />
                  <span className={`icon-edit ${classes.icon}`}></span>
                </div>
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              sm={10}
              lg={4}
              container
              direction="column"
              justify="center"
              alignItems="center"
              align="center">
              <Controller
                control={control}
                {...register("fullname")}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    helperText={
                      errors?.fullname ? errors?.fullname.message : null
                    }
                    size="small"
                    error={!!errors?.fullname}
                    onChange={onChange}
                    value={value}
                    fullWidth
                    label={t("full-name")}
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={10}
              lg={4}
              container
              direction="column"
              justify="center"
              alignItems="center"
              align="center">
              <Controller
                control={control}
                {...register("email")}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    helperText={errors?.email ? errors?.email.message : null}
                    size="small"
                    error={!!errors?.email}
                    onChange={onChange}
                    value={value}
                    fullWidth
                    label={t("email-address")}
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            {/* {data ? (
              <Grid
                item
                xs={12}
                sm={10}
                lg={4}
                container
                direction="column"
                justify="center"
                alignItems="center"
                align="center">
                <TextField
                  fullWidth
                  select
                  size="small"
                  value={selectCountry}
                  defaultValue={selectCountry}
                  label={t("choose-country")}
                  InputLabelProps={{ shrink: true }}
                  onChange={onSelectCountry}>
                  {countries?.map((row) => {
                    return (
                      <MenuItem
                        onClick={() => onSelectCountryItem(row)}
                        key={row.code}
                        value={row.phone}
                        className={`text-capitalize`}>
                        <div className={`${classes.flagRow}`}>
                          <Avatar
                            className={`${classes.flag}`}
                            loading="lazy"
                            // width="30"
                            // height="20"
                            srcSet={`https://flagcdn.com/w40/${row.code.toLowerCase()}.png 2x`}
                            src={`https://flagcdn.com/w20/${row.code.toLowerCase()}.png`}
                            alt="dsd"
                            style={{
                              marginInlineEnd: "7px",
                              objectFit: "cover !import",
                            }}
                          />
                          <span className={`${classes.flagTitle}`}>
                            +({row.phone}) - {row.label}
                          </span>
                        </div>
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
            ) : (
              ""
            )} */}

            <Grid
              item
              xs={12}
              sm={10}
              lg={4}
              container
              direction="column"
              justify="center"
              alignItems="center"
              align="center">
              {/* <Controller
                control={control}
                {...register("phoneNumber")}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    helperText={
                      errors?.phoneNumber ? errors?.phoneNumber.message : null
                    }
                    size="small"
                    error={!!errors?.phoneNumber}
                    onChange={onChange}
                    value={value}
                    fullWidth
                    label={t("phone-number")}
                    variant="outlined"
                  />
                )}
              /> */}

              <Controller
                control={control}
                {...register("phoneNumber")}
                render={({ field: { onChange, value } }) => (
                  <div className={`w-100`}>
                    <PhoneInput
                      helperText={
                        errors?.phoneNumber ? errors?.phoneNumber.message : null
                      }
                      error={!!errors?.phoneNumber}
                      country={"kw"}
                      value={value}
                      onChange={onChange}
                      className={"phone-number-container"}
                    />
                  </div>
                )}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={10}
              lg={4}
              container
              direction="column"
              justify="center"
              alignItems="center"
              align="center">
              <Button
                type="submit"
                disabled={loading}
                fullWidth
                size="large"
                color="primary"
                variant="contained">
                {loading ? (
                  <>
                    <CircularProgress size={17} style={{ color: "#fff" }} />
                    {t("loading")}
                  </>
                ) : (
                  t("Profile.update-profile")
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </>
  );
};
