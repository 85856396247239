import React, { useState } from "react";
import classes from "../Store.module.css";
import { Avatar } from "@components/index";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";

export const Video = ({ videoURL }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className={classes.player}>
      <ReactPlayer
        className={classes.videoPlayer}
        url={videoURL?.youtubeVideo}
        playing={true}
        muted={false}
        allowfullscreen
        controls={true}
      />
    </div>
  );
};
