import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../interceptor/axiosInstance";
import toast from "react-hot-toast";

const initialState = {
  contactUs: { data: null, loading: false, success: false },
  newsLetter: { data: null, loading: false, success: false },
};

// Create Contact Us
export const createContactUs = createAsyncThunk(
  "createContactUs/contactUs",
  async (action) => {
    const config = {
      url: `messages/send`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        name: action.fullName,
        email: action.email,
        countryCode: "+965",
        subject: action.subject,
        mobile: action.phoneNumber,
        question: action.message,
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

// Create New Letter
export const createNewsLetterContact = createAsyncThunk(
  "createNewsLetterContact/contactUs",
  async (action) => {
    const config = {
      url: `messages/send`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        name: action.fullName,
        email: action.email,
        countryCode: "+965",
        subject: action.subject,
        mobile: action.phoneNumber,
        question: action.message,
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

const contactUsReducer = createSlice({
  name: "contactUs",
  initialState,
  reducers: {
    clearState: (state) => {
      state.contactUs.success = false;
    },
  },
  extraReducers: (builder) => {
    /********** Create Contact **********/
    builder.addCase(createContactUs.pending, (state, action) => {
      state.contactUs.loading = true;
    });
    builder.addCase(createContactUs.fulfilled, (state, action) => {
      state.contactUs.loading = false;
      const payload = action?.payload;
      if (payload?.status === 200 || payload?.status === 201) {
        toast.success(payload?.data?.data?.message);
        state.contactUs.success = true;
      }
    });
    builder.addCase(createContactUs.rejected, (state, action) => {
      state.contactUs.loading = false;
    });
    /********** Create Contact **********/

    /********** Create Newsletter **********/
    builder.addCase(createNewsLetterContact.pending, (state, action) => {
      state.newsLetter.loading = true;
    });
    builder.addCase(createNewsLetterContact.fulfilled, (state, action) => {
      state.newsLetter.loading = false;
      const payload = action?.payload;
      if (payload?.status === 200 || payload?.status === 201) {
        toast.success(payload?.data?.data?.message);
        state.newsLetter.success = true;
      }
    });
    builder.addCase(createNewsLetterContact.rejected, (state, action) => {
      state.newsLetter.loading = false;
    });
    /********** Create Newsletter **********/
  },
});

export const { clearState } = contactUsReducer.actions;

export default contactUsReducer.reducer;
