import React, { useState } from "react";
import classes from "./Size.module.css";
import { useTranslation } from "react-i18next";

export const Size = ({ onSizeFromParent, sizes, size }) => {
  const { t } = useTranslation();
  const onSize = (row, id) => {
    onSizeFromParent(row, id);
  };

  return (
    <>
      <div className={`${classes.sizeContainer}`}>
        <div className={`${classes.chooseSizeTitle} mb-2`}>
          {t("chooseSize")}
        </div>
        <div className={`${classes.sizeGrid}`}>
          {sizes?.length > 0
            ? sizes?.map((row, index) => {
                return (
                  <button
                    key={row.id}
                    onClick={() => onSize(row, row.id)}
                    className={`${classes.sizeCard} ${
                      row.id === size ? classes.active : ""
                    }`}>
                    {row?.title}
                  </button>
                );
              })
            : ""}
        </div>
      </div>
    </>
  );
};
