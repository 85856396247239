import React, { useState } from "react";
import classes from "./Collection.module.css";
import Button from "@mui/material/Button";
import { Link, NavLink } from "react-router-dom";
import { Avatar, CategoryCardDefault, HeadingBar } from "@components";
import { useTranslation } from "react-i18next";

export const Collection = ({
  more,
  gridCount,
  items,
  keyword,
  title,
  linkTitle,
  link,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className={`${classes.container} pb-5 pt-5`}>
        <div className="container-fluid">
          <HeadingBar
            title={title}
            linkTitle={
              keyword === "superDeals"
                ? `${t("more")} ${t("super-deals")}`
                : null
            }
            link={keyword === "superDeals" ? "/super-deals" : null}
          />

          <div className={classes.block}>
            <div
              className={`${classes.grid} ${
                gridCount ? gridCount : "mixer-grid"
              }`}>
              {items?.map((row, index) => {
                let keyword = null;
                let keywordID = null;
                let keywordEncryptID = null;

                if (row?.module) {
                  keywordEncryptID = row?.moduleInEncryptId;
                  keywordID = row?.moduleId;

                  if (row?.module === "brands") {
                    keyword = "b";
                  }

                  if (row?.module === "sub2Categories") {
                    keyword = "s2c";
                  }

                  if (row?.module === "subCategories") {
                    keyword = "sc";
                  }

                  if (row?.module === "mainCategories") {
                    keyword = "c";
                  }
                } else {
                  keyword = "c";
                  keywordID = row?.id;
                }

                return (
                  <CategoryCardDefault
                    className={classes.card}
                    row={row}
                    keyword={keyword}
                    keywordID={keywordID}
                    keywordEncryptID={keywordEncryptID}
                  />
                );
              })}
            </div>

            {/* <div className={`${classes.action}`}>
              <NavLink className={`btn btn-corner`} to="/super-deals">
                {t("super-deals")}{" "}
                <span className={`icon-redirection icon`}></span>
              </NavLink>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
