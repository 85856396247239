import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import classes from "../Slider.module.css";
import { Avatar } from "@components";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  EffectFade,
  Mousewheel,
  Lazy,
  Thumbs,
} from "swiper";

export const SliderPreview = ({
  thumbIsActive,
  setThumbsSwiper,
  onClosePreviewFromParent,
  gallery,
  mainImage,
  swiperRef,
}) => {
  // const swiperRef = useRef(null);
  const { t, i18n } = useTranslation();
  const playerRef = useRef(null);
  const [playing, setPlaying] = useState(0);

  const handleSlideChange = (e) => {
    setPlaying(e.activeIndex + "preview");
    console.log(e.activeIndex + "preview");
  };

  const closeSlider = ({ gallery }) => {
    onClosePreviewFromParent(!thumbIsActive);
    setPlaying(null);
  };

  return (
    <>
      <div
        className={`${thumbIsActive ? classes.active : ""} ${
          classes.thumbContainer
        } text-center`}>
        <Swiper
          ref={swiperRef}
          modules={[Thumbs, Pagination, Navigation]}
          onSwiper={setThumbsSwiper}
          spaceBetween={20}
          slidesPerView={1}
          loop={false}
          navigation={{
            prevEl: ".btn-arrow-prev-thumb",
            nextEl: ".btn-arrow-next-thumb",
          }}
          speed={800}
          onSlideChange={handleSlideChange}>
          <SwiperSlide>
            <Avatar className={classes.imgThumb} src={mainImage} />
          </SwiperSlide>
          {gallery?.map((row, index) => {
            const newIndex = index + "preview";
            return (
              <SwiperSlide>
                {row?.media?.type === "image" ? (
                  <Avatar className={classes.imgThumb} src={row?.media?.url} />
                ) : (
                  <ReactPlayer
                    key={index + 5000}
                    controls
                    className={classes.videoPlayerPreview}
                    url={row.media.url}
                    playing={playing === newIndex}
                    muted={false}
                  />
                )}
              </SwiperSlide>
            );
          })}
        </Swiper>

        <div className={`${classes.btnActions}`}>
          <div className={`btn-arrow btn-arrow-left btn-arrow-prev-thumb`}>
            <span className="icon-chevron-left icon"></span>
          </div>

          <div onClick={closeSlider} className={`btn-arrow btn-arrow-exit`}>
            <span className="icon-cancel icon"></span>
          </div>

          <div className={`btn-arrow btn-arrow-right btn-arrow-next-thumb`}>
            <span className="icon-chevron-right icon"></span>
          </div>
        </div>
      </div>
    </>
  );
};
