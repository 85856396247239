import React, { useState, useEffect } from "react";
import { Routes, Route, Outlet } from "react-router";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import classes from "./Account.module.css";
import { jwt } from "@services";
import { Config } from "@config/config";
import { useTranslation } from "react-i18next";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useLocation } from "react-router-dom";

/** Redux **/
import { useDispatch, useSelector } from "react-redux";
import { userData, logout } from "@store/auth/auth";

/** Pages  **/
import { Info } from "./UpdateInfo";
import { Password } from "./UpdatePassword";

export default function UpdateLayout() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const jwToken = jwt();
  const location = useLocation();
  const pathname = location.pathname.slice(9);
  const { data, loading } = useSelector((state) => state.auth.userData);
  const [alignment, setAlignment] = React.useState(pathname);

  const handleChange = (event, newAlignment) => {
    if (newAlignment === null) {
      navigate(`/account/${alignment}`);
    } else {
      setAlignment(newAlignment);
      navigate(`/account/${newAlignment}`);
    }
  };

  const onLogout = () => {
    dispatch(logout());
  };

  useEffect(() => {
    dispatch(userData());
  }, [dispatch, jwToken]);

  useEffect(() => {
    setAlignment(pathname);
  }, [pathname]);

  return (
    <>
      <div className={classes.container}>
        <div className={`${classes.updateProfileContainer}`}>
          <div
            className={`${classes.updateProfileHeader} d-flex align-items-center justify-content-between`}>
            <div className={`${classes.updateProfileBackBtn}`}>
              <NavLink to="/account" className={`btn default-btn back-btn`}>
                <span className={`icon-chevron-left icon`}> </span>
                {t("back-to-account")}
              </NavLink>
            </div>

            <div className={`${classes.updateProfileTabs}`}>
              <ToggleButtonGroup
                color="primary"
                value={alignment}
                exclusive
                onChange={handleChange}
                aria-label="Platform">
                <ToggleButton value={"update-profile"}>
                  {t("Profile.update-profile")}
                </ToggleButton>
                <ToggleButton value={"update-password"}>
                  {t("Profile.update-password")}
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>

          <div className={`${classes.updatePages} pt-5`}>
            <Outlet />
            <Routes>
              <Route exact path="/update-profile" element={<Info />} />
              <Route path="/update-password" element={<Password />} />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
}
