import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import axiosInstance from "../interceptor/axiosInstance";

const initialState = {
  search: {
    data: null,
    paginate: null,
    loading: false,
    success: false,
    pagePaginate: 1,
  },
};

// Fetch Search
export const fetchSearch = createAsyncThunk(
  "fetchSearch/search",
  async (action) => {
    let url = `products?paginate=15&search=${action.search}`;
    let page = {};

    if (action?.page && action?.page != 1) {
      url = `products?paginate=15&page=${action.page}&search=${action.search}`;
      page = {
        page: action?.page,
      };
    }

    const config = {
      url: url,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: page,
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

const searchReducer = createSlice({
  name: "search",
  initialState,
  reducers: {
    clearState: (state) => {
      state.search.success = false;
    },
    handlePagePagination: (state, action) => {
      state.search.pagePaginate = action.payload.page;
    },
  },
  extraReducers: (builder) => {
    /********** Fetch Search **********/
    builder.addCase(fetchSearch.pending, (state, action) => {
      state.search.loading = true;
      state.search.data = null;
    });
    builder.addCase(fetchSearch.fulfilled, (state, action) => {
      state.search.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        state.search.data = payload?.data?.data?.rows;
        state.search.paginate = payload?.data?.data?.paginate;

        const pageIfFound = action?.meta?.arg?.page;
        if (pageIfFound) {
          state.search.pagePaginate = pageIfFound;
        } else {
          state.search.pagePaginate = 1;
        }
      }
    });
    builder.addCase(fetchSearch.rejected, (state, action) => {
      state.search.loading = false;
      state.search.data = null;
    });
    /********** Fetch Search **********/
  },
});

export const { handlePagePagination, clearState } = searchReducer.actions;

export default searchReducer.reducer;
