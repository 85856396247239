import React, { useEffect, useState } from "react";
import classes from "./Counter.module.css";
import { fetchCart, updateCartItemQty, clearState } from "@store/cart";
import { useSelector, useDispatch } from "react-redux";
import { CircleSpinner, EmptyProductsMsg } from "@components";

export const Counter = ({ row }) => {
  const dispatch = useDispatch();
  const [maxCount, setMaxCount] = useState(row?.availableQty);
  const [counter, setCounter] = useState(row?.qty);
  const [anonymousCounter, setAnonymousCounter] = useState(null);
  const [currentId, setCurrentId] = useState(null);
  const { loading: loadingUpdateCartQty, success: successUpdateCartQty } =
    useSelector((state) => state.cart.updateCartQty);

  const onCounter = (type = null, id) => {
    if (maxCount == 0) {
      return;
    }
    let data = counter;
    if (type === "decrement") {
      if (data === 1) {
        return;
      }
      data -= 1;
    }

    if (type === "increment") {
      if (data >= maxCount) {
        return;
      }
      data += 1;
    }
    // onDataFromCounter(data);
    setAnonymousCounter(data);
    setCurrentId(id);
    dispatch(
      updateCartItemQty({
        qty: data,
        shoppingCartId: row?.shoppingCartId,
        productId: row?.id,
      })
    );
  };

  useEffect(() => {
    if (successUpdateCartQty) {
      setCounter(anonymousCounter);
      dispatch(fetchCart({ noLoading: true }));
    }
    dispatch(clearState());
  }, [dispatch, successUpdateCartQty, anonymousCounter, counter]);

  return (
    <div
      className={[classes.counter, "d-inline-flex align-items-center"].join(
        " "
      )}>
      {currentId === row.id && loadingUpdateCartQty ? (
        <div className={`${classes.overlay}`}>
          <CircleSpinner size="sm" />
        </div>
      ) : (
        ""
      )}
      <div
        onClick={() => onCounter("decrement", row.id)}
        className={[
          classes.decrement,
          classes.counterBtn,
          "d-flex align-items-center",
        ].join(" ")}>
        <span className={`icon-minus ${classes.icon}`}></span>
      </div>
      <input
        className={[classes.inputCounter].join(" ")}
        type="text"
        value={counter}
        readOnly
      />
      <div
        onClick={() => onCounter("increment", row.id)}
        className={[
          classes.increment,
          classes.counterBtn,
          "d-flex align-items-center",
        ].join(" ")}>
        <span className={`icon-plus ${classes.icon}`}></span>
      </div>
    </div>
  );
};
