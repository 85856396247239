import React, { useState, useEffect } from "react";
import classes from "./Payment.module.css";
import { CircleSpinner } from "@components";
import visa from "@assets/img/icons/visa.png";
import mastercard from "@assets/img/icons/mastercard.png";
import Knet from "@assets/img/payments/knet.png";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import { fetchPaymentOptions } from "@store/checkout";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export const PaymentMethods = ({
  onPaymentMethodFromParent,
  paymentMethod,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data, loading, success } = useSelector(
    (state) => state.checkout.paymentOptions
  );

  const togglePayment = (value) => {
    onPaymentMethodFromParent(value);
  };

  useEffect(() => {
    dispatch(fetchPaymentOptions());
  }, [dispatch]);

  return (
    <div className={`${classes.paymentGateaway}`}>
      <div className="mb-2">
        <div className={`${classes.title} title-md`}>{t("payment")}</div>
        <div className={`${classes.text} text`}>
          {t("all-transactions-are-secure-and-encrypted")}
        </div>
      </div>

      {loading ? (
        <CircleSpinner size="lg" />
      ) : (
        <>
          <Accordion open={paymentMethod} toggle={togglePayment}>
            {data?.length > 0
              ? data?.map((row, index) => {
                  return (
                    <AccordionItem
                      className={paymentMethod === row?.value ? "active" : ""}>
                      <AccordionHeader
                        className="d-flex justify-content-between"
                        targetId={row?.value}>
                        <div className={`accordion-header-title`}>
                          <div className="radio-bullet">
                            <span className="radio-bullet-circle"></span>
                          </div>
                          <div className="radio-label">{row?.title}</div>
                          <div className="accordion-logos d-flex align-items-center">
                            <img
                              className="accordion-logo img-fluid"
                              src={row?.media?.url}
                              alt={row?.title}
                            />
                          </div>

                          {/* {row?.value.toLowerCase() === "knet" ? (
                            <div className="accordion-logos d-flex align-items-center">
                              <img
                                className="accordion-logo img-fluid"
                                src={Knet}
                                alt={t("Cart.knet")}
                              />
                            </div>
                          ) : (
                            ""
                          )} */}
                        </div>
                      </AccordionHeader>
                    </AccordionItem>
                  );
                })
              : ""}

            {/* <AccordionItem className={paymentMethod === "cash" ? "active" : ""}>
              <AccordionHeader targetId="cash">
                <div className={`accordion-header-title`}>
                  <div className="radio-bullet">
                    <span className="radio-bullet-circle"></span>
                  </div>
                  <div className="radio-label">{t("cash-on-delivery")}</div>
                </div>
              </AccordionHeader>
              <AccordionBody accordionId="cash">
                <div className="accordion-alert text-center">
                  {t("cash-on-delivery-available-only-for-kuwait")}
                </div>
              </AccordionBody>
            </AccordionItem> */}
          </Accordion>
        </>
      )}
    </div>
  );
};
