import React, { useEffect, useState } from "react";
import classes from "./Create.module.css";
import Button from "@mui/material/Button";
import { NavLink, Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Form } from "reactstrap";
import Autocomplete from "@mui/material/Autocomplete";
import { countries } from "@services/Countries";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import { CircleSpinner } from "@components";
import { useTranslation } from "react-i18next";

/** Redux **/
import { useDispatch, useSelector } from "react-redux";
import { fetchProvinces, fetchCities } from "@store/general";
import { createAdress, clearState, fetchAddresses } from "@store/user";

export const Create = ({ onCreateFromParent, open }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.user.address.loading);
  const success = useSelector((state) => state.user.address.success);

  const provincesData = useSelector((state) => state.general.provinces.data);
  const provincesLoading = useSelector(
    (state) => state.general.provinces.loading
  );
  const citiesData = useSelector((state) => state.general.cities.data);
  const citiesLoading = useSelector((state) => state.general.cities.loading);
  const [province, setProvince] = useState(null);
  const [city, setCity] = useState(null);
  const handleClose = () => {
    onCreateFromParent(false);
  };

  const onProvince = (e) => {
    setCity(null);
    const value = e.target.value;
    setProvince(value);

    if (value) {
      dispatch(fetchCities({ rowId: value }));
    }
  };

  const schema = yup
    .object({
      phone: yup
        .string()
        .required(t("Validations.phone-required"))
        .min(3, t("Validations.phone-too-short")),
      addressTitle: yup
        .string()
        .required(t("Validations.address-required"))
        .min(3, t("Validations.address-too-short")),
      address2: yup
        .string()
        .required(t("Validations.address-required"))
        .min(3, t("Validations.address-too-short")),
      zipCode: yup
        .string()
        .required(t("Validations.zip-code-required"))
        .min(2, t("Validations.zip-code-too-short")),
    })
    .required();

  const defaultValues = {
    addressTitle: "",
    address2: "",
    phone: "",
    zipCode: "",
    primary: false,
  };

  const {
    setError,
    clearErrors,
    reset,
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = async (data) => {
    const newData = Object.assign(data, { city, province });
    dispatch(createAdress(newData));
    // console.log(data);
  };

  const customStyle = {
    maxWidth: "450px",
  };

  const descriptionElementRef = React.useRef(null);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  useEffect(() => {
    dispatch(fetchProvinces());
  }, [dispatch]);

  useEffect(() => {
    if (success) {
      reset();
      setProvince(null);
      setCity(null);
      dispatch(clearState());
      dispatch(fetchAddresses());
      // setOpen(false);
      onCreateFromParent(false);
    }
  }, [success]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description">
        <DialogTitle id="scroll-dialog-title" className="text-center">
          {t("add-new-address")}
          <div className="text text-center pl-2 pr-2">
            {t("auth-description")}
          </div>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
        <DialogContent style={customStyle}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              {/* <Grid item xs={12} sm={12}>
                <Controller
                  control={control}
                  {...register("firstName")}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      helperText={
                        errors?.firstName ? errors?.firstName.message : null
                      }
                      size="small"
                      error={!!errors?.firstName}
                      onChange={onChange}
                      value={value}
                      fullWidth
                      label={"First Name"}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Controller
                  control={control}
                  {...register("lastName")}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      helperText={
                        errors?.lastName ? errors?.lastName.message : null
                      }
                      size="small"
                      error={!!errors?.lastName}
                      onChange={onChange}
                      value={value}
                      fullWidth
                      label={"Last Name"}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Controller
                  control={control}
                  {...register("company")}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      helperText={
                        errors?.company ? errors?.company.message : null
                      }
                      size="small"
                      error={!!errors?.company}
                      onChange={onChange}
                      value={value}
                      fullWidth
                      label={"Company"}
                      variant="outlined"
                    />
                  )}
                />
              </Grid> */}
              <Grid item xs={12} sm={12}>
                <Controller
                  control={control}
                  {...register("phone")}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      helperText={errors?.phone ? errors?.phone.message : null}
                      size="small"
                      error={!!errors?.phone}
                      onChange={onChange}
                      value={value}
                      fullWidth
                      label={t("phone")}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Controller
                  control={control}
                  {...register("addressTitle")}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      helperText={
                        errors?.addressTitle
                          ? errors?.addressTitle.message
                          : null
                      }
                      size="small"
                      error={!!errors?.addressTitle}
                      onChange={onChange}
                      value={value}
                      fullWidth
                      label={`${t("address")} 1`}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Controller
                  control={control}
                  {...register("address2")}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      helperText={
                        errors?.address2 ? errors?.address2.message : null
                      }
                      size="small"
                      error={!!errors?.address2}
                      onChange={onChange}
                      value={value}
                      fullWidth
                      label={`${t("address")} 2`}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              {provincesLoading ? (
                <CircleSpinner className={`pt-3`} size={50} />
              ) : (
                <Grid item xs={12} sm={12}>
                  <Box>
                    <FormControl size="small" fullWidth>
                      <TextField
                        select
                        size="small"
                        value={province}
                        label={t("province")}
                        onChange={(e) => onProvince(e)}>
                        {provincesData &&
                          provincesData.map((row, index) => {
                            return (
                              <MenuItem value={row.id}>{row.name}</MenuItem>
                            );
                          })}
                      </TextField>
                    </FormControl>
                  </Box>
                </Grid>
              )}

              {province ? (
                citiesLoading ? (
                  <CircleSpinner className={`pt-3`} size={50} />
                ) : (
                  <Grid item xs={12} sm={12}>
                    <Box>
                      <FormControl size="small" fullWidth>
                        <TextField
                          select
                          size="small"
                          value={city}
                          label={t("city")}
                          onChange={(e) => setCity(e.target.value)}>
                          {citiesData &&
                            citiesData.map((row, index) => {
                              return (
                                <MenuItem value={row.id}>{row.name}</MenuItem>
                              );
                            })}
                        </TextField>
                      </FormControl>
                    </Box>
                  </Grid>
                )
              ) : (
                ""
              )}

              <Grid item xs={12} sm={12}>
                <Controller
                  control={control}
                  {...register("zipCode")}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      helperText={
                        errors?.zipCode ? errors?.zipCode.message : null
                      }
                      size="small"
                      error={!!errors?.zipCode}
                      onChange={onChange}
                      value={value}
                      fullWidth
                      label={t("zip-code")}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Controller
                control={control}
                {...register("primary")}
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    helperText={
                      errors?.primary ? errors?.primary.message : null
                    }
                    error={!!errors?.primary}
                    onChange={onChange}
                    value={value}
                    size="sm"
                    control={<Checkbox />}
                    label={t("set-as-default-address")}
                  />
                )}
              />
            </Grid>
            <Button
              disabled={loading}
              className="mt-3"
              fullWidth
              type="submit"
              size="large"
              color="primary"
              variant="contained">
              {loading ? (
                <>
                  <CircularProgress size={17} style={{ color: "#fff" }} />
                  {t("loading")}
                </>
              ) : (
                t("save-address")
              )}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
