import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import classes from "./Item.module.css";
import { Avatar, CartAttributes } from "@components";
import { useTranslation } from "react-i18next";

export const Item = ({ row }) => {
  const { t } = useTranslation();

  return (
    <div className={`${classes.item} d-flex justify-content-between`}>
      <div className={`${classes.left} d-flex`}>
        <div className={`${classes.view}`}>
          <div className={classes.badge}>{row?.qty}</div>
          <Avatar className={classes.image} src={row?.media?.url} />
        </div>
        <div className={`${classes.details}`}>
          <div className={`${classes.title} title lines-2`}>{row?.title}</div>
          <div className={`${classes.size} text`}>
            {row?.unitPrice} {t("currency")} ({t("for-1-piece")})
          </div>
          <CartAttributes row={row} />
        </div>
      </div>
      <div className={`${classes.right} d-flex align-items-center`}>
        <div className={`${classes.price}`}>
          {row?.totalPrice} {t("currency")}
        </div>
      </div>
    </div>
  );
};
