import React, { useState } from "react";
import { Helmet } from "react-helmet";
import classes from "./WhatsApp.module.css";
import WhatsAppLogo from "@assets/img/icons/whatsapp.svg";
import { useTranslation } from "react-i18next";

export const WhatsApp = ({ whatsAppNumber }) => {
  const { t, i18n } = useTranslation();
  const onWhatsappContact = () => {
    let url = window.location.href;
    let text = `https://wa.me/${whatsAppNumber}?&text=${t(
      "hello-have-question-about"
    )}`;
    window.open(text, "_blank");
  };

  return (
    <>
      <button
        onClick={onWhatsappContact}
        className={`btn ${classes.container}`}>
        <img
          className={`img-fluid ${classes.image}`}
          src={WhatsAppLogo}
          alt={t("whatsapp")}
        />
      </button>
    </>
  );
};
