import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../interceptor/axiosInstance";
import toast from "react-hot-toast";

const initialState = {
  home: { data: null, loading: false },
  popularProducts: { data: null, loading: false },
  mostRecentProducts: { data: null, loading: false },
  newArrivalsProducts: { data: null, loading: false },
  brands: { data: null, loading: false },
  offers: { data: null, loading: false },
  sale: { data: null, loading: false },
};

// Fetch Home
export const fetchHome = createAsyncThunk("fetchHome/home", async (action) => {
  const config = {
    url: `home`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return await axiosInstance(config)
    .then((response) => response)
    .catch((errors) => errors.response);
});

// Fetch Popular Products
export const fetchPopularProducts = createAsyncThunk(
  "fetchPopularProducts/home",
  async (action) => {
    const config = {
      url: `products?paginate=100&sortBy=popular`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

// Fetch Most Recent Products
export const fetchMostRecentProducts = createAsyncThunk(
  "fetchProducts/home",
  async (action) => {
    const config = {
      url: `products?paginate=100&sortBy=mostRecent`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

// Fetch New Arrivals Products
export const fetchNewArrivalsProducts = createAsyncThunk(
  "fetchNewArrivalsProducts/home",
  async (action) => {
    const config = {
      url: `products?paginate=100&sortBy=newArrivals`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

// Fetch Brands
export const fetchBrands = createAsyncThunk(
  "fetchBrands/home",
  async (action) => {
    const config = {
      url: `brands?paginate=100`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

// Fetch Offers
export const fetchOffers = createAsyncThunk(
  "fetchOffers/home",
  async (action) => {
    const config = {
      url: `offers`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

// Fetch Offers
export const fetchSale = createAsyncThunk("fetchSale/home", async (action) => {
  const config = {
    url: `/products/sale`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return await axiosInstance(config)
    .then((response) => response)
    .catch((errors) => errors.response);
});

const homeReducer = createSlice({
  name: "home",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /********** Fetch Home **********/
    builder.addCase(fetchHome.pending, (state, action) => {
      state.home.loading = true;
    });
    builder.addCase(fetchHome.fulfilled, (state, action) => {
      state.home.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        state.home.data = payload?.data?.data;
      }
    });
    builder.addCase(fetchHome.rejected, (state, action) => {
      state.home.loading = false;
    });
    /********** Fetch Home **********/

    /********** Fetch Popular Products **********/
    builder.addCase(fetchPopularProducts.pending, (state, action) => {
      state.popularProducts.loading = true;
    });
    builder.addCase(fetchPopularProducts.fulfilled, (state, action) => {
      state.popularProducts.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        state.popularProducts.data = payload?.data?.data?.rows;
      }
    });
    builder.addCase(fetchPopularProducts.rejected, (state, action) => {
      state.popularProducts.loading = false;
    });
    /********** Fetch Popular Products **********/

    /********** Fetch Most Recent Products **********/
    builder.addCase(fetchMostRecentProducts.pending, (state, action) => {
      state.mostRecentProducts.loading = true;
    });
    builder.addCase(fetchMostRecentProducts.fulfilled, (state, action) => {
      state.mostRecentProducts.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        state.mostRecentProducts.data = payload?.data?.data?.rows;
      }
    });
    builder.addCase(fetchMostRecentProducts.rejected, (state, action) => {
      state.mostRecentProducts.loading = false;
    });
    /********** Fetch Most Recent Products **********/

    /********** Fetch Most Recent Products **********/
    builder.addCase(fetchNewArrivalsProducts.pending, (state, action) => {
      state.newArrivalsProducts.loading = true;
    });
    builder.addCase(fetchNewArrivalsProducts.fulfilled, (state, action) => {
      state.newArrivalsProducts.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        state.newArrivalsProducts.data = payload?.data?.data?.rows;
      }
    });
    builder.addCase(fetchNewArrivalsProducts.rejected, (state, action) => {
      state.newArrivalsProducts.loading = false;
    });
    /********** Fetch Most Recent Products **********/

    /********** Fetch Brands **********/
    builder.addCase(fetchBrands.pending, (state, action) => {
      state.brands.loading = true;
    });
    builder.addCase(fetchBrands.fulfilled, (state, action) => {
      state.brands.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        state.brands.data = payload?.data?.data?.rows;
      }
    });
    builder.addCase(fetchBrands.rejected, (state, action) => {
      state.brands.loading = false;
    });
    /********** Fetch Brands **********/

    /********** Fetch Offers **********/
    builder.addCase(fetchOffers.pending, (state, action) => {
      state.offers.loading = true;
    });
    builder.addCase(fetchOffers.fulfilled, (state, action) => {
      state.offers.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        state.offers.data = payload?.data?.data?.rows;
      }
    });
    builder.addCase(fetchOffers.rejected, (state, action) => {
      state.offers.loading = false;
    });
    /********** Fetch Offers **********/

    /********** Fetch Sale **********/
    builder.addCase(fetchSale.pending, (state, action) => {
      state.sale.loading = true;
    });
    builder.addCase(fetchSale.fulfilled, (state, action) => {
      state.sale.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        state.sale.data = payload?.data?.data?.rows;
      }
    });
    builder.addCase(fetchSale.rejected, (state, action) => {
      state.sale.loading = false;
    });
    /********** Fetch Sale **********/
  },
});

export default homeReducer.reducer;
