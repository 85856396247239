import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../interceptor/axiosInstance";
import toast from "react-hot-toast";

const initialState = {
  // isAuthenticated: false,
  login: { loading: false, success: null, error: null },
  register: { loading: false, success: null, error: null },
  userData: { data: null, loading: false },
  forgetPassword: { data: null, loading: false, success: null, error: null },
  resetPassword: { data: null, loading: false, success: null, error: null },
  sendOtp: { data: null, loading: false, success: null },
  verified: { data: null, loading: false, success: null },
};

export const login = createAsyncThunk("auth/login", async (action) => {
  const config = {
    url: `auth/login`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: {
      email: action?.email,
      password: action?.password,
    },
  };
  return await axiosInstance(config)
    .then((response) => response)
    .catch((errors) => errors.response.data);
});

export const register = createAsyncThunk("auth/register", async (action) => {
  const config = {
    url: `auth/register`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: {
      name: action.fullname,
      mobile: action.phoneNumber,
      email: action.email,
      password: action.password,
    },
  };
  return await axiosInstance(config)
    .then((response) => response)
    .catch((errors) => errors.response.data);
});

export const userData = createAsyncThunk("auth/userData", async (action) => {
  const config = {
    url: `auth/me`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return await axiosInstance(config)
    .then((response) => response.data)
    .catch((errors) => errors.response.data);
});

export const forgetPassword = createAsyncThunk(
  "auth/forgetPassword",
  async (action) => {
    const config = {
      url: `auth/forgetPassword`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        email: action.email,
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response.data);
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (action) => {
    const config = {
      url: `auth/resetNewPassword`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        email: action?.email,
        validationCode: action?.validationCode,
        newPassword: action?.password,
        newPasswordConfirmation: action?.confirmationPassword,
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response.data);
  }
);

export const logout = createAsyncThunk("auth/logout", async (action) => {
  const config = {
    url: `auth/logout`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: {
      accessToken: localStorage.getItem("token") ?? "",
    },
  };
  return await axiosInstance(config)
    .then((response) => response.data)
    .catch((errors) => errors.response.data);
});

// Send Otp
export const createOtp = createAsyncThunk("createOtp/auth", async (action) => {
  const config = {
    url: `auth/requestNewCode`,
    method: "POST",
    data: {
      email: action?.email,
    },
  };
  return await axiosInstance(config)
    .then((response) => response)
    .catch((errors) => errors.response);
});

// Verify
export const verifyEmail = createAsyncThunk(
  "verifyEmail/auth",
  async (action) => {
    const config = {
      url: `auth/verify`,
      method: "POST",
      data: {
        email: action?.email,
        validationCode: action?.otp,
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

const authReducer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearState: (state) => {
      state.login.success = false;
      state.login.error = false;
      state.register.success = false;
      state.register.error = false;
      state.verified.success = false;
      state.sendOtp.success = false;
      state.forgetPassword.success = false;
      state.resetPassword.success = false;
    },
  },
  extraReducers: (builder) => {
    /***** Login *****/
    builder.addCase(login.pending, (state, action) => {
      state.login.loading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      console.log(action);
      state.login.loading = false;
      const payload = action?.payload;
      if (payload?.status === 200 || payload?.status === 201) {
        // state.isAuthenticated = true;
        const token = payload?.data?.data?.accessToken;
        if (token) {
          localStorage.setItem("token", token);
          window.location.href = "/";
        }
        state.login.success = true;
        state.login.error = false;
      }
    });
    builder.addCase(login.rejected, (state, action) => {
      console.log(action);
      state.login.loading = false;
      state.login.success = false;
      state.login.error = true;
    });
    /***** Login *****/

    /***** Register *****/
    builder.addCase(register.pending, (state, action) => {
      state.register.loading = true;
    });
    builder.addCase(register.fulfilled, (state, action) => {
      state.register.loading = false;
      const payload = action?.payload;
      if (payload?.status === 200 || payload?.status === 201) {
        state.register.success = true;
        state.register.error = false;
        // toast.success(payload?.data?.message ?? "Successfuly Register");
      }
    });
    builder.addCase(register.rejected, (state, action) => {
      state.register.loading = false;
      state.register.success = false;
      state.register.error = true;
    });
    /***** Register *****/

    /***** UserData *****/
    builder.addCase(userData.pending, (state, action) => {
      state.userData.loading = true;
    });
    builder.addCase(userData.fulfilled, (state, action) => {
      state.userData.loading = false;
      const payload = action.payload;
      state.userData.data = payload.data;
    });
    builder.addCase(userData.rejected, (state, action) => {
      state.userData.loading = false;
    });
    /***** UserData *****/

    /***** Forget Password *****/
    builder.addCase(forgetPassword.pending, (state, action) => {
      state.forgetPassword.loading = true;
    });
    builder.addCase(forgetPassword.fulfilled, (state, action) => {
      state.forgetPassword.loading = false;
      const payload = action.payload;
      if (payload?.status === 200 || payload?.status === 201) {
        state.forgetPassword.success = true;
      }
    });
    builder.addCase(forgetPassword.rejected, (state, action) => {
      state.forgetPassword.loading = false;
    });
    /***** Forget Password *****/

    /***** Reset Password *****/
    builder.addCase(resetPassword.pending, (state, action) => {
      state.resetPassword.loading = true;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.resetPassword.loading = false;
      const payload = action?.payload;
      if (payload?.status === 200 || payload?.status === 201) {
        state.resetPassword.success = true;
      }
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.resetPassword.loading = false;
    });
    /***** Reset Password *****/

    /***** Logout *****/
    builder.addCase(logout.pending, (state, action) => {});
    builder.addCase(logout.fulfilled, (state, action) => {
      const token = localStorage.getItem("token");
      if (token) {
        localStorage.removeItem("token");
        window.location.href = "/auth/login";
      }
    });
    builder.addCase(logout.rejected, (state, action) => {});
    /***** Logout *****/

    /********** Send OTP **********/
    builder.addCase(createOtp.pending, (state, action) => {
      state.sendOtp.loading = true;
    });
    builder.addCase(createOtp.fulfilled, (state, action) => {
      state.sendOtp.loading = false;
      const payload = action?.payload;
      console.log(action.payload);
      if (payload?.status === 200 || payload?.status === 201) {
        state.sendOtp.success = true;
        const message = payload?.data?.message;
        toast.success(message);
      }

      if (payload?.status === 404) {
        state.sendOtp.success = false;
        const error = payload?.data?.error;
        toast.error(error);
      }
    });
    builder.addCase(createOtp.rejected, (state, action) => {
      state.sendOtp.loading = false;
      state.sendOtp.success = false;
    });
    /********** Send OTP **********/

    /********** Verify **********/
    builder.addCase(verifyEmail.pending, (state, action) => {
      state.verified.loading = true;
    });
    builder.addCase(verifyEmail.fulfilled, (state, action) => {
      state.verified.loading = false;
      const payload = action?.payload;
      if (payload?.status === 200 || payload?.status === 201) {
        state.verified.success = true;
        const message = payload?.data?.message;
        toast.success(message);
      }

      if (payload?.status === 404 || payload?.status === 400) {
        state.verified.success = false;
        const error = payload?.data?.error;
        toast.error(error);
      }
    });
    builder.addCase(verifyEmail.rejected, (state, action) => {
      state.verified.loading = false;
      state.verified.success = false;
    });
    /********** Verify **********/
  },
});

export const { clearState } = authReducer.actions;

export default authReducer.reducer;
