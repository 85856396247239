import React, { useState } from "react";
import classes from "./OrderCard.module.css";
import { Avatar, Counter, CartAttributes } from "@components";
import { useTranslation } from "react-i18next";

export const OrderCard = ({ className, row }) => {
  const { t } = useTranslation();
  const handleCounter = (data) => {
    console.log(data);
  };

  return (
    <tr>
      <td>
        <div className={`${classes.card}  ${className} d-flex`}>
          <div className={`${classes.view} table-card-view`}>
            <Avatar
              className={`${classes.image} image`}
              src={row?.media?.url}
            />
          </div>
          <div className={`${classes.details} table-card-details`}>
            <div className={`${classes.top} top`}>
              <div className={`${classes.title} title max-1-line`}>
                {row?.title}
              </div>

              <div className="mt-2">
                {/* <div className={`${classes.text} table-text`}>040</div> */}
                <div className={`${classes.text} ${classes.price} table-text`}>
                  {row?.unitPrice} {t("currency")}
                </div>
              </div>

              <CartAttributes row={row} />
            </div>
          </div>
        </div>
      </td>
      <td>
        <div className={`${classes.text} table-text justify-content-center`}>
          {row?.qty}
        </div>
      </td>
      <td>
        <div className={`${classes.text} table-text justify-content-end`}>
          {row?.totalPrice} {t("currency")}
        </div>
      </td>
    </tr>
  );
};
