import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import axiosInstance from "../interceptor/axiosInstance";

const initialState = {
  categories: {
    data: null,
    paginate: null,
    pagePaginate: 1,
    loading: false,
    success: false,
  },
};

// Fetch Categories
export const fetchCategories = createAsyncThunk(
  "fetchCategories/categories",
  async (action) => {
    const config = {
      url: `categories?paginate=30`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        page: action?.page,
      },
      params: {
        page: action?.page,
        search: action?.search,
        parentId: action?.parentId,
      },
    };
    return await axiosInstance(config)
      .then((response) => response)
      .catch((errors) => errors.response);
  }
);

const categoriesReducer = createSlice({
  name: "categories",
  initialState,
  reducers: {
    handlePagePagination: (state, action) => {
      state.categories.pagePaginate = action.payload.page;
    },
  },
  extraReducers: (builder) => {
    /********** Fetch Categories **********/
    builder.addCase(fetchCategories.pending, (state, action) => {
      state.categories.loading = true;
      state.categories.data = null;
    });
    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      state.categories.loading = false;
      const payload = action.payload;
      if (payload?.status === 200) {
        state.categories.data = payload?.data?.data?.rows;
        state.categories.paginate = payload?.data?.data?.paginate;
        state.categories.success = true;
        state.categories.pagePaginate =
          payload?.data?.data?.paginate?.current_page;
      }
    });
    builder.addCase(fetchCategories.rejected, (state, action) => {
      state.categories.loading = false;
      state.categories.data = null;
    });
    /********** Fetch Categories **********/
  },
});

export const { handlePagePagination } = categoriesReducer.actions;

export default categoriesReducer.reducer;
