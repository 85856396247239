import React, { useState } from "react";
import classes from "./Download.module.css";
import { Avatar } from "@components";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Config } from "@config";
import { useSelector } from "react-redux";
import DownloadVector from "@assets/img/download.png";

export const Download = ({ loading }) => {
  const { t } = useTranslation();
  const { data } = useSelector((state) => state.general.appSettings);

  return loading ? (
    ""
  ) : (
    <>
      <div className={`${classes.container}`} data-aos="zoom-out-down">
        <div className={classes.block}>
          <div className={classes.grid}>
            <div className={classes.view}>
              <Avatar
                className={classes.image}
                src={DownloadVector}
                alt={Config?.name}
              />
            </div>
            <div className={classes.details}>
              <div className={classes.info}>
                <div
                  className={`${classes.downloadText}`}
                  dangerouslySetInnerHTML={{
                    __html: t("download-now-code-description"),
                  }}
                />
                {/* <div
                  dangerouslySetInnerHTML={{
                    __html: t("download-now-code-description", {
                      percentage: "<b>10%</b>",
                      code: "<b>VIP10</b>",
                    }),
                  }}
                /> */}
                <div>
                  <div className={`title ${classes.title}`}>
                    {t("download-now")}
                  </div>
                  <a
                    href={data?.androidAppUrl}
                    target="_blank"
                    className={`btn btn-normal ${classes.btn}`}
                    rel="noreferrer">
                    {t("play-store")}
                  </a>

                  <a
                    href={data?.iosAppUrl}
                    target="_blank"
                    className={`btn btn-normal ${classes.btn}`}
                    rel="noreferrer">
                    {t("apple-store")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
